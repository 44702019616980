import './SparkMaster.less';

import React, { useState, useEffect } from 'react';
import {
  Input,
  Select,
  Form,
  Switch,
  Button,
  Divider,
  Card,
  Upload,
  message,
  UploadFile,
  Spin,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { chidoriSizeItem, submitChidori } from 'services/sparkmaster';
import IncortaIcon from 'images/incorta-blue-logo.svg';
import DatabricksIcon from 'images/databricks.svg';
import GCPCloudDataprocIcon from 'images/gcp-cloud-dataproc.svg';
import AzureHDInsightIcon from 'images/azure-hd-insight.svg';
// import DisallowedConfigurations from 'components/Clusters/SparkMaster/DisallowedConfigurations/DisallowedConfigurations';
import { useGetChidori, useGetChidoriSizes } from 'hooks/queries';
const { Option } = Select;
const requiredKeys = [
  'type',
  'project_id',
  'private_key_id',
  'private_key',
  'client_email',
  'client_id',
  'auth_uri',
  'token_uri',
  'auth_provider_x509_cert_url',
  'client_x509_cert_url',
  'universe_domain',
];

function SparkMaster({ instance, data, close }) {
  let instanceid = instance.id;
  const { data: chidoriResult, isLoading: chidoriIsPending } = useGetChidori({
    instanceid,
  });
  const {
    data: chidoriSizes,
    isLoading: isChidoriSizesLoading,
    isError: hasChidoriSizesError,
  } = useGetChidoriSizes({});

  const [form] = Form.useForm();
  let [master, setMaster] = useState('incortaspark');
  let [isEnabled, setIsEnabled] = useState(false);
  let [serviceAccountJson, setServiceAccountJson] = useState(false);
  let [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  const handleMasterChange = value => {
    switch (value) {
      case 'databricks':
        setMaster('databricks');
        break;
      case 'dataproc':
        setMaster('dataproc');
        break;
      case 'hdinsight':
        setMaster('hdinsight');
        break;
      case 'incortaspark':
        setMaster('incortaspark');
        break;
      default:
        setMaster('');
    }
  };

  function handleSubmit(values) {
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('files[]', file as any);
    });
    values.serviceAccountJson = serviceAccountJson;
    submitChidori(values, instance);
    message.info(`Chidori is Processing for ${instance.name}`);
    close();
  }

  async function onValuesChange(changedValues: any, allValues: any) {
    if (Object.keys(allValues).length === 1 && allValues.install) {
      form.setFieldValue('master', 'incortaspark');
    }
    setTimeout(() => {
      const fieldsValues = form.getFieldsValue();
      const shouldDisable = Object.values(fieldsValues).some(
        value => !value && value !== false,
      );

      setIsSubmitButtonDisabled(shouldDisable);
    }, 500);
  }

  const handleToggle = () => {
    setIsEnabled(!isEnabled);
  };

  const integerFormatter = value => {
    if (value === '') {
      return '';
    }
    const intValue = parseInt(value, 10);
    return isNaN(intValue) ? '' : intValue.toString();
  };

  const integerParser = value => {
    const intValue = parseInt(value, 10);
    return isNaN(intValue) ? '' : intValue;
  };

  useEffect(() => {
    console.log({ chidoriResult });
    if (chidoriResult?.master) {
      setMaster(chidoriResult.master);
    }
    if (chidoriResult?.install) {
      form.setFieldsValue({ install: true });
      setIsEnabled(true);
    }
  }, [chidoriResult?.master, chidoriResult?.install]);

  return (
    <div>
      {chidoriResult && !chidoriIsPending ? (
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={{ ...chidoriResult }}
          onValuesChange={onValuesChange}
        >
          <Form.Item name="install">
            <Switch
              defaultChecked={isEnabled}
              onChange={handleToggle}
              style={{ marginRight: '10px' }}
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={isEnabled}
            />
          </Form.Item>
          {isEnabled && (
            <>
              <Form.Item name="master" label="Vendor">
                <Select
                  onChange={handleMasterChange}
                  defaultValue={'incortaspark'}
                >
                  <Option value="incortaspark">
                    <div className="option__wrapper">
                      <img src={IncortaIcon} />
                      Incorta Spark
                    </div>
                  </Option>
                  <Option value="databricks">
                    <div className="option__wrapper">
                      <img src={DatabricksIcon} />
                      Databricks
                    </div>
                  </Option>
                  <Option value="dataproc">
                    <div className="option__wrapper">
                      <img src={GCPCloudDataprocIcon} />
                      Google Cloud Dataproc
                    </div>
                  </Option>
                  <Option value="hdinsight">
                    <div className="option__wrapper">
                      <img src={AzureHDInsightIcon} />
                      Azure HDInsight
                    </div>
                  </Option>
                </Select>
              </Form.Item>
              <Card className="vendor-configurations__wrapper">
                {master === 'incortaspark' && (
                  <div>
                    <Form.Item
                      name="size"
                      label="Size"
                      className="form-item__wrapper"
                      rules={[{ required: true }]}
                    >
                      <Select
                        loading={isChidoriSizesLoading}
                        disabled={hasChidoriSizesError}
                      >
                        {chidoriSizes?.map((chidoriSize: chidoriSizeItem) => (
                          <Option
                            key={chidoriSize.size}
                            value={chidoriSize.size}
                          >
                            {chidoriSize.size}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                )}

                {master === 'databricks' && (
                  <div>
                    <Form.Item
                      name="apiUrl"
                      label="API URL"
                      className="form-item__wrapper"
                      required
                    >
                      <Input
                        type="url"
                        placeholder="https://<databricks-instance>.cloud.databricks.com"
                      />
                    </Form.Item>
                    <Form.Item
                      name="authToken"
                      label="Auth Token"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="xxxxxxxxxxxxxxxxxxxxxxx" />
                    </Form.Item>
                    <Form.Item
                      name="clusterID"
                      label="Cluster ID"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="0123-456789-abcd1234" />
                    </Form.Item>
                  </div>
                )}
                {master === 'dataproc' && (
                  <div>
                    <Form.Item
                      name="region"
                      label="Region"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="us-central1" />
                    </Form.Item>
                    <Form.Item
                      name="project"
                      label="Project"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="my-gcp-project" />
                    </Form.Item>
                    <Form.Item
                      name="cluster"
                      label="Cluster"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="my-dataproc-cluster" />
                    </Form.Item>
                    <Form.Item
                      name="serviceAccountEmail"
                      label="Service Account Email"
                      className="form-item__wrapper"
                      required
                    >
                      <Input type="email" placeholder="example@domain.com" />
                    </Form.Item>
                    <Form.Item name="serviceAccountFile" required>
                      <Upload
                        onRemove={(file: any) => {
                          const index = fileList.indexOf(file);
                          const newFileList = fileList.slice();
                          newFileList.splice(index, 1);
                          setFileList(newFileList);
                        }}
                        beforeUpload={(file: any) => {
                          const reader = new FileReader();
                          reader.readAsText(file);
                          return new Promise((resolve, reject) => {
                            reader.onload = () => {
                              const fileString = reader.result as string;
                              try {
                                const json = JSON.parse(fileString);
                                if (
                                  requiredKeys.every(key =>
                                    json.hasOwnProperty(key),
                                  )
                                ) {
                                  resolve(file);
                                  setFileList([file]);
                                  setServiceAccountJson(json);
                                  message.success(
                                    'File uploaded successfully.',
                                  );
                                } else {
                                  message.error(
                                    'The JSON file does not have all the required keys.',
                                  );
                                  reject(
                                    new Error(
                                      'The JSON file does not have all the required keys.',
                                    ),
                                  );
                                }
                              } catch (error) {
                                message.error('Invalid JSON file.');
                                reject(new Error('Invalid JSON file.'));
                              }
                              return false;
                            };
                          });
                        }}
                        fileList={fileList as any}
                        accept=".json"
                        multiple={false}
                        maxCount={1}
                      >
                        <Button>
                          <UploadOutlined /> Upload Service Account File
                        </Button>
                      </Upload>
                    </Form.Item>
                  </div>
                )}
                {master === 'hdinsight' && (
                  <div>
                    <Form.Item
                      name="clusterName"
                      label="Cluster Name"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="my-hdinsight-cluster" />
                    </Form.Item>
                    <Form.Item
                      name="clusterUsername"
                      label="Cluster Username"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="my-cluster-username" />
                    </Form.Item>
                    <Form.Item
                      name="clusterPassword"
                      label="Cluster Password"
                      className="form-item__wrapper"
                      required
                    >
                      <Input.Password placeholder="my-cluster-password" />
                    </Form.Item>
                    <Form.Item
                      name="subscriptionID"
                      label="Subscription ID"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="01234567-89ab-cdef-0123-456789abcdef" />
                    </Form.Item>
                    <Form.Item
                      name="resourceGroup"
                      label="Resource Group"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="my-resource-group" />
                    </Form.Item>
                  </div>
                )}
              </Card>
              <Divider />
              {/* <DisallowedConfigurations /> */}
            </>
          )}
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              disabled={isSubmitButtonDisabled}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Spin />
      )}{' '}
    </div>
  );
}

export default SparkMaster;
