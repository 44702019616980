import React, { useState, useCallback } from 'react';
import {
  Table,
  Typography,
  Tag,
  Button,
  Tooltip,
  Space,
  Input,
  Modal,
} from 'antd';
import { formatISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { PlusSquareOutlined, BackwardOutlined } from '@ant-design/icons';
import useRequest from 'hooks/useRequest';

import {
  getNonAccountManagers,
  addAccountManager,
} from 'services/accountManager';

let { Title } = Typography;
const { Search } = Input;

function AccountManagerUsers() {
  let [page, setPage] = useState(1);
  let [search, setSearch] = useState('');
  let history = useHistory();

  let {
    result,
    isPending,
    makeRequest: refetchUsers,
  } = useRequest(
    useCallback(() => getNonAccountManagers({ page, search }), [page, search]),
    { resolve: true },
  );

  let users = result?.data?.users;
  let pageSize = result?.data?.limit;
  let current = result?.data?.page;
  let total = result?.data?.total;

  let columns = [
    {
      title: 'Full name',
      dataIndex: 'fullName',
      render(_, user) {
        return (
          <span>
            {user.fullName}
            &emsp;
            <Tag color="blue">
              {user.role.name.charAt(0).toUpperCase() + user.role.name.slice(1)}
            </Tag>
            {user.permission === 'accountant' && (
              <Tag color="green">Accountant</Tag>
            )}
            {user.permission === 'support' && <Tag color="red">Support</Tag>}
            {user.permission === 'pre-sales' && (
              <Tag color="green">Pre-Sales</Tag>
            )}
            {user.permission === 'professional services' && (
              <Tag color="green">Professional Services</Tag>
            )}
            {user.permission === 'admin' && <Tag color="green">Admin</Tag>}
            {user.permission === 'super admin' && (
              <Tag color="cyan">Super admin</Tag>
            )}
            {user.confirmed && <Tag color="purple">Confirmed</Tag>}
            {user.trialExpired && <Tag color="red">Expired</Tag>}
            {user.blocked && <Tag color="red">Blocked</Tag>}
          </span>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Company',
      dataIndex: 'company',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },
    {
      title: 'last Login At',
      dataIndex: 'lastLoginAt',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },
    {
      title: 'Action',
      render(_, user) {
        return (
          <Space>
            {
              <Tooltip title="Add">
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <>
                          Are you sure you want to add
                          <strong> {user.fullName} </strong>
                          to the Account Managers list?
                        </>
                      ),
                      async onOk() {
                        await addAccountManager(user.id, user);
                        await refetchUsers();
                      },
                    });
                  }}
                >
                  <PlusSquareOutlined />
                </Button>
              </Tooltip>
            }
          </Space>
        );
      },
    },
  ];

  return (
    <div className="AccountManager">
      <div className="tableTitle">
        <Title>Add Account Managers</Title>
        <Tooltip title="Create">
          <Button
            type="primary"
            icon={<BackwardOutlined />}
            onClick={() => {
              history.push(`/accountmanagers`);
            }}
          >
            Account Managers
          </Button>
        </Tooltip>
      </div>
      <Search
        placeholder="Search By Email, Name, Comapny Or 'Field Name'=Some Value."
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        enterButton
      />
      <Table
        rowKey="id"
        className="antd-table-customized-scroll"
        bordered
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={users}
        loading={isPending}
        columns={columns}
      />
    </div>
  );
}

export default AccountManagerUsers;
