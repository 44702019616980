import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';

const SUCCEEDED = 'succeeded';
const FAILED = 'failed';
const IN_PROGRESS = 'in progress';
const NOT_REQUIRED = 'not required';
const SKIPPED = 'skipped';

export function getBackupFunctionStatusTag(backupStatus) {
  return backupStatus === SUCCEEDED ? (
    <Tag color="green">{backupStatus}</Tag>
  ) : backupStatus === FAILED ? (
    <Tag color="red">{backupStatus}</Tag>
  ) : backupStatus === IN_PROGRESS ? (
    <Tag color="yellow">{backupStatus}</Tag>
  ) : backupStatus === NOT_REQUIRED ? (
    <Tag color="grey">{backupStatus}</Tag>
  ) : backupStatus === SKIPPED ? (
    <Tag color="blue">{backupStatus}</Tag>
  ) : (
    <Tag color="purple">{backupStatus}</Tag>
  );
}

export function getWeekNumberAndDay(
  backupDate,
  selectedDay: string | undefined = undefined,
): any {
  const weekNumber = Math.floor((new Date(backupDate).getDate() - 1) / 7) + 1;
  if (selectedDay === undefined) {
    selectedDay = moment(backupDate).format('dddd');
  }
  switch (weekNumber) {
    case 1:
      return `First ${selectedDay}`;
    case 2:
      return `Second ${selectedDay}`;
    case 3:
      return `Third ${selectedDay}`;
    case 4:
      if (
        new Date(backupDate).getDate() + 7 <=
        moment(backupDate).daysInMonth()
      )
        return `Fourth ${selectedDay}`;
      else return `Last ${selectedDay}`;
    case 5:
      return `Last ${selectedDay}`;
    default:
      return selectedDay;
  }
}
