import React, { useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Button, Tooltip, Modal } from 'antd';
import {
  StopOutlined,
  CheckOutlined,
  UnlockOutlined,
  EditOutlined,
} from '@ant-design/icons';
import UserModal from '../UserModal/UserModal';
import useRequest from 'hooks/useRequest';
import { getUser, resetUserPassword, blockUser } from 'services/user';
import { showError } from 'utils/errors';
import { SessionContext } from 'auth/SessionProvider';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';

let { Title } = Typography;

function UserDetails(userObj) {
  let { userid }: any = useParams();
  let [modalProps, setModalProps] = useState<any>(null);

  const { user: me } = useContext(SessionContext);
  let [param] = useState(userid);

  let {
    result,
    isPending,
    makeRequest: refetchUsers,
  } = useRequest(
    useCallback(() => getUser({ param }), [param]),
    { resolve: true },
  );

  let user = result?.data?.user || userObj;

  const usercolumns = [
    { title: 'Key', dataIndex: 'key' },
    { title: 'Value', dataIndex: 'value' },
  ];

  const userdata = [
    { key: 'First Name', value: `${user.firstName}` },
    { key: 'Last Name', value: `${user.lastName}` },
    { key: 'Email', value: `${user.email}` },
    { key: 'Company', value: `${user.company}` },
    { key: 'Organization', value: `${user.organization}` },
    { key: 'Cost Center', value: `${user.cost_center}` },
    {
      key: 'Job title',
      value: `${user.jobTitle === null ? '' : user.jobTitle}`,
    },
    {
      key: 'Phone',
      value: `${user.phone === null ? '' : user.phone}`,
    },
    {
      key: 'Permission',
      value: `${
        user.permission?.charAt(0).toUpperCase() + user.permission?.slice(1)
      }`,
    },
    {
      key: 'Account Type',
      value: `${user.role?.displayName}`,
    },
    {
      key: 'Total Consumed Credit',
      value: `${user.consumption === null ? '' : user.consumption}`,
    },
  ];

  return (
    <div className="UserDetails">
      <div className="tableTitle">
        <Title level={3}>Personal Information</Title>
        <div>
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setModalProps({ isNew: false, user });
              }}
            >
              Edit
            </Button>
          </Tooltip>
          {user.uuid !== me.uuid && (
            <Tooltip title={user.blocked ? 'Unblock' : 'Block'}>
              <Button
                icon={user.blocked ? <CheckOutlined /> : <StopOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title: (
                      <>
                        Are you sure you want to{' '}
                        {user.blocked ? 'Unblock' : 'block'}{' '}
                        <strong>{user.email}</strong>?
                      </>
                    ),
                    async onOk() {
                      await blockUser(user.uuid, !user.blocked).catch(error => {
                        showError(error);
                      });
                      await refetchUsers();
                    },
                  });
                }}
              >
                {user.blocked ? 'Unblock' : 'Block'}{' '}
              </Button>
            </Tooltip>
          )}

          <Tooltip title="Reset Password">
            <Button
              icon={<UnlockOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: (
                    <>
                      Are you sure you want to reset this user's password{' '}
                      <strong>{user.email}</strong>?
                    </>
                  ),
                  async onOk() {
                    await resetUserPassword(user.uuid);
                    await refetchUsers();
                  },
                });
              }}
            >
              {'Reset Password'}{' '}
            </Button>
          </Tooltip>
        </div>
      </div>

      <SearchableTable
        pagination={false}
        loading={isPending}
        columns={usercolumns}
        dataSource={userdata}
      />
      {modalProps && (
        <UserModal
          {...modalProps}
          refetchUsers={refetchUsers}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default UserDetails;
