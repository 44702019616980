import './TypeField.less';

import React from 'react';
import { Select, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { getBlueprintTypes } from 'services/blueprintCategory';

let { Option } = Select;

function TypeField({ name, label }) {
  let { result, isPending } = useRequest(getBlueprintTypes, {
    resolve: true,
    handleError: true,
    useCache: true,
  });

  let blueprintsTypes = result?.data?.blueprintsTypes;

  return (
    <Form.Item
      className="Type"
      name={name}
      label={label}
      rules={[{ required: true }]}
    >
      <FieldSelect
        loading={isPending && !blueprintsTypes}
        blueprintsTypes={blueprintsTypes}
      />
    </Form.Item>
  );
}

function FieldSelect({ value, onChange, loading, blueprintsTypes }: any) {
  return (
    <Select
      value={blueprintsTypes ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="Type__select-dropdown"
    >
      {blueprintsTypes?.map(blueprintsType => (
        <Option key={blueprintsType} value={blueprintsType}>
          {blueprintsType}
        </Option>
      ))}
    </Select>
  );
}

export default TypeField;
