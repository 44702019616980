import './BusinessFunctionField.less';

import React from 'react';
import { Select, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { getBlueprintFunctions } from 'services/blueprintCategory';

let { Option } = Select;

function BusinessFunctionField({ name, label }) {
  let { result, isPending } = useRequest(getBlueprintFunctions, {
    resolve: true,
    handleError: true,
    useCache: true,
  });

  let blueprintsFunctions = result?.data?.blueprintsFunctions;

  return (
    <Form.Item
      className="BusinessFunction"
      name={name}
      label={label}
      rules={[{ required: true }]}
    >
      <FieldSelect
        loading={isPending && !blueprintsFunctions}
        blueprintsFunctions={blueprintsFunctions}
      />
    </Form.Item>
  );
}

function FieldSelect({
  value,
  onChange,
  loading,
  blueprintsFunctions,
}: {
  value?: string;
  onChange?: () => {};
  loading: boolean;
  blueprintsFunctions: any[];
}) {
  return (
    <Select
      value={blueprintsFunctions ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="BusinessFunction__select-dropdown"
    >
      {blueprintsFunctions?.map(blueprintsFunction => (
        <Option key={blueprintsFunction} value={blueprintsFunction}>
          {blueprintsFunction}
        </Option>
      ))}
    </Select>
  );
}

export default BusinessFunctionField;
