import './NavigationLayout.less';

import { useContext, useState } from 'react';
import {
  UserOutlined,
  SettingOutlined,
  ClusterOutlined,
  DeploymentUnitOutlined,
  UsergroupAddOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  FileDoneOutlined,
  FileAddOutlined,
  BookOutlined,
  DatabaseOutlined,
  FunnelPlotOutlined,
  AuditOutlined,
  MailOutlined,
  EyeOutlined,
  BuildOutlined,
  DollarCircleOutlined,
  LaptopOutlined,
  RadarChartOutlined,
  CloudServerOutlined,
  StrikethroughOutlined,
  SnippetsOutlined,
  BorderOuterOutlined,
  CloudDownloadOutlined,
  UserSwitchOutlined,
  PieChartOutlined,
  CloudSyncOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Menu, Layout, Button, Typography, Tooltip } from 'antd';
import { useHistory, useLocation, Link } from 'react-router-dom';
import cookie from 'js-cookie';
import { ReactComponent as ChidoriBlackIcon } from 'images/chidori-black-logo.svg';
import IncortaLogo from 'images/incorta-logo.svg';
import { SessionContext } from 'auth/SessionProvider';

let { Header, Sider, Content } = Layout;
let { Text } = Typography;

function NavigationLayout({ children }) {
  let location = useLocation();
  let history = useHistory();
  const { user, logout } = useContext(SessionContext);

  let [collapsed, setCollapsed] = useState(true);

  function handleToggle() {
    setCollapsed(!collapsed);
  }
  if (user.permission === 'guest') {
    return (
      <Layout className="NavigationLayout">
        <Layout className="NavigationLayout__site-layout">
          <Header className="NavigationLayout__rightheader">
            <div className="NavigationLayout__header-nav">
              <Text strong>{user.fullName}</Text>
              <Link to={`/support-dashboard`}>
                <Button type="dashed">Support Dashboard</Button>
              </Link>
              <Button
                onClick={() => {
                  logout({ returnTo: window.location.origin });
                  cookie.remove('CloudAdminSSO', {
                    domain: process.env.REACT_APP_DOMAIN,
                  });
                  document.dispatchEvent(new CustomEvent('SESSION_LOGGED_OUT'));
                }}
              >
                Logout
              </Button>
            </div>
          </Header>
          <Content className="NavigationLayout__content">{children}</Content>
        </Layout>
      </Layout>
    );
  }
  return (
    <Layout className="NavigationLayout">
      <Sider width={240} trigger={null} collapsible collapsed={collapsed}>
        <div className="NavigationLayout__logo">
          <img src={IncortaLogo} alt="Incorta Logo" />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname]}
          className="NavigationLayout__menu"
        >
          <Menu.Item
            key="/"
            icon={<HomeOutlined />}
            onClick={() => {
              history.push('/');
            }}
          >
            Home
          </Menu.Item>
          {(user.permission === 'admin' ||
            user.permission === 'super admin' ||
            user.permission === 'support') && (
            <Menu.SubMenu title="Users Management" icon={<UserOutlined />}>
              <Menu.Item
                key="/users"
                onClick={() => {
                  history.push('/users');
                }}
                icon={<UserOutlined />}
              >
                Users
              </Menu.Item>
              <Menu.Item
                key="/provision-user"
                icon={<UserSwitchOutlined />}
                onClick={() => {
                  history.push('/provision-user');
                }}
              >
                Provision Users
              </Menu.Item>
              {(user.permission === 'admin' ||
                user.permission === 'super admin') && (
                <Menu.Item
                  key="/accountmanagers"
                  icon={<UsergroupAddOutlined />}
                  onClick={() => {
                    history.push('/accountmanagers');
                  }}
                >
                  Account Managers
                </Menu.Item>
              )}
            </Menu.SubMenu>
          )}
          {(user.permission === 'admin' ||
            user.permission === 'super admin') && (
            <Menu.SubMenu
              icon={<FileDoneOutlined />}
              title="Blueprints Management"
            >
              <Menu.Item
                key="/exportedblueprints"
                icon={<CloudDownloadOutlined />}
                onClick={() => {
                  history.push('/exportedblueprints');
                }}
              >
                Blueprints Exports
              </Menu.Item>

              <Menu.Item
                key="/blueprints"
                disabled={true}
                icon={<FileDoneOutlined />}
                onClick={() => {
                  history.push('/blueprints');
                }}
              >
                <Tooltip placement="left" title={'Moved To Managed CMS'}>
                  Blueprints (Deprecated)
                </Tooltip>
              </Menu.Item>

              <Menu.Item
                key="/blueprintcategories"
                disabled={true}
                icon={<FileAddOutlined />}
                onClick={() => {
                  history.push('/blueprintcategories');
                }}
              >
                <Tooltip placement="left" title={'Moved To Managed CMS'}>
                  Blueprint Categories (Deprecated)
                </Tooltip>
              </Menu.Item>

              <Menu.Item
                disabled={true}
                key="/blueprintfunctions"
                icon={<FunnelPlotOutlined />}
                onClick={() => {
                  history.push('/blueprintfunctions');
                }}
              >
                <Tooltip placement="left" title={'Moved To Managed CMS'}>
                  Blueprint Functions (Deprecated)
                </Tooltip>
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {(user.permission === 'super admin' ||
            user.permission === 'admin' ||
            user.permission === 'support') && (
            <Menu.SubMenu
              title="Clusters Management"
              icon={<ClusterOutlined />}
            >
              <Menu.Item
                key="/clusters"
                icon={<ClusterOutlined />}
                onClick={() => {
                  history.push('/clusters');
                }}
              >
                Clusters
              </Menu.Item>
              {user.permission === 'super admin' && (
                <Menu.Item
                  key="/featurebits"
                  icon={<LaptopOutlined />}
                  onClick={() => {
                    history.push('/featurebits');
                  }}
                >
                  Feature Bits
                </Menu.Item>
              )}

              <Menu.Item
                key="/backups"
                icon={<CloudServerOutlined />}
                onClick={() => {
                  history.push('/backups');
                }}
              >
                Backups
              </Menu.Item>
            </Menu.SubMenu>
          )}
          <Menu.SubMenu title="System Management" icon={<SettingOutlined />}>
            {(user.permission === 'admin' ||
              user.permission === 'super admin' ||
              user.permission === 'release manager') && (
              <Menu.Item
                key="/configurations"
                icon={<SettingOutlined />}
                onClick={() => {
                  history.push('/configurations');
                }}
              >
                Configurations
              </Menu.Item>
            )}

            {(user.permission === 'admin' ||
              user.permission === 'super admin') && (
              <Menu.Item
                key="/dataagents"
                icon={<DatabaseOutlined />}
                onClick={() => {
                  history.push('/dataagents');
                }}
              >
                Data Agents
              </Menu.Item>
            )}
            {(user.permission === 'admin' ||
              user.permission === 'super admin') && (
              <Menu.Item
                key="/platform"
                icon={<BorderOuterOutlined />}
                onClick={() => {
                  history.push('/platform');
                }}
              >
                Platform Actions
              </Menu.Item>
            )}
            {(user.permission === 'admin' ||
              user.permission === 'super admin') && (
              <Menu.Item
                key="/togglefeatures"
                icon={<DeploymentUnitOutlined />}
                onClick={() => {
                  history.push('/togglefeatures');
                }}
              >
                Toggle Features
              </Menu.Item>
            )}

            {user.permission !== 'release manager' && (
              <Menu.Item
                key="/clicommands"
                icon={<BookOutlined />}
                onClick={() => {
                  history.push('/clicommands');
                }}
              >
                CLI Commands
              </Menu.Item>
            )}
            {(user.permission === 'admin' ||
              user.permission === 'super admin') && (
              <Menu.Item
                key="/contextmanager"
                icon={<RadarChartOutlined />}
                onClick={() => {
                  history.push('/contextmanager');
                }}
              >
                Operators
              </Menu.Item>
            )}

            {(user.permission === 'admin' ||
              user.permission === 'super admin') && (
              <Menu.Item
                key="/costawareness"
                icon={<DollarCircleOutlined />}
                onClick={() => {
                  history.push('/costawareness');
                }}
              >
                Cost Awareness
              </Menu.Item>
            )}
            {(user.permission === 'super admin' ||
              user.permission === 'admin') && (
              <Menu.Item
                key="/infrastructure"
                icon={<SnippetsOutlined />}
                onClick={() => {
                  history.push('/infrastructure');
                }}
              >
                Infrastructure
              </Menu.Item>
            )}
          </Menu.SubMenu>
          {user.permission !== 'release manager' && (
            <Menu.SubMenu title="Monitoring" icon={<EyeOutlined />}>
              <Menu.Item
                key="/clustersstatus"
                icon={<EyeOutlined />}
                onClick={() => {
                  history.push('/clustersstatus');
                }}
              >
                Cloud Insights
              </Menu.Item>
              <Menu.Item
                key="/clustersactions"
                icon={<CloudSyncOutlined />}
                onClick={() => {
                  history.push('/clustersactions');
                }}
              >
                Cloud Actions
              </Menu.Item>
              {(user.permission === 'super admin' ||
                user.permission === 'admin' ||
                user.permission === 'accountant') && (
                <Menu.Item
                  key="/audit"
                  icon={<AuditOutlined />}
                  onClick={() => {
                    history.push('/audit');
                  }}
                >
                  Audit History
                </Menu.Item>
              )}
              {(user.permission === 'admin' ||
                user.permission === 'super admin') && (
                <Menu.Item
                  key="/notifications"
                  icon={<MailOutlined />}
                  onClick={() => {
                    history.push('/notifications');
                  }}
                >
                  Notifications
                </Menu.Item>
              )}
            </Menu.SubMenu>
          )}
          {(user.permission === 'support' ||
            user.permission === 'admin' ||
            user.permission === 'super admin' ||
            user.permission === 'release manager') && (
            <Menu.SubMenu title="Images Management" icon={<BuildOutlined />}>
              <Menu.Item
                key="/images"
                icon={<BuildOutlined />}
                onClick={() => {
                  history.push('/images');
                }}
              >
                Images
              </Menu.Item>
              {user.permission !== 'release manager' && (
                <Menu.Item
                  key="/sparkimages"
                  icon={<StrikethroughOutlined />}
                  onClick={() => {
                    history.push('/sparkimages');
                  }}
                >
                  Spark Images
                </Menu.Item>
              )}
            </Menu.SubMenu>
          )}
          <Menu.SubMenu title="Guidance" icon={<InfoCircleOutlined />}>
            <Menu.Item
              key="/cluster-sizes"
              icon={<ClusterOutlined />}
              onClick={() => {
                history.push('/cluster-sizes');
              }}
            >
              Cluster Sizes
            </Menu.Item>
            <Menu.Item
              key="/chidori-sizes"
              className="chidori-sizes-nav"
              icon={
                <ChidoriBlackIcon
                  style={{ width: '12px', height: '12px', fill: 'white' }}
                />
              }
              onClick={() => {
                history.push('/chidori-sizes');
              }}
            >
              Chidori Sizes
            </Menu.Item>
            {(user.permission === 'admin' ||
              user.permission === 'super admin') && (
              <Menu.Item
                key="/oclabels"
                icon={<PieChartOutlined />}
                onClick={() => {
                  history.push('/oclabels');
                }}
              >
                Over Commitment
              </Menu.Item>
            )}
          </Menu.SubMenu>
        </Menu>
      </Sider>
      <Layout className="NavigationLayout__site-layout">
        <Header className="NavigationLayout__header">
          <div>
            {collapsed ? (
              <MenuUnfoldOutlined
                className="NavigationLayout__trigger"
                onClick={handleToggle}
              />
            ) : (
              <MenuFoldOutlined
                className="NavigationLayout__trigger"
                onClick={handleToggle}
              />
            )}
          </div>
          <div className="NavigationLayout__header-nav">
            <Text strong>{user.fullName}</Text>
            <Link to={`/support-dashboard`}>
              <Button type="dashed">Support Dashboard</Button>
            </Link>
            <Button
              onClick={() => {
                logout({ returnTo: window.location.origin });
                cookie.remove('CloudAdminSSO', {
                  domain: process.env.REACT_APP_DOMAIN,
                });
                document.dispatchEvent(new CustomEvent('SESSION_LOGGED_OUT'));
              }}
            >
              Logout
            </Button>
          </div>
        </Header>
        <Content className="NavigationLayout__content">{children}</Content>
      </Layout>
    </Layout>
  );
}

export default NavigationLayout;
