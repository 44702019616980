import React, { useState } from 'react';
import { Typography, Button, Tooltip, Space } from 'antd';
import { formatISO } from 'date-fns';
import { EditOutlined } from '@ant-design/icons';
import ConfigModal from './ConfigModal/ConfigModal';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { useGetConfigurations } from 'hooks/queries';

let { Title } = Typography;

function Configurations() {
  let [page, setPage] = useState(1);
  let [search, setSearch] = useState('');
  let [modalProps, setModalProps] = useState<any>(null);

  const {
    data: result,
    isLoading: isPending,
    refetch: refetchConfigurations,
  } = useGetConfigurations({ page, search });

  let configurations = result?.data?.configurations;
  let pageSize = result?.data?.limit;
  let current = result?.data?.page;
  let total = result?.data?.total;

  let columns = [
    {
      title: 'Configuration Key',
      dataIndex: 'displayName',
    },
    {
      title: 'Configuration Value',
      dataIndex: 'configValue',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },
    {
      title: 'Action',
      render(_, configuration) {
        return (
          <Space>
            <Tooltip title="Edit">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ isNew: false, configuration });
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="Configurations">
      <div className="tableTitle">
        <Title>System Configurations</Title>
      </div>

      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={configurations}
        loading={isPending}
        columns={columns}
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        searchPlaceholder={
          "Search by Display Name, Configuration Key or 'Field Name'=Some Value."
        }
      />

      {modalProps && (
        <ConfigModal
          {...modalProps}
          refetchConfigurations={refetchConfigurations}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default Configurations;
