import './SparkVersionsField.less';

import React, { useCallback } from 'react';
import { Select, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { getSparkImages } from 'services/sparkimages';

let { Option } = Select;

function SparkVersionsField({ name, label }) {
  let { isPending } = useRequest(
    useCallback(() => getSparkImages({}), []),
    {
      resolve: true,
      handleError: true,
      useCache: true,
    },
  );

  let sparkVersions = ['3.0.2', '3.3.0'];
  return (
    <Form.Item className="SparkVersionsField" name={name} label={label}>
      <FieldSelect
        loading={isPending && !sparkVersions}
        sparkVersions={sparkVersions}
      />
    </Form.Item>
  );
}

function FieldSelect({ value, onChange, loading, sparkVersions }: any) {
  return (
    <Select
      value={sparkVersions ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="SparkVersionsField__select-dropdown"
    >
      {sparkVersions?.map(sparkVersion => (
        <Option key={sparkVersion} value={sparkVersion}>
          {sparkVersion}
        </Option>
      ))}
    </Select>
  );
}

export default SparkVersionsField;
