export const QueryServerKeys = {
  USER: {
    GET_USER_DATA: 'me',
    GET_USERS: 'get-users',
  },
  HOME: {
    GET_INFO: 'home-info',
  },
  CLUSTER_MANAGEMENT: {
    CLUSTERS: 'get-clusters',
  },
  SYSTEM_MANAGEMENT: {
    CONFIGURATIONS: 'configurations',
    CREATE_CONFIGURATIONS: 'create-configurations',
    UPDATE_CONFIGURATIONS: 'update-configurations',
    AZURE_PLANS: 'azure-plans',
    ADD_USER_TO_FEATURE: 'add-user-to-feature',
    TOGGLE_FEATURES: 'toggle-features',
  },
  CHIDORI: {
    CHIDORI: 'get-chidori',
    GET_SIZES: 'get-chidori-sizes',
    UPDATE_SIZES: 'update-chidori-sizes',
  },
};
