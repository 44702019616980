import axios from 'axios';
import { API_URL } from 'utils/http';

export function getConfigurations({ page = 1, search }) {
  return axios.get(`${API_URL}/configurations`, { params: { page, search } });
}

export function updateConfiguration(id, configuration) {
  return axios.put(`${API_URL}/configurations/${id}/update`, configuration);
}

export function createConfiguration(configuration) {
  return axios.post(`${API_URL}/configurations/create`, configuration);
}

export function deleteConfiguration(id) {
  return axios.delete(`${API_URL}/configurations/${id}/delete`);
}

export function getCostType() {
  return axios.get(`${API_URL}/costtypes`);
}

export function getSlaType() {
  return axios.get(`${API_URL}/slaTypes`);
}
