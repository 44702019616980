import React, { useState, useCallback } from 'react';
import { Typography, Button, Tooltip } from 'antd';
import { formatISO } from 'date-fns';
import { PlusOutlined } from '@ant-design/icons';
import NotificationsModal from './NotificationsModal';
import useRequest from 'hooks/useRequest';
import { getUsers } from 'services/user';
import './Notification.less';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';

let { Title } = Typography;

function Notifications() {
  let [page, setPage] = useState(1);
  let [search, setSearch] = useState('');
  let [modalProps, setModalProps] = useState<any>(null);
  let [selectedUsers, setSelectedUsers] = useState<any>([]);

  let {
    result,
    isPending,
    makeRequest: refetchGlobalNotifications,
  } = useRequest(
    useCallback(() => getUsers({ page, search }), [page, search]),
    { resolve: true },
  );

  let users = result?.data?.users;
  let pageSize = result?.data?.limit;
  let current = result?.data?.page;
  let total = result?.data?.total;

  let columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      width: '10%',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },
  ];
  const onSelectChange = selectedRowKeys => {
    setSelectedUsers(selectedRowKeys);
  };

  return (
    <div className="Notifcations">
      <div className="tableTitle">
        <Title>Notifications</Title>
        <span>
          {selectedUsers.length > 0 && (
            <span className="num-of-selected">
              {selectedUsers.length} user(s) selected
            </span>
          )}
          <Tooltip title="Send Global Notification">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setModalProps({ isNew: true });
              }}
            >
              Send Global Notification
            </Button>
          </Tooltip>
        </span>
      </div>

      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        rowSelection={{
          preserveSelectedRowKeys: true,
          onChange: onSelectChange,
        }}
        dataSource={users}
        loading={isPending}
        columns={columns}
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        searchPlaceholder={
          'Search By Email, Name, Comapny Or `Field Name`=Some Value.'
        }
      />

      {modalProps && (
        <NotificationsModal
          {...modalProps}
          refetchGlobalNotifications={refetchGlobalNotifications}
          selectedUsers={selectedUsers}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default Notifications;
