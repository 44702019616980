import React, { useCallback, useContext, useEffect, useState } from 'react';
import './Infrastructure.less';

import { Typography, Button, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import CleanupModal from 'components/Clusters/CleanupModal/CleanupModal';
import { cleanupLogs } from 'services/cluster';
import useRequest from 'hooks/useRequest';
import { SessionContext } from 'auth/SessionProvider';

let { Title } = Typography;

function Infrastructure() {
  const { user } = useContext(SessionContext);
  let [cleanupModalProps, setCleanupModalProps] = useState(false);
  let [loading, setLoading] = useState(false);
  let [logs, setLogs] = useState<any>({});

  let { makeRequest } = useRequest(
    useCallback(async () => {
      const result = await cleanupLogs();
      setLogs(result.data);
      setLoading(false);
      // eslint-disable-next-line
    }, [user]),
    {
      handleError: true,
    },
  );

  function handleFetch() {
    setLoading(true);
    makeRequest();
  }

  useEffect(() => {
    handleFetch();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="Infrastructure">
      <div className="tableTitle">
        <Title>Infrastructure</Title>
      </div>
      {(user.permission === 'super admin' || user.permission === 'admin') && (
        <div className="cleanup">
          <Button
            size="large"
            type="default"
            onClick={() => {
              setCleanupModalProps(true);
            }}
          >
            Cleanup Resources
          </Button>
        </div>
      )}

      <Spin
        tip="Fetching Cleanup Logs"
        spinning={loading}
        className="Infrastructure__spinner"
      >
        <TextArea disabled className="Infrastructure__textarea" value={logs} />
      </Spin>

      {cleanupModalProps && (
        <CleanupModal
          cancel={() => setCleanupModalProps(false)}
          refresh={() => handleFetch()}
        />
      )}
    </div>
  );
}

export default Infrastructure;
