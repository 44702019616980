import React, { useCallback, useState } from 'react';
import { Modal, Form, Button, Space, AutoComplete } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { getUsersEmails } from 'services/user';
import { transferClustersOwnership } from 'services/cluster';
import useRequest from 'hooks/useRequest';
import { multiclusterAction } from 'utils/helpers';

const { Option } = AutoComplete;

function TransferOwnershipModel({
  cluster,
  refetchClusters,
  close,
  clusterIds,
  setErrorModalProps,
}) {
  let { name } = cluster ?? {};
  const [result, setResult] = useState([]);

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async cluster => {
        await multiclusterAction(
          transferClustersOwnership,
          [cluster, clusterIds],
          refetchClusters,
          setErrorModalProps,
        );
        await refetchClusters();
      },
      // eslint-disable-next-line
      [name, refetchClusters, clusterIds],
    ),
    {
      handleError: true,
    },
  );

  const debounced = useDebouncedCallback(async email => {
    let res = [];
    if (email) {
      let emailResult = await getUsersEmails(email);

      let users = emailResult?.data?.users;
      res = users.map(user => `${user.email}`);

      setResult(res);
    } else {
      res = [];
      setResult(res);
    }
  }, 500);

  async function handleSearch(email) {
    debounced.callback(email);
  }

  async function handleFinish(cluster) {
    await makeRequest(cluster);
    close();
  }

  return (
    <Modal
      visible
      title={'Transfer Cluster To New User'}
      footer={null}
      onCancel={close}
    >
      <Form
        initialValues={{
          name,
        }}
        layout="vertical"
        name="cluster"
        onFinish={handleFinish}
      >
        <Form.Item name="email" label="User Email" rules={[{ required: true }]}>
          <AutoComplete
            onSearch={value => {
              handleSearch(value);
            }}
            placeholder="User Email"
          >
            {result.map(email => (
              <Option key={email} value={email}>
                {email}
              </Option>
            ))}
          </AutoComplete>
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {'Transfer'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default TransferOwnershipModel;
