import React, { useState, useCallback } from 'react';
import { Typography, Button, Tooltip, Space, Modal } from 'antd';
import { formatISO } from 'date-fns';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import BlueprintModal from './BlueprintFunctionModal/BlueprintFunctionModal';
import useRequest from 'hooks/useRequest';
import { showError } from 'utils/errors';
import {
  getBlueprintFunctions,
  deleteBlueprintFunction,
} from 'services/blueprintFunction';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';

let { Title } = Typography;

function BlueprintFunctions() {
  let [page, setPage] = useState(1);
  let [search] = useState('');
  let [modalProps, setModalProps] = useState<any>(null);

  let {
    result,
    isPending,
    makeRequest: refetchBlueprintFunctions,
  } = useRequest(
    useCallback(() => getBlueprintFunctions({ page, search }), [page, search]),
    { resolve: true },
  );

  let blueprintFunctions = result?.data?.blueprintFunctions;
  let pageSize = result?.data?.limit;
  let current = result?.data?.page;
  let total = result?.data?.total;

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },
    {
      title: 'Action',
      render(_, blueprintFunction) {
        return (
          <Space>
            <Tooltip title="Edit">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ isNew: false, blueprintFunction });
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>

            <Tooltip title={'Delete'}>
              <Button
                size="small"
                onClick={() => {
                  Modal.confirm({
                    title: (
                      <>
                        Are you sure you want to {'delete'}{' '}
                        <strong>{blueprintFunction.name}</strong>?
                      </>
                    ),
                    async onOk() {
                      await deleteBlueprintFunction(blueprintFunction.id).catch(
                        error => {
                          showError(error);
                        },
                      );
                      await refetchBlueprintFunctions();
                    },
                  });
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="BlueprintsFunctions">
      <div className="tableTitle">
        <Title>Blueprint Functions</Title>
        <Tooltip title="Create">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setModalProps({ isNew: true });
            }}
          >
            Add Blueprint Function
          </Button>
        </Tooltip>
      </div>
      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={blueprintFunctions}
        loading={isPending}
        columns={columns}
      />

      {modalProps && (
        <BlueprintModal
          {...modalProps}
          refetchBlueprintFunctions={refetchBlueprintFunctions}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default BlueprintFunctions;
