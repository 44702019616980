import { useAuth0 } from '@auth0/auth0-react';
import cookie from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { SessionContextType, UserData, UserExtensions } from '../types/user';
import { queryClient } from 'index';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import { useGetUserData } from 'hooks/queries';

export const SessionContext = React.createContext<SessionContextType>(
  {} as SessionContextType,
);

export function SessionProvider({ children }: any) {
  function reloadUser() {
    queryClient.invalidateQueries(QueryServerKeys.USER.GET_USER_DATA); // TODO: call invalidate queries on every useQuery that calls reloadUser instead of making it here
  }

  const {
    user,
    isAuthenticated,
    isLoading,
    error,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const namespace = process.env.REACT_APP_AUTH_NAMESPACE;
  let shallowNesting = {};
  if (user) {
    shallowNesting = {
      uuid: user[`${namespace}/app_metadata`]?.uuid,
      redirectTo: process.env.REACT_APP_RUN_AUTH0_LOCAL
        ? process.env.REACT_APP_URL
        : user[`${namespace}/redirect_to`],
    };

    // Set SSO cookie and redirect url
    const CloudAdminSSO = user[`${namespace}/sso`];
    if (CloudAdminSSO && !cookie.get('CloudAdminSSO')) {
      cookie.set('CloudAdminSSO', CloudAdminSSO, {
        domain: process.env.REACT_APP_DOMAIN,
        expires: 1000 * 60 * 60 * 24 * 365,
        secure: true,
        sameSite: 'strict',
      });
    }
  }

  let [extensions, setExtensions] = useState<UserExtensions>({
    configurations: null,
    platformActions: null,
    toggleFeatures: [],
    features: [],
    instancesCount: 0,
  });

  const extendedUser = {
    ...(shallowNesting as UserData),
    ...extensions,
  } as UserData;

  const { data: result, isLoading: isGetUserPending } = useGetUserData({
    isAuthenticated,
  });

  useEffect(() => {
    if (result) {
      const configurations = result.data.configurations;
      const platformActions = result.data.platformActions;
      const updatedUserData = result?.data?.user;

      setExtensions({
        configurations,
        platformActions,
        ...updatedUserData,
        userDataIsSet: true,
      });
    }
  }, [result, user]);

  const initialState = {
    user: extendedUser,
    isAuthenticated,
    isLoading: isLoading || isGetUserPending,
    extensions,
    error,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
    reloadUser,
  };

  return (
    <SessionContext.Provider value={initialState}>
      {children}
    </SessionContext.Provider>
  );
}
