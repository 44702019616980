import { FilterOutlined, EditOutlined } from '@ant-design/icons';

import { useState, useCallback } from 'react';
import { Typography, Button, Tooltip, Space } from 'antd';

import ContextManagerModal from './ContextManagerModal/ContextManagerModal';
import ContextManagerCriteriaModal from './ContextManagerModal/ContextManagerCriteriaModal';
import { getOperators } from 'services/contextManager';
import useRequest from 'hooks/useRequest';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';

let { Title } = Typography;

function ContextManager() {
  let [search, setSearch] = useState('');
  let [modalProps, setModalProps] = useState<any>(null);
  let [criteriaModalProps, setCriteriaModalProps] = useState<any>(null);

  let {
    result,
    isPending,
    makeRequest: refetchOperators,
  } = useRequest(
    useCallback(() => getOperators(search), [search]),
    { resolve: true },
  );

  let operators = result?.data?.operators;

  let columns = [
    {
      title: 'Cluster Code',
      dataIndex: 'cluster_code',
      width: '20%',
    },
    {
      title: 'Region',
      dataIndex: 'region',
    },
    {
      title: 'Zone',
      dataIndex: 'zone',
    },
    {
      title: 'Url',
      dataIndex: 'url',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      width: '10%',
      render(priority) {
        return priority > 0 ? priority : 'Disabled';
      },
    },
    {
      title: 'Action',
      render(_, operator) {
        return (
          <Space>
            <Tooltip title="Edit">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ operator });
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Set Filter Criteria">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setCriteriaModalProps({ operator });
                }}
              >
                <FilterOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="contextmanager">
      <Title>Operators</Title>

      <SearchableTable
        pagination={false}
        dataSource={operators}
        loading={isPending}
        columns={columns}
        onSearch={value => {
          setSearch(value);
        }}
        searchPlaceholder={'Search By Region or Url.'}
      />

      {modalProps && (
        <ContextManagerModal
          {...modalProps}
          refetchOperators={refetchOperators}
          close={() => setModalProps(null)}
        />
      )}
      {criteriaModalProps && (
        <ContextManagerCriteriaModal
          {...criteriaModalProps}
          refetchOperators={refetchOperators}
          close={() => setCriteriaModalProps(null)}
        />
      )}
    </div>
  );
}

export default ContextManager;
