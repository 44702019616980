import { useState, useCallback } from 'react';
import { Modal, Descriptions } from 'antd';
import { format } from 'date-fns';
import { getBackupFunctionStatusTag } from 'utils/backup';
import useRequest from 'hooks/useRequest';

import { getBackupParent } from 'services/backup';

function BackupModal({ backup, close }) {
  let search = backup.parentID;
  let [page] = useState(1);
  let { result } = useRequest(
    useCallback(() => getBackupParent({ page, search }), [page, search]),
    { resolve: true },
  );
  let backupParent = result?.data?.backup;

  return !search || backupParent ? (
    <Modal visible title={'Backup Details'} footer={null} onCancel={close}>
      <Descriptions title={backup.instance.name} layout="horizontal" bordered>
        {(!search && (
          <Descriptions.Item span={3} label="Start Date">
            {format(new Date(backup.date), 'dd-MM-yyyy hh:mm a')}
          </Descriptions.Item>
        )) ||
          (backupParent && (
            <Descriptions.Item span={3} label="Start Date">
              {format(new Date(backupParent.date), 'dd-MM-yyyy hh:mm a')}
            </Descriptions.Item>
          ))}

        <Descriptions.Item span={3} label="Full Backup">
          {backup.fullBackup.toString()}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Metadata Backup Status">
          {getBackupFunctionStatusTag(backup.metadataBackupStatus)}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Disk Backup Status">
          {getBackupFunctionStatusTag(backup.diskBackupStatus)}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="File Store Backup Status">
          {getBackupFunctionStatusTag(backup.filestoreBackupStatus)}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="GCS Bucket Backup Status">
          {getBackupFunctionStatusTag(backup.gcsBucketBackupStatus)}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Velero Backup Status">
          {getBackupFunctionStatusTag(backup.veleroBackupStatus)}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  ) : (
    <div></div>
  );
}

export default BackupModal;
