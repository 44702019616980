import '../../ui/AdvancedSearch/AdvancedSearch.less';

import { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { Form, Input, Button, Select, Modal, message } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { updateOperator } from '../../../services/contextManager';
import useRequest from 'hooks/useRequest';

const { Option } = Select;

const AdvancedSearch = ({
  close,
  columnsList,
  dataSource,
  refetchOperator,
  refetchOperators,
  operator,
}) => {
  const [form] = Form.useForm();
  const [searchKeys, setsearchKeys] = useState<any>(null);
  const [searchValue, setSearchValue] = useState<any>('');

  useEffect(() => {
    const options = [...columnsList];
    setsearchKeys(options);
  }, [columnsList]);

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async searchParam => {
        const response = await updateOperator(
          operator.platform,
          operator.region,
          operator.cluster_code,
          operator.priority,
          searchParam,
        );
        message.success('Success updating operator');
        await refetchOperator();
        await refetchOperators();
      },
      // eslint-disable-next-line
      [operator.url],
    ),
    {
      handleError: true,
    },
  );

  const onFinish = async values => {
    let searchParam = '';
    if (values.defaultSearchKey && values.defaultValueOfSearchKey) {
      searchParam += `'${values.defaultSearchKey}'=${
        values.defaultValueOfSearchKey
      }${values.search ? (values.search.length > 0 ? '&&' : '') : ''}`;
    }
    if (values.defaults) {
      values.defaults.forEach((element, index) => {
        if (element.searchValue && element.searchKey && element.filter) {
          searchParam += `'${element.searchKey}'${element.filter}${
            element.searchValue
          }${'&&'}`;
        }
      });
    }
    if (values.search) {
      values.search.forEach((element, index) => {
        if (element.searchValue && element.searchKey && element.searchFilter) {
          searchParam += `'${element.searchKey}'${element.searchFilter}${
            element.searchValue
          }${values.search.length - 1 === index ? '' : '&&'}`;
        }
      });
    }
    if (searchParam.slice(-2) === '&&') {
      searchParam = searchParam.slice(0, -2);
    }
    await makeRequest(searchParam);
    setSearchValue(searchParam);
    close();
  };

  const handleRemoveItem = field => {
    const formValues = form.getFieldsValue(true);
    let currentOptions = [...columnsList];
    if (formValues.defaultSearchKey) {
      _.remove(currentOptions, function (c) {
        return c === formValues.defaultSearchKey;
      });
    }
    formValues.search.forEach((value, index) => {
      if (value && field.name !== index) {
        _.remove(currentOptions, function (c) {
          return c === value.searchKey;
        });
      }
    });
    setsearchKeys(currentOptions);
  };

  const handleChange = () => {
    const formValues = form.getFieldsValue(true);
    let currentOptions = [...columnsList];
    if (formValues.defaultSearchKey) {
      _.remove(currentOptions, function (c) {
        return c === formValues.defaultSearchKey;
      });
    }
    if (formValues.search) {
      formValues.search.forEach(value => {
        if (value) {
          _.remove(currentOptions, function (c) {
            return c === value.searchKey;
          });
        }
      });
    }
    setsearchKeys(currentOptions);
  };

  return (
    <Modal
      visible
      bodyStyle={{ maxHeight: '750px', overflow: 'auto' }}
      title={`Set Criteria`}
      footer={null}
      onCancel={close}
      width={'50%'}
    >
      {searchKeys && (
        <Form
          form={form}
          name="advanced-search-form"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.List initialValue={dataSource} name="defaults">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields?.map(field => {
                    let item = dataSource[field.name];
                    return (
                      <div key={field.key}>
                        <div className="form-item-row">
                          <Form.Item
                            className="form-item-select"
                            name={[field.name, 'searchKey']}
                            initialValue={item.criteria}
                          >
                            <Select disabled>
                              <Option value={item.criteria}>
                                {item.criteria}
                              </Option>
                            </Select>
                          </Form.Item>

                          <Form.Item
                            className="form-item-select"
                            name={[field.name, 'searchFilter']}
                            initialValue={item.filter}
                          >
                            <Select disabled>
                              <Option value={item.filter}>{item.filter}</Option>
                            </Select>
                          </Form.Item>

                          <Form.Item
                            className="form-item-input"
                            name={[field.name, 'searchValue']}
                            initialValue={item.value}
                          >
                            <Input />
                          </Form.Item>
                          <MinusCircleOutlined
                            style={{ color: 'rgb(166, 11, 0)' }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              );
            }}
          </Form.List>

          <Form.List name="search">
            {(fields, { add, remove }) => (
              <>
                {fields.map(field => (
                  <div key={field.key}>
                    <div className="form-item-row">
                      <Form.Item
                        {...field}
                        className="form-item-select"
                        name={[field.name, 'searchKey']}
                      >
                        <Select onChange={handleChange}>
                          {searchKeys.map(item => (
                            <Option value={item}>{item}</Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        className="form-item-select"
                        name={[field.name, 'searchFilter']}
                      >
                        <Select onChange={handleChange}>
                          <Option value="like">like</Option>
                          <Option value="exact">exact</Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        {...field}
                        className="form-item-input"
                        name={[field.name, 'searchValue']}
                      >
                        <Input />
                      </Form.Item>
                      <MinusCircleOutlined
                        style={{ color: 'rgb(166, 11, 0)' }}
                        onClick={() => {
                          handleRemoveItem(field);
                          remove(field.name);
                        }}
                      />
                    </div>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add New Criteria
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
            <Button type="primary" block htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AdvancedSearch;
