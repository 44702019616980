import React from 'react';
import { Typography, Button, Tooltip, Tabs } from 'antd';
import { BackwardOutlined } from '@ant-design/icons';
import { useParams, useHistory } from 'react-router-dom';
import UserDetails from '../Users/UserDetails/UserDetails';
import InstanceDetails from '../Users/InstanceDetails/InstanceDetails';
import UserConsumption from '../Users/UserConsumption/UserConsumption';
import UserPackages from '../Users/UserPackages/UserPackages';

import AuditHistory from 'components/AuditHistory/AuditHistory';

const { TabPane } = Tabs;

let { Title } = Typography;

function UserInfo() {
  let { userid }: any = useParams();

  let history = useHistory();

  return (
    <div className="UserInfo">
      <div className="tableTitle">
        <Title>Account Details</Title>
        <Tooltip title="Users">
          <Button
            type="primary"
            icon={<BackwardOutlined />}
            onClick={() => {
              history.push(`/provision-user`);
            }}
          >
            Provision Users
          </Button>
        </Tooltip>
      </div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Account & Security" key="1">
          <UserDetails userID={userid} />
        </TabPane>
        <TabPane tab="Clusters" key="2">
          <InstanceDetails userID={userid} />
        </TabPane>
        <TabPane tab="Packages" key="3">
          <UserPackages userID={userid} />
        </TabPane>
        <TabPane tab="Consumption" key="4">
          <UserConsumption userID={userid} />
        </TabPane>
        <TabPane tab="Audit" key="5">
          <AuditHistory userID={userid} instance={null} />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default UserInfo;
