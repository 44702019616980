import axios from 'axios';
import { API_URL } from 'utils/http';

export function getClusters({ page = 1, search, userid = null, limit = 10 }) {
  return axios.get(`${API_URL}/instances`, {
    params: { page, search, userid, limit },
  });
}

export function getClusterTenants({ userId, clusterName }) {
  return axios.get(`${API_URL}/tenants/${userId}/instances/${clusterName}`);
}

export function updateCluster(name, cluster) {
  return axios.put(`${API_URL}/instances/${name}/update`, cluster);
}

export function deleteCluster(name) {
  return axios.delete(`${API_URL}/instances/${name}/delete`);
}

export function deleteMultiCluster(clusterIds) {
  return axios.post(`${API_URL}/instances/delete`, { clusterIds });
}

export function ldapSync(ldapRecord) {
  return axios.post(
    `${API_URL}/instances/${ldapRecord.instanceName}/ldapSync`,
    {
      ldapRecord: ldapRecord,
    },
  );
}

export function scheduler(schedulerRecord) {
  return axios.post(
    `${API_URL}/instances/${schedulerRecord.instanceName}/scheduler`,
    {
      schedulerRecord: schedulerRecord,
    },
  );
}

export function blockClusterFromUrl(name, blockage) {
  return axios.put(`${API_URL}/instances/${name}/blockformurl`, {
    blockage: blockage,
  });
}

export function oneClickMLMultiCluster(oneClickML, clusterIds) {
  const params = {
    oneClickML,
    clusterIds,
  };
  return axios.post(`${API_URL}/instances/oneclickml`, params);
}

export function tenantConfigCluster(key, value, clusterIds) {
  const params = {
    key,
    value,
    clusterIds,
  };
  return axios.post(`${API_URL}/instances/tenantConfig`, params);
}

export function blockMultiClusterFromUrl(clusterIds) {
  const params = {
    blockage: true,
    clusterIds,
  };
  return axios.post(`${API_URL}/instances/block`, params);
}

export function unblockMultiClusterFromUrl(clusterIds) {
  const params = {
    blockage: false,
    clusterIds,
  };
  return axios.post(`${API_URL}/instances/block`, params);
}

export function disconnectCluster(name) {
  return axios.put(`${API_URL}/instances/${name}/disconnect`);
}

export function disconnectMultiCluster(clusterIds) {
  return axios.post(`${API_URL}/instances/disconnect`, { clusterIds });
}

export function connectCluster(name) {
  return axios.put(`${API_URL}/instances/${name}/connect`);
}

export function connectMultiCluster(clusterIds) {
  return axios.post(`${API_URL}/instances/connect`, { clusterIds });
}

export function archiveCluster(name) {
  return axios.post(`${API_URL}/archives/archive`, {
    archiveRecord: { instanceName: name },
  });
}

export function transferClustersOwnership(cluster, clusterIds) {
  const params = {
    ...cluster,
    clusterIds,
  };
  return axios.post(`${API_URL}/instances/transfer`, params);
}

export function cloneCluster(name, cluster) {
  return axios.post(`${API_URL}/instances/${name}/clone`, cluster);
}

export function migrateCluster(name, migrationData) {
  return axios.post(`${API_URL}/instances/${name}/migrate`, migrationData);
}

export function getSizes() {
  return axios.get(`${API_URL}/sizes`);
}

export function getTimezones() {
  return axios.get(`${API_URL}/timezones`);
}

export function upgradeCluster(name, cluster) {
  return axios.put(`${API_URL}/instances/${name}/upgrade`, cluster);
}

export function upgradeMultiCluster(cluster, clusterIds) {
  const params = {
    ...cluster,
    clusterIds,
  };
  return axios.post(`${API_URL}/instances/upgrade`, params);
}

export function collectLogs(name, podType) {
  return axios.post(`${API_URL}/instances/${name}/collectLogs`, {
    podType: podType,
  });
}

export function sendLogsToCloudProvider(name, podType) {
  return axios.post(`${API_URL}/instances/${name}/sendLogsToCloudProvider`, {
    podType: podType,
  });
}

export function refreshScripts(name) {
  return axios.post(`${API_URL}/instances/${name}/refreshScripts`);
}

export function refreshScriptsMultiCluster(clusterIds) {
  const params = {
    clusterIds,
  };
  return axios.post(`${API_URL}/instances/refreshScripts`, params);
}

export function collectMetrics(name) {
  return axios.post(`${API_URL}/instances/${name}/collectMetrics`);
}

export function collectMetricsMultiCluster(clusterIds) {
  const params = {
    clusterIds,
  };
  return axios.post(`${API_URL}/instances/collectMetrics`, params);
}

export function toggleFeatureBits(
  clusterIDs,
  enabledFeatureBitsIDs,
  disabledFeatureBitsIDs,
) {
  const params = {
    clusterIDs,
    enabledFeatureBitsIDs,
    disabledFeatureBitsIDs,
  };
  return axios.put(`${API_URL}/instances/togglefeaturebits`, params);
}
export function sensitiveData(name) {
  return axios.get(`${API_URL}/instances/${name}/secrets`);
}

export function updateClusterImage(name) {
  return axios.put(`${API_URL}/instances/${name}/updateclusterimage`);
}

export function getInstanceCustomization(name) {
  return axios.get(`${API_URL}/instances/${name}/customization`);
}

export function customizeInstance(name, customization) {
  return axios.put(`${API_URL}/instances/${name}/customization`, customization);
}

export function cleanupResources(filters) {
  return axios.post(`${API_URL}/instances/cleanup`, filters);
}

export function cleanupLogs() {
  return axios.get(`${API_URL}/instances/cleanup/logs`);
}

export function updateClusterSlaCost(id, cluster) {
  return axios.put(`${API_URL}/instances/${id}/updateSlaCost`, cluster);
}

export function getInstanceUsers(name) {
  return axios.get(`${API_URL}/instances/${name}/listusers`);
}

export function enableOnDemandLoader(instance) {
  return axios.post(
    `${API_URL}/instances/${instance.name}/on-demand-loader/enable`,
  );
}

export function disableOnDemandLoader(instance) {
  return axios.post(
    `${API_URL}/instances/${instance.name}/on-demand-loader/disable`,
  );
}

export function addUserRole(
  instanceName,
  roleName,
  userEmail,
  bypassValidationFlag,
) {
  return axios.put(`${API_URL}/instances/${instanceName}/adduserrole`, {
    bypassValidationFlag,
    roleName,
    userEmail,
  });
}

export function updateUserRole(instanceName, roleName, userEmail) {
  return axios.put(`${API_URL}/instances/${instanceName}/updateuserrole`, {
    roleName,
    userEmail,
  });
}

export function removeUserRole(instanceName, userEmail) {
  return axios.put(`${API_URL}/instances/${instanceName}/removeuserrole`, {
    userEmail,
  });
}

export function addInstanceLock(lockedAction, clusterIds) {
  const params = {
    lockedAction,
    clusterIds,
  };
  console.log('-- params --- ', params);
  return axios.post(`${API_URL}/instances/addinstancelock`, params);
}

export function removeInstanceLock(unlockedAction, clusterIds) {
  const params = {
    unlockedAction,
    clusterIds,
  };
  return axios.post(`${API_URL}/instances/removeinstancelock`, params);
}

export function addInstanceSelfLock(lockedAction, clusterIds) {
  const params = {
    lockedAction,
    clusterIds,
  };
  return axios.post(`${API_URL}/instances/addinstanceselflock`, params);
}

export function removeInstanceSelfLock(unlockedAction, clusterIds) {
  const params = {
    unlockedAction,
    clusterIds,
  };
  return axios.post(`${API_URL}/instances/removeinstanceselflock`, params);
}

export function getCluster(name) {
  return axios.get(`${API_URL}/instances/${name}`);
}
