import React, { useCallback } from 'react';
import { Modal, Form, Input, Button, Space } from 'antd';
import useRequest from 'hooks/useRequest';
import {
  createBlueprintFunction,
  updateBlueprintFunction,
} from 'services/blueprintFunction';

import './BlueprintFunctionModal.less';

function BlueprintFunctionModal({
  isNew,
  blueprintFunction,
  refetchBlueprintFunctions,
  close,
}) {
  let { id, name } = blueprintFunction ?? {};

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async blueprintFunction => {
        isNew
          ? await createBlueprintFunction(blueprintFunction)
          : await updateBlueprintFunction(id, blueprintFunction);
        await refetchBlueprintFunctions();
      },
      [id, isNew, refetchBlueprintFunctions],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(blueprintFunction) {
    await makeRequest(blueprintFunction);
    close();
  }

  return (
    <Modal
      visible
      title={isNew ? 'New Blueprint Function' : 'Edit Blueprint Function'}
      footer={null}
      onCancel={close}
      wrapClassName="BlueprintFunctionModal"
    >
      <Form
        initialValues={{
          name,
        }}
        layout="vertical"
        name="BlueprintFunction"
        onFinish={handleFinish}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {isNew ? 'Create' : 'Update'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default BlueprintFunctionModal;
