import { useCallback } from 'react';
import { Modal, Form, Input, Button, Space, Switch, Row, Col } from 'antd';
import IdleField from '../IdleField/IdleField';
import { updateCluster } from 'services/cluster';
import useRequest from 'hooks/useRequest';

function ClusterModal({ isNew, cluster, refetchClusters, close }) {
  let {
    name,
    idleTime,
    sleeppable,
    pyPackages,
    enableWelcome,
    enableChat,
    enableOnboarding,
    allowBackup,
    filestoreMountPath,
    cname,
    sparkCpu,
    sparkMem,
    hostName,
  } = cluster ?? {};
  console.debug(`CLUSTER: ${JSON.stringify(cluster)}`);
  let enableOnDemandLoader = cluster.onDemandLoader
    ? cluster.onDemandLoader.enabled
    : false;
  cluster.enableOnDemandLoader = enableOnDemandLoader;
  let { makeRequest, isPending } = useRequest(
    useCallback(
      async cluster => {
        await updateCluster(name, cluster);
        await refetchClusters();
      },
      [name, refetchClusters],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(cluster) {
    await makeRequest(cluster);
    close();
  }

  return (
    <Modal
      visible
      title={isNew ? 'New Cluster' : 'Edit Cluster'}
      footer={null}
      onCancel={close}
    >
      <Form
        initialValues={{
          name,
          sleeppable,
          idleTime,
          pyPackages,
          enableWelcome,
          enableChat,
          enableOnboarding,
          allowBackup,
          filestoreMountPath,
          cname,
          sparkCpu,
          sparkMem,
          hostName,
          enableOnDemandLoader,
        }}
        layout="vertical"
        name="cluster"
        onFinish={handleFinish}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input disabled={!isNew} />
        </Form.Item>
        <IdleField label="Idle Time" name="idleTime" />
        <Row align="stretch" gutter={24} justify="space-around" key="guides">
          <Col span={8} key="enableWelcome">
            <Form.Item
              name="enableWelcome"
              label="Welcome Message"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8} key="enableChat">
            <Form.Item name="enableChat" label="Chat" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>

          <Col span={8} key="enableOnboarding">
            <Form.Item
              name="enableOnboarding"
              label="Home Page"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          {console.debug(cluster)}
          {console.debug(`enableOnDemandLoader: ${enableOnDemandLoader}`)}
          <Col span={8} key="enableOnDemandLoader">
            <Form.Item
              name="enableOnDemandLoader"
              label="On-Demand Loader"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="cname" label="Cname">
          <Input />
        </Form.Item>
        <Form.Item name="hostName" label="Alias (Host Name)">
          <Input />
        </Form.Item>
        <Form.Item name="filestoreMountPath" label="File Store Mount Path">
          <Input />
        </Form.Item>
        <Form.Item name="sparkCpu" label="Spark Resource Quota (CPU)">
          <Input />
        </Form.Item>
        <Form.Item name="sparkMem" label="Spark Resource Quota (Mem)">
          <Input />
        </Form.Item>
        <Form.Item name="pyPackages" label="Python Packages">
          <Input.TextArea style={{ height: 150 }} />
        </Form.Item>
        <Form.Item name="description" label="Cluster Description">
          <Input.TextArea style={{ height: 300 }} />
        </Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {isNew ? 'Create' : 'Update'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default ClusterModal;
