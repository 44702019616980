import React, { ComponentType, useContext, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Login from '../Login/Login';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import { SessionContext } from '../../auth/SessionProvider';
import Home from 'components/Home/Home';
import ProvisionUsers from 'components/ProvisionUsers/ProvisionUsers';
import Users from 'components/Users/Users';
import Configurations from 'components/Configurations/Configurations';
import BlueprintCategories from 'components/BlueprintCategories/BlueprintCategories';
import AccountManager from 'components/AccountManager/AccountManager';
import AccountManagerUsers from 'components/AccountManager/AccountManagerUsers';
import AuditHistory from 'components/AuditHistory/AuditHistory';
import Notification from 'components/Notification/Notification';
import ProvisionUserInfo from 'components/ProvisionUsers/UserInfo';
import Backups from 'components/Backups/Backups';
import BlueprintFunctions from 'components/BlueprintFunctions/BlueprintFunctions';
import Blueprints from 'components/Blueprints/Blueprints';
import Cli from 'components/Cli/Cli';
import Clusters from 'components/Clusters/Clusters';
import ClustersStatus from 'components/ClustersStatus/clustersstatus';
import ContextManager from 'components/ContextManager/ContextManager';
import CostAwareness from 'components/CostAwareness/costAwareness';
import DataAgents from 'components/DataAgents/DataAgents';
import ExportedBlueprints from 'components/ExportedBlueprints/ExportedBlueprints';
import FeatureBits from 'components/FeatureBits/FeatureBits';
import Images from 'components/Images/images';
import Infrastructure from 'components/Infrastructure/Infrastructure';
import NewProvisionCustomer from 'components/NewProvisionCustomer/NewProvisionCustomer/NewProvisionCustomer';
import PlatformAction from 'components/PlatformAction/PlatformAction';
import SparkImages from 'components/SparkImages/SparkImages';
import SupportDashboard from 'components/SupportDashboard/SupportDashboard';
import ToggleFeature from 'components/ToggleFeatures/ToggleFeature';
import ToggleFeatures from 'components/ToggleFeatures/ToggleFeatures';
import UserInfo from 'components/Users/UserInfo';
import EmailSentPage from 'components/EmailSentPage/EmailSentPage';
import NavigationLayout from 'components/ui/NavigationLayout/NavigationLayout';
import { ErrorHandler } from 'auth/ErrorHandler';
import { userPermissionTypes } from 'utils/user';
import { Spinner } from 'components/Spinner/Spinner';
import OverCommitment from 'components/guidance/OverCommitment/OverCommitment';
import ClustersActions from 'components/clusterActions/ClusterActions';
import ClusterSizes from 'components/guidance/ClusterSizes/ClusterSizes';
import ChidoriSizes from 'components/guidance/ChidoriSizes/ChidoriSizes';

interface LocationState {
  redirectTo?: any;
}

function AppRoutes() {
  const { user, isAuthenticated, isLoading, error } =
    useContext(SessionContext);
  const { pathname } = useLocation<LocationState>();
  useEffect(() => {
    let script = document.createElement('script');
    if (pathname === '/signup' || pathname === '/signup/') {
      script = document.createElement('script');

      script.src = '//js.hs-scripts.com/7507810.js';
      script.async = true;
      script.defer = true;
      script.id = 'hs-script-loader';

      document.body.appendChild(script);
      document
        .querySelectorAll('.hs-cookie-notification-position-bottom')
        .forEach((e: any) => (e.style.display = 'block'));
    } else {
      document
        .querySelectorAll('.hs-cookie-notification-position-bottom')
        .forEach((e: any) => (e.style.display = 'none'));
    }
  });

  function getCookie(cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  const isLoggedIn = !!getCookie('CloudAdminSSO');
  const userPermission = user.permission;
  const isAdmin = userPermission === userPermissionTypes.admin;
  const isSuperAdmin = userPermission === userPermissionTypes.superAdmin;
  const isSupport = userPermission === userPermissionTypes.support;
  const isReleaseManager =
    userPermission === userPermissionTypes.releaseManager;

  if (error) {
    const { message, userEmail } = JSON.parse(error.message);
    return <ErrorHandler message={message as string} userEmail={userEmail} />;
  }

  if (!isAuthenticated && !isLoggedIn) {
    return <Login />;
  }

  if (user?.uuid && !user?.userDataIsSet) {
    return <Spinner />;
  }

  if (user?.email && isAuthenticated) {
    if (!user?.confirmed) {
      return <ErrorHandler message={'Verify Your Email'} />;
    } else if (user?.permission === userPermissionTypes.guest) {
      return <ErrorHandler message={"You don't have permission"} />;
    }
  }

  return (
    <NavigationLayout>
      <Switch>
        <ProtectedRoute exact path="/" component={Home} />
        <ProtectedRoute
          exact
          path="/provision-user"
          component={ProvisionUsers}
        />

        <ProtectedRoute exact path="/users" component={Users} />
        <ProtectedRoute
          exact
          path="/configurations"
          component={Configurations}
        />
        <ProtectedRoute
          exact
          path="/blueprints"
          component={Blueprints}
          isLoading={isLoading || !userPermission}
          isPermitted={isAdmin || isSuperAdmin}
        />
        <ProtectedRoute
          exact
          path="/exportedblueprints"
          component={ExportedBlueprints}
          isLoading={isLoading || !userPermission}
          isPermitted={isAdmin || isSuperAdmin}
        />
        <ProtectedRoute
          exact
          path="/blueprintcategories"
          component={BlueprintCategories}
          isLoading={isLoading || !userPermission}
          isPermitted={isAdmin || isSuperAdmin}
        />
        <ProtectedRoute
          exact
          path="/blueprintfunctions"
          component={BlueprintFunctions}
        />
        <ProtectedRoute exact path="/clusters" component={Clusters} />
        <ProtectedRoute
          exact
          path="/support-dashboard"
          component={SupportDashboard}
        />
        <ProtectedRoute exact path="/dataagents" component={DataAgents} />

        <ProtectedRoute
          exact
          path="/togglefeatures"
          component={ToggleFeatures}
        />

        <ProtectedRoute
          path="/togglefeatures/:feature"
          component={ToggleFeature}
        />

        <ProtectedRoute
          path="/accounting/userdetails/:userid"
          component={UserInfo}
        />

        <ProtectedRoute
          path="/accounting/provisionuserdetails/:userid"
          component={ProvisionUserInfo}
        />
        <ProtectedRoute path="/accountmanagers" component={AccountManager} />

        <ProtectedRoute
          path="/accountmanagers/users"
          component={AccountManagerUsers}
        />
        <ProtectedRoute path="/clicommands" component={Cli} />

        <ProtectedRoute path="/notifications" component={Notification} />

        <ProtectedRoute exact path="/audit" component={AuditHistory} />

        <ProtectedRoute
          exact
          path="/images"
          component={Images}
          isLoading={isLoading || !userPermission}
          isPermitted={isAdmin || isSuperAdmin || isSupport || isReleaseManager}
        />
        <ProtectedRoute
          exact
          path="/contextmanager"
          component={ContextManager}
          isLoading={isLoading || !userPermission}
          isPermitted={isAdmin || isSuperAdmin}
        />
        <ProtectedRoute exact path="/costawareness" component={CostAwareness} />

        <ProtectedRoute
          exact
          path="/clustersstatus"
          component={ClustersStatus}
        />

        <ProtectedRoute exact path="/featurebits" component={FeatureBits} />

        <ProtectedRoute exact path="/backups" component={Backups} />

        <ProtectedRoute
          exact
          path="/sparkimages"
          component={SparkImages}
          isLoading={isLoading || !userPermission}
          isPermitted={isAdmin || isSuperAdmin || isSupport || isReleaseManager}
        />
        <ProtectedRoute
          exact
          path="/infrastructure"
          component={Infrastructure}
          isLoading={isLoading || !userPermission}
          isPermitted={isAdmin || isSuperAdmin}
        />

        <ProtectedRoute
          exact
          path="/platform"
          component={PlatformAction}
          isLoading={isLoading || !userPermission}
          isPermitted={isAdmin || isSuperAdmin || isSupport}
        />

        <ProtectedRoute
          exact
          path="/provision-customer/new"
          component={NewProvisionCustomer}
          isLoading={isLoading || !userPermission}
          isPermitted={isAdmin || isSuperAdmin || isSupport}
        />

        <ProtectedRoute exact path="/oclabels" component={OverCommitment} />

        <ProtectedRoute
          exact
          path="/clustersactions"
          component={ClustersActions}
        />
        <Route exact path="/verify">
          <EmailSentPage />
        </Route>

        <Route exact path="/cluster-sizes" component={ClusterSizes} />
        <Route exact path="/chidori-sizes" component={ChidoriSizes} />

        <Redirect to="/" />
      </Switch>
    </NavigationLayout>
  );
}

interface ProtectedRouteProps {
  path?: string | string[];
  exact?: boolean;
  component: ComponentType<any>;
  isLoading?: boolean;
  isPermitted?: boolean;
  redirectURL?: string;
}

function ProtectedRoute({
  path,
  exact,
  component,
  isLoading,
  isPermitted = true,
  redirectURL = '/',
}: React.PropsWithChildren<ProtectedRouteProps>) {
  function Redirection() {
    return <Redirect to={redirectURL} />;
  }
  const location = useLocation();

  return (
    <Route
      exact={exact}
      path={path}
      component={
        isPermitted
          ? withAuthenticationRequired(component, {
              onRedirecting: () => <FullPageLoader />,
              returnTo: location.pathname || '/',
              loginOptions: {
                redirectUri: window.location.origin,
                appState: {
                  returnTo: location.pathname,
                },
              },
            })
          : isLoading
          ? Spinner
          : Redirection
      }
    />
  );
}

export default AppRoutes;
