import { message } from 'antd';

export async function multiclusterAction(
  action,
  params,
  refetchClusters,
  setErrorModalProps,
) {
  try {
    const response = await action(...params);
    message.success(response.data.message);
    await refetchClusters();
  } catch (e: any) {
    const data = e.response.data;
    message.error({
      content: data.message,
      onClick: () => {
        setErrorModalProps({
          message: data.message,
          responseMessages: data.responseMessages,
        });
      },
    });
  }
}
