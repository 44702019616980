import React, { useState, useCallback } from 'react';
import { Typography, Button, Tooltip, Space, Modal, Tag } from 'antd';
import { formatISO } from 'date-fns';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import PackageModal from '../../Accounting/PackageModal/PackageModal';
import useRequest from 'hooks/useRequest';
import { deletePackage } from 'services/package';
import { getUser } from 'services/user';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';

let { Title } = Typography;

function UserPackages({ userID }) {
  let { userid }: any = useParams();
  let [param] = useState(userid);

  let {
    result,
    isPending,
    makeRequest: refetchUsers,
  } = useRequest(
    useCallback(() => getUser({ param }), [param]),

    { resolve: true },
  );

  let [packageModalProps, setPackageModalProps] = useState<any>(null);

  const packagesColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render(_, pack) {
        return (
          <span>
            {pack.name}
            &emsp;
            {pack.currentlyActive && <Tag color="green">Currently Active</Tag>}
          </span>
        );
      },
    },
    { title: 'Type', dataIndex: 'type' },
    { title: 'State', dataIndex: 'state' },
    { title: 'Available Units', dataIndex: 'availableUnits' },
    { title: 'Consumed Units', dataIndex: 'consumedUnits' },
    {
      title: 'Start Date',
      width: '9%',
      dataIndex: 'start',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },
    {
      title: 'Renewal Date',
      width: '9%',
      dataIndex: 'renewedAt',
      render(date) {
        return date
          ? formatISO(new Date(date), { representation: 'date' })
          : '';
      },
    },
    {
      title: 'End Date',
      dataIndex: 'end',
      width: '9%',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },
    {
      title: 'Action',
      width: '8%',
      render(_, pack) {
        return (
          <Space>
            {
              <Tooltip title="Remove">
                <Button
                  size="small"
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <>
                          Are you sure you want to remove
                          <strong> {pack.name} </strong> ?
                        </>
                      ),
                      async onOk() {
                        await deletePackage(pack.id);
                        await refetchUsers();
                      },
                    });
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            }
          </Space>
        );
      },
    },
  ];

  const packagesData = result?.data.user.packages;

  return (
    <div className="UserPackages">
      <div className="tableTitle">
        <Title level={3}>Packages</Title>
        <Tooltip title="Create">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setPackageModalProps({ isNew: true, userPackageID: userID });
            }}
          >
            Add Package
          </Button>
        </Tooltip>
      </div>

      <SearchableTable
        pagination={false}
        loading={isPending}
        columns={packagesColumns}
        dataSource={packagesData}
      />

      {packageModalProps && (
        <PackageModal
          {...packageModalProps}
          refetchPackages={refetchUsers}
          close={() => setPackageModalProps(null)}
        />
      )}
    </div>
  );
}

export default UserPackages;
