import './TagField.less';

import React from 'react';
import { Select, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { getBlueprintTags } from 'services/blueprintCategory';

let { Option } = Select;

function TagField({ name, label }) {
  let { result, isPending } = useRequest(getBlueprintTags, {
    resolve: true,
    handleError: true,
    useCache: true,
  });

  let blueprintsTags = result?.data?.blueprintsTags;

  return (
    <Form.Item
      className="Tag"
      name={name}
      label={label}
      rules={[{ required: false }]}
    >
      <FieldSelect
        loading={isPending && !blueprintsTags}
        blueprintsTags={blueprintsTags}
      />
    </Form.Item>
  );
}

function FieldSelect({ value, onChange, loading, blueprintsTags }: any) {
  return (
    <Select
      value={blueprintsTags ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="Tag__select-dropdown"
      allowClear
    >
      {blueprintsTags?.map(blueprintsTag => (
        <Option key={blueprintsTag} value={blueprintsTag}>
          {blueprintsTag}
        </Option>
      ))}
    </Select>
  );
}

export default TagField;
