import React, { useCallback, useContext } from 'react';
import { Modal, Form, Input, Button, Space, Switch, Row, Col } from 'antd';
import moment from 'moment';
import RoleField from '../RoleField/RoleField';
import PermissionField from '../PermissionField/PermissionField';
import useRequest from 'hooks/useRequest';
import { deleteUser, updateUser } from 'services/user';
import { SessionContext } from 'auth/SessionProvider';

function UserModal({ isNew, user, refetchUsers, close }) {
  const { user: me } = useContext(SessionContext);

  let {
    email,
    firstName,
    lastName,
    company,
    phone,
    jobTitle,
    role,
    uuid,
    permission,
    trialExpiration,
    organization,
    cost_center,
    isPartner,
    enableMarketplace,
  } = user ?? {};

  if (user) {
    const differenceInDays = moment
      .duration(
        moment(trialExpiration).startOf('day').diff(moment().startOf('day')),
      )
      .asDays();
    trialExpiration = differenceInDays < 0 ? 0 : differenceInDays;
  }

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async user => {
        await updateUser(uuid, user);
        await refetchUsers();
      },
      [uuid, refetchUsers],
    ),
    {
      handleError: true,
    },
  );

  let { makeRequest: deleteUserRequest } = useRequest(
    useCallback(async () => {
      await deleteUser(uuid);
      await refetchUsers();
    }, [uuid, refetchUsers]),
    {
      handleError: true,
    },
  );

  async function handleFinish(user) {
    await makeRequest(user);
    close();
  }

  async function handleDeleteUser() {
    await deleteUserRequest();
    close();
  }

  return (
    <Modal
      visible
      title={isNew ? 'New User' : 'Edit User'}
      footer={null}
      onCancel={close}
    >
      <Form
        initialValues={{
          email,
          firstName,
          lastName,
          company,
          phone,
          jobTitle,
          role: role.name,
          permission,
          trialExpiration,
          organization,
          cost_center,
          isPartner,
          enableMarketplace,
        }}
        layout="vertical"
        name="User"
        onFinish={handleFinish}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, type: 'email' }]}
        >
          <Input disabled={!isNew} />
        </Form.Item>

        {me.permission === 'super admin' && (
          <RoleField label="Account Type" name="role" />
        )}

        <Row align="stretch" gutter={24} justify="space-around" key="guides">
          <Col span={13} key="permission">
            <PermissionField label="Permission" name="permission" />
          </Col>

          <Col span={4} key="partner">
            <Form.Item name="isPartner" label="Partner" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>

          <Col span={7} key="marketplace">
            <Form.Item
              name="enableMarketplace"
              label="App Marketplace"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>

        <Row align="stretch" gutter={24} justify="space-around" key="guides">
          <Col span={12} key="firstName">
            <Form.Item name="firstName" label="First name">
              <Input />
            </Form.Item>
          </Col>

          <Col span={12} key="lastName">
            <Form.Item name="lastName" label="Last name">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {user.role.name === 'free' && (
          <Form.Item
            name="trialExpiration"
            label="Trial Expiration"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        )}

        <Form.Item name="company" label="Company">
          <Input />
        </Form.Item>

        <Form.Item name="jobTitle" label="Job title">
          <Input />
        </Form.Item>

        <Form.Item name="phone" label="Phone">
          <Input />
        </Form.Item>

        <Form.Item name="organization" label="Organization">
          <Input />
        </Form.Item>

        <Form.Item name="cost_center" label="cost_center">
          <Input />
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {isNew ? 'Create' : 'Update'}
          </Button>
          <Button onClick={close}>Cancel</Button>
          {me.permission === 'super admin' && (
            <Button
              type="primary"
              danger
              onClick={() => {
                Modal.confirm({
                  title: (
                    <>
                      Are you sure you want to delete{' '}
                      <strong>{user.email}</strong>?
                    </>
                  ),
                  async onOk() {
                    await handleDeleteUser();
                  },
                });
              }}
            >
              Delete
            </Button>
          )}
        </Space>
      </Form>
    </Modal>
  );
}

export default UserModal;
