import { useContext } from 'react';
import { SessionContext } from './SessionProvider';
import EmailSentPage from 'components/EmailSentPage/EmailSentPage';
import GuestUserErrorPage from 'components/ErrorPages/GuestUser/GuestUserErrorPage';

type ErrorHandlerProps = {
  message: string;
  userEmail?: string;
};
export function ErrorHandler({ message }: ErrorHandlerProps) {
  const { logout } = useContext(SessionContext);

  if (message.toLocaleLowerCase().includes('verify')) {
    return <EmailSentPage />;
  } else if (message.toLocaleLowerCase().includes('permission')) {
    return <GuestUserErrorPage />;
  } else if (message.toLocaleLowerCase().includes('state'))
    return <>Invalid State</>;
  else if (
    message.toLocaleLowerCase().includes('please change your password')
  ) {
    logout({ returnTo: `${process.env.REACT_APP_URL}` });
    return null;
  } else return <>message</>;
}
