import { useCallback, useState } from 'react';
import { Modal, Form, Button, Space, Select } from 'antd';
import useRequest from 'hooks/useRequest';
import { addInstanceLock, addInstanceSelfLock } from 'services/cluster';
import { useGetConfigurations } from 'hooks/queries';
import { multiclusterAction } from 'utils/helpers';

function AddActionModal({
  close,
  clusterIds,
  tab,
  refetchClusters,
  setErrorModalProps,
  closeMain,
}) {
  const [action, setAction] = useState<undefined | string>(undefined);
  let [page, setPage] = useState(1);
  let [search, setSearch] = useState('Instance Locked Actions');

  const {
    data: result,
    isLoading,
    refetch: refetchConfigurations,
  } = useGetConfigurations({ page, search });
  let configurations = result?.data?.configurations;
  if (configurations) {
    configurations = configurations[0]?.configValue?.split(',');
    configurations = configurations?.map(item => item.trim());
    if (tab == 'selfActions')
      configurations = configurations.filter(configuration =>
        configuration.startsWith('Self'),
      );
    else
      configurations = configurations.filter(
        configuration => !configuration.startsWith('Self'),
      );
  }

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async addedAction => {
        if (tab == 'selfActions') {
          await multiclusterAction(
            addInstanceSelfLock,
            [addedAction, clusterIds],
            refetchClusters,
            setErrorModalProps,
          );
        } else {
          await multiclusterAction(
            addInstanceLock,
            [addedAction, clusterIds],
            refetchClusters,
            setErrorModalProps,
          );
        }
        await refetchClusters();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [refetchClusters, clusterIds],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(record) {
    await makeRequest(record.action);
    closeMain();
    close();
  }

  return (
    <Modal visible title={'Choose Action'} footer={null} onCancel={close}>
      <Form layout="vertical" name="schedulerRecord" onFinish={handleFinish}>
        <Form.Item name="action">
          <Select
            placeholder="Select Action"
            value={action}
            onChange={action => {
              setAction(action);
            }}
            loading={isLoading}
          >
            {configurations &&
              configurations.map(action => {
                return (
                  <Select.Option key={action} value={action}>
                    {action}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={isPending}>
              {'Add'}
            </Button>
            <Button onClick={close}>Cancel</Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddActionModal;
