import React, { useCallback } from 'react';
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Col,
  Row,
  Button,
  Space,
  message,
  Switch,
} from 'antd';
import CategoryField from '../CategoryField/CategoryField';
import CategoryFieldTrimmed from '../CategoryField/CategoryFieldTrimmed';
import BusinessFunctionField from '../BusinessFunctionField/BusinessFunctionField';
import SupportedVersionField from '../SupportedVersionField/SupportedVersionField';

import TagField from '../TagField/TagField';
import TypeField from '../TypeField/TypeField';

import useRequest from 'hooks/useRequest';
import { createBlueprint, updateBlueprint } from 'services/blueprint';
import Upload from 'components/ui/Upload/Upload';

function BlueprintModal({ isNew, blueprint, refetchBlueprints, close }) {
  let {
    id,
    name,
    blueprintCategoryID,
    url,
    position,
    description,
    businessFunction,
    type,
    owner,
    ownerLogo,
    tag,
    fullImport,
    incortaVersionFormatted,
    enable,
    visibleForTrial,
  } = blueprint ?? {};

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async blueprint => {
        isNew
          ? await createBlueprint(blueprint)
          : await updateBlueprint(id, blueprint);
        await refetchBlueprints();
      },
      [id, isNew, refetchBlueprints],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(blueprint) {
    await makeRequest(blueprint);
    close();
  }

  return (
    <Modal
      visible
      title={isNew ? 'New Blueprint' : 'Edit Blueprint'}
      footer={null}
      onCancel={close}
    >
      <Form
        initialValues={{
          name,
          blueprintCategoryID,
          url,
          position,
          description,
          owner,
          ownerLogo,
          businessFunction,
          type,
          tag,
          fullImport,
          incortaVersionFormatted,
          enable,
          visibleForTrial,
        }}
        layout="vertical"
        name="Blueprint"
        onFinish={handleFinish}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input disabled={!isNew} />
        </Form.Item>

        <Row align="stretch" gutter={24} justify="space-around" key="functions">
          <Col span={10} key="businessFunction">
            <BusinessFunctionField
              label="Business Function"
              name="businessFunction"
            />
          </Col>
          <Col span={14} key="Category">
            <CategoryField name="blueprintCategoryID" label="Category" />
          </Col>
        </Row>

        <Form.Item
          name="url"
          label="Incorta Website URL"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Row align="stretch" gutter={24} justify="space-around" key="stat">
          <Col span={10} key="businessFunction">
            <TypeField label="Type" name="type" />
          </Col>

          <Col span={8} key="tag">
            <TagField label="Tag" name="tag" />
          </Col>

          <Col span={6} key="position">
            <Form.Item
              name="position"
              label="Order"
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <SupportedVersionField
          label="Supported Incorta Version"
          name="incortaVersionFormatted"
          blueprint={blueprint}
        />

        <Row align="stretch" gutter={24} justify="space-around" key="displays">
          <Col span={8} key="fullImport">
            <Form.Item
              name="fullImport"
              label="Full Tenant Import"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8} key="enable">
            <Form.Item
              name="enable"
              label="Enabled For Users"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8} key="visibleForTrial">
            <Form.Item
              name="visibleForTrial"
              label="Visible for trial users"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="description" label="Blueprint Description">
          <Input.TextArea maxLength={280} rows={5} />
        </Form.Item>

        <Row align="stretch" gutter={24} justify="space-around" key="owners">
          <Col span={14} key="owner">
            <Form.Item
              name="owner"
              label="Blueprint Owner"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10} key="logo">
            <CategoryFieldTrimmed name="ownerLogo" label="Logo" />
          </Col>
        </Row>

        <Form.Item
          name="exportedFile"
          label="Exported File"
          rules={[{ required: isNew }]}
        >
          <Upload
            draggable
            beforeUpload={file => {
              const isValue = [
                'application/x-zip-compressed',
                'application/zip',
              ].includes(file.type);
              if (!isValue) {
                message.error('You can only upload  Zip file');
                return false;
              }

              const isLt2M = file.size / 1024 / 1024 < 20;
              if (!isLt2M) {
                message.error('Image must smaller than 2MB!');
                return false;
              }

              return isValue && isLt2M;
            }}
            notImage
          />
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {isNew ? 'Create' : 'Update'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default BlueprintModal;
