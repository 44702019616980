import React from 'react';
import './ErrorModal.less';
import { Descriptions, Modal, Tag, Tooltip } from 'antd';

function ErrorModal({ message, responseMessages, close }) {
  function statusTags(status, message) {
    return status < 300 ? (
      <Tag color="green">{message}</Tag>
    ) : status < 400 ? (
      <Tag color="grey">{message}</Tag>
    ) : (
      <Tag color="red">{message}</Tag>
    );
  }

  function inflateItems(responses) {
    return responses?.map(res => {
      return (
        <Descriptions.Item
          span={3}
          label={res.property}
          className="error-modal__descriptions-item"
        >
          <Tooltip title={res.message}>
            {statusTags(res.status, res.message)}
          </Tooltip>
        </Descriptions.Item>
      );
    });
  }

  return (
    <Modal visible title={message} footer={null} onCancel={close}>
      <Descriptions layout="horizontal" bordered>
        {inflateItems(responseMessages)}
      </Descriptions>
    </Modal>
  );
}

export default ErrorModal;
