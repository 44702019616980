import React, { useCallback } from 'react';
import { Modal, Form, Button, Space } from 'antd';
import PriorityField from '../PriorityField/PriorityField';
import { updateOperator } from 'services/contextManager';
import useRequest from 'hooks/useRequest';

function ContextManagerModal({ operator, close, refetchOperators }) {
  let { platform, region, cluster_code, filteringCriteria, priority } =
    operator ?? {};

  let { makeRequest } = useRequest(
    useCallback(
      async operator => {
        console.log(platform);
        await updateOperator(
          platform,
          region,
          cluster_code,
          operator.priority,
          filteringCriteria,
        );
        await refetchOperators();
      },
      // eslint-disable-next-line
      [refetchOperators],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(operator) {
    await makeRequest(operator);
    close();
  }

  return (
    <Modal visible title={'Edit Priority'} footer={null} onCancel={close}>
      <Form
        initialValues={{
          region,
          cluster_code,
          priority,
        }}
        layout="vertical"
        name="Operator"
        onFinish={handleFinish}
      >
        <PriorityField label="Priority" name="priority" />
        <Space>
          <Button type="primary" htmlType="submit">
            {'Update'}
          </Button>
        </Space>
        <Space>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default ContextManagerModal;
