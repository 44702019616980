import { useCallback, useState } from 'react';
import moment from 'moment';

import {
  Modal,
  Form,
  Input,
  Button,
  Space,
  Radio,
  Switch,
  Row,
  Col,
  DatePicker,
  TimePicker,
} from 'antd';
import { upgradeCluster, upgradeMultiCluster } from 'services/cluster';
import useRequest from 'hooks/useRequest';
import { multiclusterAction } from 'utils/helpers';

function PackageModal({
  isNew,
  cluster,
  refetchClusters,
  close,
  clusterIds,
  clusterName,
  setErrorModalProps,
}) {
  let { name, version } = cluster ?? {};
  const [scheduled, setScheduled] = useState(false);
  const toggleScheduled = () => {
    setScheduled(!scheduled);
  };

  const disabledDate = current => {
    return current && current < moment().startOf('day');
  };

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async cluster => {
        !clusterIds
          ? await upgradeCluster(clusterName, cluster)
          : await multiclusterAction(
              upgradeMultiCluster,
              [cluster, clusterIds],
              refetchClusters,
              setErrorModalProps,
            );
        await refetchClusters();
      },
      // eslint-disable-next-line
      [name, refetchClusters, clusterIds],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(cluster) {
    await makeRequest(cluster);
    close();
  }

  return (
    <Modal
      visible
      title={'Upgrade To Custom Build'}
      footer={null}
      onCancel={close}
    >
      <Form
        initialValues={{
          name,
          version,
        }}
        layout="vertical"
        name="cluster"
        onFinish={handleFinish}
      >
        <Form.Item
          extra="Use release number for official release, ex: 4.8, 4.9.1"
          name="version"
          label="Build number"
          rules={[{ required: true }]}
        >
          <Input placeholder="Incorta-20200919-0030-470" />
        </Form.Item>

        <Form.Item name="upgradeMode" label="Upgrade From">
          <Radio.Group defaultValue="true">
            <Radio value="true">Custom Build</Radio>
            <Radio value="false">Official Release</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="backupNow" label="Scheduled" valuePropName="checked">
          <Switch onClick={toggleScheduled} />
        </Form.Item>

        {scheduled && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="date"
                label="Scheduled Date"
                rules={[{ required: true }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="time"
                label="Scheduled Time"
                rules={[{ required: true }]}
              >
                <TimePicker
                  style={{ width: '100%' }}
                  use12Hours
                  format="h:mm a"
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {'Upgrade'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default PackageModal;
