import axios from 'axios';
import { API_URL } from 'utils/http';

export function submitChidori(values, instance) {
  return axios.post(`${API_URL}/spark/chidori`, {
    values,
    instanceName: instance.name,
    userID: instance.userID,
  });
}

export async function fetchInstanceChidoriStatus(instanceId) {
  try {
    const res = await axios.get(`${API_URL}/spark/fetchChidori`, {
      params: { instanceId },
    });
    return res.data.chidoriState;
  } catch (e) {
    throw new Error(e);
  }
}

export type chidoriSizeItem = {
  id: number;
  size: string;
  sizeValue: number;
  createdAt: Date;
  updatedAt: Date;
};
type chidoriSizesResult = {
  allChidoriSizesInTable: {
    count: number;
    rows: chidoriSizeItem[];
  };
  total: number;
  limit: number;
  page: number;
  pagesCount: number;
};
export async function getChidoriSizes({ page = 1, search, userid = null }) {
  try {
    const res = await axios.get<chidoriSizesResult>(
      `${API_URL}/spark/getChidoriSizesTable`,
      {
        params: { page, search, userid },
      },
    );
    return res.data.allChidoriSizesInTable.rows;
  } catch (e) {
    throw new Error(e);
  }
}

export type modifiedChidoriSize = { name: string; memory: string };
export async function updateChidoriSizes({
  chidoriSizes,
}: {
  chidoriSizes: modifiedChidoriSize[];
}) {
  try {
    const res = await axios.post<any>(
      `${API_URL}/spark/updateChidoriSizesTable`,
      {
        chidoriSizes,
      },
    );
    return res;
  } catch (e) {
    throw new Error(e);
  }
}
