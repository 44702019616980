import axios from 'axios';
import { API_URL } from 'utils/http';

export function getAccountManagers({ page = 1, search }) {
  return axios.get(`${API_URL}/accountmanagers`, { params: { page, search } });
}

export function getNonAccountManagers({ page = 1, search }) {
  return axios.get(`${API_URL}/accountmanagers/eligible`, {
    params: { page, search },
  });
}

export function addAccountManager(uid, user) {
  return axios.put(`${API_URL}/accountmanagers/${uid}/add`, user);
}

export function removeAccountManager(uid, user) {
  return axios.put(`${API_URL}/accountmanagers/${uid}/remove`, user);
}
