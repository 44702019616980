export type CSize =
  | 'small'
  | 'medium'
  | 'larges1'
  | 'larges2'
  | 'larges3'
  | 'larges4'
  | 'larges5'
  | 'larges6'
  | 'larges7';

interface Tanent {
  id: number;
  name: string;
  data_size: number;
  requied_cpu: number;
  required_memory: number;
  default: boolean;
  createdAt: string;
  updatedAt: string;
}

interface User {
  id: string;
  fullName: string;
}

export interface Instance {
  id: string;
  name: string;
  status: string;
  sleeppable: boolean;
  image: string;
  consumption: string;
  csize: CSize;
  csizeDetails: string;
  dsize: string;
  availableDisk: string;
  cpu: string;
  memory: string;
  ipu: number;
  hostName: string | null;
  runningAt: string | null;
  tenantID: number;
  tenant: Tanent;
  pips: string;
  sqliEnabled: boolean;
  upgradable: boolean;
  availableUpgrades: string[];
  byBuildNumber: boolean;
  cloneable: boolean;
  remoteStorage: boolean;
  consumedData: string | null;
  releaseCount: string;
  releaseUrl: string;
  tenantUsers: number;
  maxExecutors: number;
  minExecutors: number;
  customBuild: string;
  createdAt: string;
  consumptionAgg: any;
  enableDataAgent: boolean;
  enableOnboarding: boolean;
  importedBlueprints?: ImportedBlueprint[];
  loaderNodes: number;
  analyticsNodes: number;
  externalCMC: boolean;
  subClusters: any[];
  toggleFeatures: any[];
  displayIdleTime: string | null;
  idleTime: number;
  clusterPolicy: {
    userPolicy: ('list' | 'create' | 'update' | 'delete')[];
    clusterPolicy: ('list' | 'create' | 'update' | 'delete')[];
    connectionPolicy: ('list' | 'create' | 'update' | 'delete')[];
    cliPolicy: ('list' | 'create' | 'update' | 'delete')[];
    blueprintPolicy: ('list' | 'create' | 'update' | 'delete')[];
    subClusterPolicy: ('list' | 'create' | 'update' | 'delete')[];
    managementPolicy: ('list' | 'create' | 'update' | 'delete')[];
    whitelistPolicy: ('list' | 'create' | 'update' | 'delete')[];
    authorizedRoles: ClusterUserRole[];
  };
  owner: User;
  authorizedRoles: ClusterUserRole[];
  region: string;
  zone: string;
  k8sClusterCode: string;
  platform: string;
}

export interface NotificationInfo {
  hasUnRead: boolean;
  notifications: Notification[];
}

export interface Notification {
  createdAt: string;
  event: string;
  eventType: string;
  id: string;
  instanceID: string;
  message: string;
  updatedAt: string;
  read: boolean;
}

export interface SubCluster {
  name: string;
  createdAt: string;
  id: number;
  instanceID: string;
  nodes: number;
  size: string;
  state: string;
  status: string;
  type: string;
  updatedAt: string;
  url: string;
  cpu: number;
  ipu: number;
}
export interface Regions {
  regions: Region[];
}

export interface Region {
  region: string;
  regionAlias: string;
  url: string;
  zone: string;
  cluster_code: string;
  isAvailable: boolean;
  platform: string;
  app_id: string;
}

export interface InstancesData {
  instances: InstanceInfo[];
  notifications?: NotificationInfo;
}

export interface InstanceInfo {
  instance: Instance;
  services: Service[];
  serverConfiguration: ServerConfiguration[];
}

export interface Service {
  name: string;
  status: string;
  url: string;
}

export interface ServerConfiguration {
  name: string;
  text: string;
  description: string;
}

export type ClusterStatus =
  | 'running'
  | 'starting-up'
  | 'stopping'
  | 'creating'
  | 'sleeping'
  | 'undefined';

export interface GetClusterData {
  userId: string;
  instanceName: string;
}

export interface DeleteClusterData {
  userId: string;
  instanceName: string;
}

export interface ImagesResult {
  images: {
    image: string;
    tags: {
      release: string;
    };
  }[];
}

export interface TenantsResult {
  tenants: {
    id: number;
    name: string;
    data_size: number;
    requied_cpu: number;
    required_memory: number;
    default: boolean;
    createdAt: string;
    updatedAt: string;
  }[];
}

interface Size {
  type: CSize;
  description: string;
  cpu: string;
  memory: string;
}

export interface SizesResult {
  sizes: {
    type: CSize;
    description: string;
    cpu: string;
    ipu: number;
    memory: string;
    isTrialAvailable: boolean;
  }[];
}

export interface ClusterData {
  name: string;
  csize?: CSize;
  dsize?: number;
  image?: string;
  tenantID?: number;
  installation?: string;
  alive?: boolean;
  sleeppable?: boolean;
  pips?: string;
  enableDataAgent?: boolean;
  idleTime?: number;
  region?: any;
  zone?: string;
  k8sClusterCode?: string;
  appId?: string;
  enableOnboarding?: boolean;
}

export interface Subscription {
  token: any;
  configurationName: string;
  servicePrincipalCredentials: string;
  subscriptionID: string;
  resourceGroup: string;
  appID: string;
  password: string;
  tenant: string;
  network: boolean;
  vnName: string;
  subnet1: string;
  subnet2: string;
  subnet3: string;
  subnet4: string;
  subnet5: string;
  region: string;
  userId: string;
  email: string;
  plan: string;
}

export type UpdateClusterData = Partial<ClusterData>;

interface EditInstanceData {
  pips: string;
  csize: CSize;
}

export interface ChangeSqliStateResult {
  message: string;
}

export interface ClusterUpgradeResult {
  message: string;
}

export interface BlueprintItem {
  blueprintCategoryID: number;
  businessFunction: string;
  createdAt: string;
  exportedFile: string;
  id: number;
  name: string;
  position: number;
  updatedAt: string;
  url: string;
  selected?: boolean;
  blueprintCategory: BlueprintCategory;
  type: string;
  tag: string;
  description: string;
}

interface Blueprint {
  blueprints: BlueprintItem[];
  createdAt: string;
  id: number;
  image: string;
  name: string;
  position: null;
  updatedAt: string;
}

export interface BlueprintsResult {
  blueprints: Blueprint[];
  blueprintFunctions: string[];
  blueprintTypes: string[];
}

export interface ImportBlueprintArg {
  names: string;
  tenant: string;
  instanceId: string;
  username: string;
  password: string;
}

export interface ExportBlueprintArg {
  names: string;
  tenant: string;
  instanceId: string;
  username: string;
  password: string;
  exportFolder: string;
}

interface ImportBlueprintValues {
  tenant: string;
  username: string;
  password: string;
  loadFromStaging: boolean;
}

export interface ImportedBlueprint {
  id: number;
  instanceID: string;
  blueprintID: number;
  tenant: string;
  imported: boolean;
  createdAt: string;
  updatedAt: string;
  blueprint: {
    id: number;
    name: string;
    url: string;
    position: number;
    exportedFile: string;
    businessFunction: string;
    blueprintCategoryID: number;
    blueprintCategory: BlueprintCategory;
    createdAt: string;
    updatedAt: string;
  };
}

export interface importedBlueprintsObj {
  [key: string]: ImportedBlueprint[];
}
export interface ImportState {
  state: 'loading' | 'success' | 'failed';
  importedBlueprints: importedBlueprintsObj;
  datasourceMessage?: string;
  failedBlueprints?: ImportedBlueprint['blueprint'][];
  message?: { title?: string; body?: string };
  error?: string;
}

interface BlueprintCategory {
  id: number;
  image: string;
  name: string;
  updatedAt: string;
  createdAt: string;
}

export type TableData = {
  day: Date;
  dayFormat: string;
  incorta: any;
  spark: any;
  total: any;
  isBeforeNow: boolean;
  hours: {
    hour: number;
    incorta: any;
    spark: any;
  }[];
}[];

export interface ConsumptionInfo {
  data: {
    clusters: {
      name: string;
      id: string;
      consumptionAgg: any;
    }[];
  };
}

export interface ValidBlueprintClusters {
  instances: Instance[];
}

export interface SupportToken {
  token: string;
  expiresAt: string;
}

export interface SubClusterCreateData {
  type: string;
  name: string;
  size: string;
  nodes: number;
}

export interface SubClusterUpdateData {
  subCluster: string;
  size: string;
  nodes: number;
}

export type SubClusterStates = 'disconnected' | 'loading' | 'running';

export interface SuspendTimeData {
  availableIdleTimes: {
    key: number;
    display: string;
  }[];
}

export interface Role {
  id: number;
  role: string;
}

export interface AuthorizedUserRole {
  authorizedRoles: Role[];
  createdAt: string;
  status: string;
  updatedAt: string;
  user: {
    id: string;
    email: string;
    confirmed: boolean;
    fullName: string;
    lastLoginAt: string;
  };
}

export interface AuthUserData {
  authorizedUserRoles: AuthorizedUserRole[];
}

export type ClusterUserRole =
  | 'owner'
  | 'accountManager'
  | 'accountAdmin'
  | 'developer';

export interface Whitelist {
  active: boolean;
  createdAt: string;
  description: string;
  id: number;
  instanceID: string;
  range: string;
  updatedAt: string;
}

export interface WhiteListData {
  whitelists: Whitelist[];
}

export interface AzureOffers {
  offers: AzureOffer[];
}

export interface AzureOffer {
  id: number;
  email: string;
  plan: string;
}

export enum InstancePlatform {
  GC = 'gc',
  AZURE = 'az',
  AZURE_SAAS = 'az-saas',
}
