import React, { useState, useCallback, useMemo } from 'react';
import { Typography, Button, Tooltip, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { getOcLabelsInfo } from 'services/oclabels';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import OverCommitmentModal from './OverCommitmentModal/OverCommitmentModal';
import useRequest from 'hooks/useRequest';
let { Title } = Typography;

function OverCommitment() {
  let [page, setPage] = useState(1);
  let [search, setSearch] = useState('');
  let [modalProps, setModalProps] = useState<any>(null);

  let searchParams = useMemo(
    () => ({
      page: page,
      search: search,
    }),
    [page, search],
  );

  let {
    result,
    isPending,
    makeRequest: refetchOcLabels,
  } = useRequest(
    useCallback(() => getOcLabelsInfo(searchParams), [searchParams]),
    { resolve: true },
  );

  let ocLabelsInfoResult = result?.data?.allOcLabels.rows;
  let pageSize = result?.data?.limit;
  let current = result?.data?.page;
  let total = result?.data?.total;

  let columns = [
    {
      title: 'Node Labels',
      dataIndex: 'label',
    },
    {
      title: 'Node Sizes',
      dataIndex: 'size',
    },
    {
      title: 'OverCommitment Value',
      dataIndex: 'overCommitmentValue',
    },
    {
      title: 'Action',
      render(_, oclabel) {
        return (
          <Space>
            <Tooltip title="Edit">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ isNew: false, oclabel });
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="ocnodelabels">
      <Title>Over Commitment</Title>

      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={ocLabelsInfoResult}
        loading={isPending}
        columns={columns}
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
      />

      {modalProps && (
        <OverCommitmentModal
          {...modalProps}
          refetchOcLabels={refetchOcLabels}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default OverCommitment;
