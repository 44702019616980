import { useState } from 'react';
import { Button, Form, Tooltip, Typography } from 'antd';
let { Title } = Typography;
import { PlusOutlined } from '@ant-design/icons';
import AddActionModal from './AddActionModal';

function LockActionsModal({
  clusterIds,
  tab,
  refetchClusters,
  setErrorModalProps,
  closeMain,
}) {
  let [showModal, setShowModal] = useState(false);

  return (
    <div title="Locks">
      <Form className="ActionLocks" layout="vertical" name="Lock Actions">
        <div className="tableTitle">
          <Title></Title>

          <Tooltip title="New">
            <Button
              type="primary"
              size="small"
              icon={<PlusOutlined />}
              onClick={() => {
                setShowModal(true);
              }}
            >
              Add Action
            </Button>
          </Tooltip>
        </div>
      </Form>
      {showModal && (
        <AddActionModal
          closeMain={closeMain}
          close={() => setShowModal(null)}
          clusterIds={clusterIds}
          tab={tab}
          refetchClusters={refetchClusters}
          setErrorModalProps={setErrorModalProps}
        />
      )}
    </div>
  );
}
export default LockActionsModal;
