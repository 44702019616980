import { useState, useContext } from 'react';
import _ from 'lodash';
import {
  Typography,
  Tag,
  Button,
  Tooltip,
  Space,
  Modal,
  Menu,
  Dropdown,
} from 'antd';
import { format } from 'date-fns';
import {
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloudOutlined,
  CheckOutlined,
  EyeOutlined,
  StopOutlined,
  CloudDownloadOutlined,
  RobotOutlined,
  DisconnectOutlined,
  DiffOutlined,
  SwapOutlined,
  UserSwitchOutlined,
  MoreOutlined,
  CloseOutlined,
  FileTextOutlined,
  FileSyncOutlined,
  AuditOutlined,
  RetweetOutlined,
  LineChartOutlined,
  ProfileOutlined,
  SecurityScanOutlined,
  LaptopOutlined,
  SwapRightOutlined,
  SwapLeftOutlined,
  FormatPainterOutlined,
  AccountBookOutlined,
  UnorderedListOutlined,
  ScheduleOutlined,
  LockOutlined,
} from '@ant-design/icons';
import ClusterModal from './ClusterModal/ClusterModal';
import UpgradeModal from './UpgradeModal/UpgradeModal';
import CloneModal from './CloneModal/CloneModal';
import MigrateModal from './MigrateModal/MigrateModal';
import LogsModal from './LogsModal/LogsModal';
import LdapModal from './LdapModal/LdapModal';
import TransferOwnershipModel from './TransferOwnershipModel/TransferOwnershipModel';
import ProgressLogs from './ProgressLogs/porgressLogs';
import FeatureBitsActionsModal from './FeatureBitsActionsModal/FeatureBitsActionsModal';
import Secrets from './Secrets/secrets';
import ClusterServiceStatus from './ClusterServiceStatus/clusterServiceStatus';
import CustomizeModal from './CustomizeModal/CustomizeModal';
import SlaCostModal from './SlaCostModal/SlaCostModal';
import UserRoles from './UserRoles/userRoles';
import ErrorModal from './ErrorModal/ErrorModal';
import SchedulerModal from './SchedulerModal/SchedulerModal';
import LockInterfaceModal from './LockActions/LockInterfaceModal';
import MassLockInterfaceModal from './LockActions/MassLock/LockInterfaceModal';
import SparkMasterModal from './SparkMaster/SparkMaster';
import ChidoriIcon from 'images/chidori-icon.svg';
import ChidoriBlackIcon from 'images/chidori-black-logo.svg';

import {
  deleteCluster,
  deleteMultiCluster,
  archiveCluster,
  blockClusterFromUrl,
  blockMultiClusterFromUrl,
  disconnectCluster,
  disconnectMultiCluster,
  connectCluster,
  connectMultiCluster,
  refreshScripts,
  refreshScriptsMultiCluster,
  collectMetrics,
  collectMetricsMultiCluster,
  updateClusterImage,
  unblockMultiClusterFromUrl,
} from 'services/cluster';
import './Clusters.less';
import { showError, showSucess } from 'utils/errors';
import AuditHistory from 'components/AuditHistory/AuditHistory';
import { multiclusterAction } from 'utils/helpers';
import { SessionContext } from 'auth/SessionProvider';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { useGetClusters } from 'hooks/queries';
import { seachableTablePageSizeOptions } from 'utils/constants';

const { Title } = Typography;

function Clusters() {
  const { user } = useContext(SessionContext);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [modalProps, setModalProps] = useState<any>(null);
  const [cloneModalProps, setCloneModalProps] = useState<any>(null);
  const [migrateModalProps, setMigrateModalProps] = useState<any>(null);
  const [logsModalProps, setLogsModalProps] = useState<any>(null);
  const [ldapModalProps, setLdapModalProps] = useState<any>(null);
  const [schedulerModalProps, setSchedulerModalProps] = useState<any>(null);
  const [transferOwnershipModelProps, setTransferOwnershipModelProps] =
    useState<any>(null);
  const [upgradeModalProps, setUpgradeModalProps] = useState<any>(null);
  const [featureBitActionsProps, setFeatureBitActionsProps] =
    useState<any>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [auditModalProps, setAuditModalProps] = useState<any>(null);
  const [progressModalProps, setProgressModalProps] = useState<any>(null);
  const [secretsModalProps, setSecretsModalProps] = useState<any>(null);
  const [clusterServiceModalProps, setclusterServiceModalProps] =
    useState<any>(null);
  const [customizeModalProps, setCustomizeModalProps] = useState<any>(null);
  const [slaCostModalProps, setSlaCostModalProps] = useState<any>(null);
  const [errorModalProps, setErrorModalProps] = useState<any>(null);
  const [userRolesModalProps, setUserRolesModalProps] = useState<any>(null);
  const [lockWindow, setLockWindow] = useState<any>(null);
  const [massLockWindow, setMassLockWindow] = useState<any>(null);
  const [sparkMasterModalProps, setSparkMasterModalProps] = useState<any>(null);

  const clearSelection = () => {
    setSelectedRowKeys([]);
  };

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const currentSelectedRows = id => {
    const selectedClusters = [...selectedRowKeys];
    _.remove(selectedClusters, function (c) {
      return c === id;
    });
    onSelectChange(selectedClusters);
  };

  const getTitle = (action, instance, checkRunning = false) => {
    if (instance.platform == 'az') {
      return `${action} is not supported in Azure clusters yet!`;
    } else if (checkRunning && instance.status !== 'running') {
      return 'Cluster Must be running.';
    } else return action;
  };

  const [limit, setLimit] = useState(seachableTablePageSizeOptions[0]);
  const {
    data: result,
    isLoading: isPending,
    refetch: refetchClusters,
  } = useGetClusters({ page, search, limit });

  const clusters = result?.data?.instances;
  const current = result?.data?.page;
  const total = result?.data?.total;

  const searchColumns = [
    'Name',
    'Owner Email',
    'Status',
    'Cost Center',
    'Organization',
    'Incorta Version',
    'Custom Build',
    'Type',
    'Created At',
    'Deleted',
    'Data Agent',
    'Cloned',
    'Migrated',
    'Alive',
    'Feature Bit',
    'Region',
    'Platform',
    'Resource Group',
    'Cost Type',
    'SLA Type',
  ];

  const platformsMap = {
    gc: 'Google Cloud Platform',
    az: 'Microsoft Azure',
    'az-saas': 'Microsoft Azure SaaS',
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '10%',
    },
    {
      title: 'Owner',
      render(_, instance) {
        return instance.user.email;
      },
    },

    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Cost Center',
      dataIndex: 'cost_center',
      width: '8%',
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      width: '10%',
    },
    {
      title: 'Region',
      width: '10%',
      render(_, instance) {
        return instance.region
          .replace('-', ' ')
          .replace(/(^\w|\s\w)/g, m => m.toUpperCase());
      },
    },
    {
      title: 'Platform',
      width: '10%',
      render(_, instance) {
        return platformsMap[instance.platform];
      },
    },
    {
      title: 'Zone',
      width: '10%',
      render(_, instance) {
        return instance.zone
          .replace('-', ' ')
          .replace(/(^\w|\s\w)/g, m => m.toUpperCase());
      },
    },
    {
      title: 'Version',
      render(_, instance) {
        return (
          <span>
            {instance.customBuildName || instance.customBuild || instance.image}
          </span>
        );
      },
    },
    {
      title: 'Type',
      render(_, instance) {
        switch (instance.csize) {
          case 'small':
            return 'Xsmall';
          case 'medium':
            return 'Small';
          case 'larges1':
            return 'Medium';
          case 'larges2':
            return 'Large';
          case 'larges3':
            return 'XLarge';
          case 'larges4':
            return '2XLarge';
          case 'larges5':
            return '4XLarge';
          case 'larges6':
            return '6XLarge';
          case 'larges7':
            return '10XLarge';
          default: {
            return undefined;
          }
        }
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: '10%',
      render(date) {
        return format(new Date(date), 'dd-MM-yyyy hh:mm a');
      },
    },
    {
      title: 'Last Startup',
      dataIndex: 'runningAt',
      width: '10%',
      render(date) {
        return date === null
          ? ''
          : format(new Date(date), 'dd-MM-yyyy hh:mm a');
      },
    },

    {
      title: 'More Information',
      render(_, instance) {
        return (
          <span>
            {!instance.vanillaVersion && <Tag color="magenta">Upgraded</Tag>}
            {instance.upgrade && (
              <Tag color="orange">
                {instance.upgrade === true
                  ? 'Pending Upgrade'
                  : instance.upgrade}
              </Tag>
            )}
            {instance.isProvision && <Tag color="volcano">Provision</Tag>}
            {instance.platform === 'gc' && <Tag color="cyan">Google</Tag>}
            {(instance.platform === 'az' ||
              instance.platform === 'az-saas') && <Tag color="cyan">Azure</Tag>}
            {instance.platform === 'aws' && <Tag color="cyan">Amazon</Tag>}
            {instance.filestoreMountPath && <Tag color="red">Filestore</Tag>}

            {instance.sqliEnabled && <Tag color="green">SQLI Enabled</Tag>}
            {instance.rgName && instance.rgName !== '0' && (
              <Tag color="purple">{instance.rgName}</Tag>
            )}
            {instance.cname && <Tag color="purple">CName</Tag>}
            {instance.hostName && <Tag color="purple">Host</Tag>}
            {instance.enableDataAgent && <Tag color="green">Data Agent</Tag>}

            {!instance.sleeppable && <Tag color="red">Always Alive</Tag>}
            {instance.isCloned && <Tag color="orange">Clone</Tag>}
            {instance.isMigrated && <Tag color="orange">Migrate</Tag>}
            {instance.isArchived && <Tag color="purple">Archived</Tag>}
            {instance.analyticsNodes > 1 && (
              <Tag color="volcano">{instance.analyticsNodes} Analytics</Tag>
            )}
            {instance.loaderNodes > 1 && (
              <Tag color="volcano">{instance.loaderNodes} Loaders</Tag>
            )}
            {instance.featureBits.map(featureBit => (
              <Tag
                color="green"
                key={featureBit.featureBits ? featureBit.featureBits.key : ''}
              >
                {featureBit.featureBits ? featureBit.featureBits.name : ''}
              </Tag>
            ))}
            {instance.rImage && <Tag color="magenta">R Image Enabled</Tag>}
            {instance.domain && <Tag color="geekblue">{instance.domain}</Tag>}
            {instance.isExcludedOc && <Tag color="volcano">OC Excluded</Tag>}
            {instance.isChidori && <Tag color="orange">Chidori</Tag>}
            {instance.onDemandLoader && instance.onDemandLoader.enabled && (
              <Tag color="blue">On-Demand Loader</Tag>
            )}
            {instance.deletedAt && (
              <Tooltip
                title={format(
                  new Date(instance.deletedAt),
                  'dd-MM-yyyy hh:mm a',
                )}
              >
                <Tag color="black">Deleted</Tag>
              </Tooltip>
            )}
          </span>
        );
      },
    },
    {
      title: 'Actions',
      render(_, instance) {
        return (
          <Space wrap>
            {
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu>
                    {!instance.deletedAt && (
                      <Menu.Item>
                        {
                          <Tooltip placement="left" title="Edit">
                            <Button
                              size="small"
                              type="primary"
                              onClick={() => {
                                setModalProps({
                                  isNew: false,
                                  cluster: instance,
                                });
                              }}
                            >
                              <EditOutlined />
                            </Button>
                          </Tooltip>
                        }
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        <Tooltip placement="left" title="Upgrade">
                          <Button
                            size="small"
                            onClick={() => {
                              setUpgradeModalProps({
                                isNew: false,
                                clusterIds: [instance.id],
                                clusterName: instance.name,
                              });
                            }}
                          >
                            <CloudDownloadOutlined />
                          </Button>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        <Tooltip placement="left" title="Clone">
                          <Button
                            size="small"
                            onClick={() => {
                              setCloneModalProps({
                                isNew: false,
                                cluster: instance,
                              });
                            }}
                          >
                            <DiffOutlined />
                          </Button>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        <Tooltip
                          placement="left"
                          title={getTitle('Migrate', instance)}
                        >
                          <Button
                            disabled={instance.platform == 'az'}
                            size="small"
                            onClick={() => {
                              setMigrateModalProps({
                                isNew: false,
                                cluster: instance,
                              });
                            }}
                          >
                            <SwapOutlined />
                          </Button>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        <Tooltip placement="left" title="View Service Status">
                          <Button
                            size="small"
                            onClick={() => {
                              setclusterServiceModalProps({ instance });
                            }}
                          >
                            <EyeOutlined />
                          </Button>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        <Tooltip
                          placement="left"
                          title={getTitle('Logs', instance)}
                        >
                          <Button
                            disabled={instance.platform == 'az'}
                            size="small"
                            onClick={() => {
                              setLogsModalProps({
                                cluster: instance,
                              });
                            }}
                          >
                            <FileTextOutlined />
                          </Button>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        <Tooltip
                          placement="left"
                          title={getTitle('Ldap/Self Sync', instance)}
                        >
                          <Button
                            disabled={instance.platform == 'az'}
                            size="small"
                            onClick={() => {
                              setLdapModalProps({
                                cluster: instance,
                              });
                            }}
                          >
                            <FileSyncOutlined />
                          </Button>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        <Tooltip placement="left" title="Transfer">
                          <Button
                            size="small"
                            onClick={() => {
                              setTransferOwnershipModelProps({
                                isNew: false,
                                clusterIds: [instance.id],
                              });
                            }}
                          >
                            <UserSwitchOutlined />
                          </Button>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        <Tooltip placement="left" title="URL Block">
                          <Button
                            size="small"
                            onClick={() => {
                              Modal.confirm({
                                title: (
                                  <>
                                    Are you sure you want to{' '}
                                    {instance.blockFromUrl
                                      ? 'unblock'
                                      : 'block'}
                                    {' starting this cluster '}
                                    <strong>{instance.name}</strong> through
                                    accessing the URL?
                                  </>
                                ),
                                async onOk() {
                                  await blockClusterFromUrl(
                                    instance.name,
                                    instance.blockFromUrl,
                                  ).catch(error => {
                                    showError(error);
                                  });
                                  await refetchClusters();
                                },
                              });
                            }}
                          >
                            {instance.blockFromUrl ? (
                              <CheckOutlined />
                            ) : (
                              <StopOutlined />
                            )}
                          </Button>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        {user.permission === 'super admin' && (
                          <Tooltip placement="left" title="Delete">
                            <Button
                              size="small"
                              onClick={() => {
                                Modal.confirm({
                                  title: (
                                    <>
                                      Are you sure you want to delete this
                                      cluster <strong>{instance.name}</strong>?
                                    </>
                                  ),
                                  async onOk() {
                                    await deleteCluster(instance.name).catch(
                                      error => {
                                        showError(error);
                                      },
                                    );
                                    await refetchClusters();
                                    currentSelectedRows(instance.id);
                                  },
                                });
                              }}
                            >
                              <DeleteOutlined />
                            </Button>
                          </Tooltip>
                        )}
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        <Tooltip
                          placement="left"
                          title={getTitle('Archive', instance)}
                        >
                          <Button
                            disabled={instance.platform == 'az'}
                            size="small"
                            onClick={() => {
                              Modal.confirm({
                                title: (
                                  <>
                                    Are you sure you want to archive this
                                    cluster <strong>{instance.name}</strong>?
                                  </>
                                ),
                                async onOk() {
                                  await archiveCluster(instance.name)
                                    .then(success => {
                                      showSucess(success);
                                    })
                                    .catch(error => {
                                      showError(error);
                                    });
                                  await refetchClusters();
                                  currentSelectedRows(instance.id);
                                },
                              });
                            }}
                          >
                            <SaveOutlined />
                          </Button>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        <Tooltip
                          placement="left"
                          title={
                            instance.status === 'running' ||
                            instance.status === 'starting'
                              ? 'Disconnect'
                              : 'Connect'
                          }
                        >
                          <Button
                            size="small"
                            disabled={instance.status === 'stopping'}
                            onClick={() => {
                              Modal.confirm({
                                title: (
                                  <>
                                    Are you sure you want to{' '}
                                    {instance.status === 'running' ||
                                    instance.status === 'starting'
                                      ? 'Disconnect'
                                      : 'Connect'}
                                    {' This Cluster '}{' '}
                                    <strong>{instance.name}</strong>?
                                  </>
                                ),
                                async onOk() {
                                  instance.status === 'running' ||
                                  instance.status === 'starting'
                                    ? await disconnectCluster(
                                        instance.name,
                                      ).catch(error => {
                                        showError(error);
                                      })
                                    : await connectCluster(instance.name).catch(
                                        error => {
                                          showError(error);
                                        },
                                      );
                                  await refetchClusters();
                                },
                              });
                            }}
                          >
                            {instance.status === 'running' ||
                            instance.status === 'starting' ? (
                              <DisconnectOutlined />
                            ) : (
                              <CloudOutlined />
                            )}
                          </Button>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        <Tooltip placement="left" title="Audit">
                          <Button
                            size="small"
                            onClick={() => setAuditModalProps({ instance })}
                          >
                            <AuditOutlined />
                          </Button>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        {instance.status === 'running' && (
                          <Tooltip
                            placement="left"
                            title={getTitle('Refresh Scripts', instance)}
                          >
                            <Button
                              disabled={instance.platform == 'az'}
                              size="small"
                              onClick={() => {
                                Modal.confirm({
                                  title: (
                                    <>
                                      Are you sure you want to refresh the cloud
                                      scripts for this cluster{' '}
                                      <strong>{instance.name}</strong>?
                                    </>
                                  ),
                                  async onOk() {
                                    await refreshScripts(instance.name).catch(
                                      error => {
                                        showError(error);
                                      },
                                    );
                                  },
                                });
                              }}
                            >
                              <RetweetOutlined />
                            </Button>
                          </Tooltip>
                        )}
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        {instance.status === 'running' && (
                          <Tooltip
                            placement="left"
                            title={getTitle('Collect Metrics', instance)}
                          >
                            <Button
                              disabled={instance.platform == 'az'}
                              size="small"
                              onClick={() => {
                                Modal.confirm({
                                  title: (
                                    <>
                                      Are you sure you want to collect the
                                      metrics for this cluster{' '}
                                      <strong>{instance.name}</strong>?
                                    </>
                                  ),
                                  async onOk() {
                                    await collectMetrics(instance.name).catch(
                                      error => {
                                        showError(error);
                                      },
                                    );
                                  },
                                });
                              }}
                            >
                              <LineChartOutlined />
                            </Button>
                          </Tooltip>
                        )}
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        {(user.permission === 'super admin' ||
                          user.permission === 'admin') && (
                          <Tooltip
                            placement="left"
                            title={getTitle('Progress Logs', instance)}
                          >
                            <Button
                              size="small"
                              disabled={instance.status !== 'running'}
                              onClick={() =>
                                setProgressModalProps({
                                  instance,
                                })
                              }
                            >
                              <ProfileOutlined />
                            </Button>
                          </Tooltip>
                        )}
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        {(user.permission === 'super admin' ||
                          user.permission === 'admin' ||
                          user.permission === 'support') && (
                          <Tooltip
                            placement="left"
                            title={
                              instance.status === 'running'
                                ? 'Feature Bits'
                                : 'Cluster Must Be Running'
                            }
                          >
                            <Button
                              size="small"
                              onClick={() =>
                                setFeatureBitActionsProps({
                                  clusterIds: [instance.id],
                                  instanceFeatureBits: instance.featureBits.map(
                                    featureBit => featureBit.featureBitID,
                                  ),
                                })
                              }
                              disabled={instance.status !== 'running'}
                            >
                              <LaptopOutlined />
                            </Button>
                          </Tooltip>
                        )}
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        <Tooltip placement="left" title="Secret">
                          <Button
                            size="small"
                            onClick={() => {
                              Modal.confirm({
                                title: (
                                  <>
                                    Are you sure you want to retrieve senstive
                                    data for this cluster{' '}
                                    <strong>{instance.name}</strong>?
                                  </>
                                ),
                                async onOk() {
                                  setSecretsModalProps({ instance });
                                },
                              });
                            }}
                          >
                            <SecurityScanOutlined />
                          </Button>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        <Tooltip
                          placement="left"
                          title={
                            instance.rImage
                              ? 'Disable R in Image'
                              : 'Enable R in Image'
                          }
                        >
                          <Button
                            size="small"
                            onClick={() => {
                              Modal.confirm({
                                title: (
                                  <>
                                    This action will lead to restarting the
                                    cluster <strong>{instance.name}</strong>?
                                  </>
                                ),
                                async onOk() {
                                  await updateClusterImage(instance.name).catch(
                                    error => {
                                      showError(error);
                                    },
                                  );

                                  await refetchClusters();
                                },
                              });
                            }}
                          >
                            {instance.rImage ? (
                              <SwapRightOutlined />
                            ) : (
                              <SwapLeftOutlined />
                            )}
                          </Button>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        <Tooltip
                          placement="left"
                          title={getTitle('Customize', instance, true)}
                        >
                          <Button
                            size="small"
                            onClick={() => setCustomizeModalProps({ instance })}
                            disabled={
                              instance.status !== 'running' ||
                              instance.platform == 'az'
                            }
                          >
                            <FormatPainterOutlined />
                          </Button>
                        </Tooltip>
                      </Menu.Item>
                    )}
                    <Menu.Item>
                      {
                        <Tooltip
                          placement="left"
                          title={getTitle('SLA and Cost Options', instance)}
                        >
                          <Button
                            disabled={instance.platform == 'az'}
                            size="small"
                            onClick={() => {
                              setSlaCostModalProps({
                                isNew: false,
                                cluster: instance,
                              });
                            }}
                          >
                            <AccountBookOutlined />
                          </Button>
                        </Tooltip>
                      }
                    </Menu.Item>
                    {!instance.deletedAt && (
                      <Menu.Item>
                        {
                          <Tooltip placement="left" title="List instance users">
                            <Button
                              size="small"
                              onClick={() => {
                                setUserRolesModalProps({
                                  isNew: false,
                                  cluster: instance,
                                });
                              }}
                            >
                              <UnorderedListOutlined />
                            </Button>
                          </Tooltip>
                        }
                      </Menu.Item>
                    )}
                    {!instance.deletedAt && (
                      <Menu.Item>
                        <Tooltip
                          placement="left"
                          title={getTitle('Scheduler', instance)}
                        >
                          <Button
                            disabled={instance.platform == 'az'}
                            size="small"
                            onClick={() => {
                              setSchedulerModalProps({
                                cluster: instance,
                                isNew: false,
                              });
                            }}
                          >
                            <ScheduleOutlined />
                          </Button>
                        </Tooltip>
                      </Menu.Item>
                    )}

                    {!instance.deletedAt &&
                      (user.permission === 'super admin' ||
                        user.permission === 'admin' ||
                        user.permission === 'support') && (
                        <Menu.Item>
                          <Tooltip placement="left" title="Lock Actions">
                            <Button
                              size="small"
                              onClick={() => {
                                setLockWindow({
                                  cluster: instance,
                                  user,
                                });
                              }}
                            >
                              <LockOutlined />
                            </Button>
                          </Tooltip>
                        </Menu.Item>
                      )}
                    {!instance.deletedAt &&
                      instance.status === 'running' &&
                      instance.platform === 'gc' &&
                      instance.instanceServices[0].loader_status ===
                        'running' && (
                        <Menu.Item>
                          <Tooltip placement="left" title={'Chidori'}>
                            <Button
                              size="small"
                              onClick={() => {
                                setSparkMasterModalProps({
                                  instance,
                                });
                              }}
                            >
                              <img
                                src={ChidoriBlackIcon}
                                alt=""
                                style={{
                                  display: 'flex',
                                  width: '12px',
                                }}
                              />
                            </Button>
                          </Tooltip>
                        </Menu.Item>
                      )}
                  </Menu>
                }
              >
                <MoreOutlined />
              </Dropdown>
            }
          </Space>
        );
      },
    },
  ];

  const searchPlaceholder =
    "Search By Cluster Name, Owner Email, Owner Name, Comapny Or 'Field Name'=Some Value. " +
    "Custom fields include 'deleted', 'installation', 'platform', 'alive'.";
  return (
    <div className="Clusters">
      <div className="cluster-header">
        <Title>Clusters</Title>
        {selectedRowKeys.length > 0 && (
          <Space>
            <span className="num-of-selected">
              {selectedRowKeys.length} selected
              <CloseOutlined onClick={() => clearSelection()} />
            </span>
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item>
                    <Tooltip placement="left" title="Upgrade">
                      <Button
                        size="small"
                        onClick={() => {
                          setUpgradeModalProps({
                            isNew: false,
                            clusterIds: selectedRowKeys,
                          });
                        }}
                      >
                        <CloudDownloadOutlined />
                      </Button>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item>
                    <Tooltip placement="left" title="transfer">
                      <Button
                        size="small"
                        onClick={() => {
                          setTransferOwnershipModelProps({
                            isNew: false,
                            clusterIds: selectedRowKeys,
                          });
                        }}
                      >
                        <UserSwitchOutlined />
                      </Button>
                    </Tooltip>
                  </Menu.Item>

                  <Menu.Item>
                    <Tooltip placement="left" title="Feature Bits">
                      <Button
                        size="small"
                        onClick={() => {
                          setFeatureBitActionsProps({
                            clusterIds: selectedRowKeys,
                          });
                        }}
                      >
                        <RobotOutlined />
                      </Button>
                    </Tooltip>
                  </Menu.Item>

                  <Menu.Item>
                    <Tooltip placement="left" title="URL Block">
                      <Button
                        size="small"
                        onClick={() => {
                          Modal.confirm({
                            title: (
                              <>
                                Are you sure you want to block starting this
                                cluster(s) through accessing the URL?
                              </>
                            ),
                            async onOk() {
                              await multiclusterAction(
                                blockMultiClusterFromUrl,
                                [selectedRowKeys],
                                refetchClusters,
                                setErrorModalProps,
                              );
                            },
                          });
                        }}
                      >
                        <CheckOutlined />
                      </Button>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item>
                    <Tooltip placement="left" title="URL Unblock">
                      <Button
                        size="small"
                        onClick={() => {
                          Modal.confirm({
                            title: (
                              <>
                                Are you sure you want to unblock starting this
                                cluster(s) through accessing the URL?
                              </>
                            ),
                            async onOk() {
                              await multiclusterAction(
                                unblockMultiClusterFromUrl,
                                [selectedRowKeys],
                                refetchClusters,
                                setErrorModalProps,
                              );
                            },
                          });
                        }}
                      >
                        <StopOutlined />
                      </Button>
                    </Tooltip>
                  </Menu.Item>
                  {user.permission === 'super admin' && (
                    <Menu.Item>
                      <Tooltip placement="left" title="Delete">
                        <Button
                          size="small"
                          onClick={() => {
                            Modal.confirm({
                              title: (
                                <>
                                  Are you sure you want to delete this
                                  cluster(s)?
                                </>
                              ),
                              async onOk() {
                                await multiclusterAction(
                                  deleteMultiCluster,
                                  [selectedRowKeys],
                                  refetchClusters,
                                  setErrorModalProps,
                                );
                              },
                            });
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Tooltip>
                    </Menu.Item>
                  )}

                  <Menu.Item>
                    <Tooltip placement="left" title="Connect">
                      <Button
                        size="small"
                        onClick={() => {
                          Modal.confirm({
                            title: (
                              <>
                                Are you sure you want to connect This cluster(s)
                                ?
                              </>
                            ),
                            async onOk() {
                              await multiclusterAction(
                                connectMultiCluster,
                                [selectedRowKeys],
                                refetchClusters,
                                setErrorModalProps,
                              );
                            },
                          });
                        }}
                      >
                        <CloudOutlined />
                      </Button>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item>
                    <Tooltip placement="left" title="Disconnect">
                      <Button
                        size="small"
                        onClick={() => {
                          Modal.confirm({
                            title: (
                              <>
                                Are you sure you want to disconnect This
                                cluster(s) ?
                              </>
                            ),
                            async onOk() {
                              await multiclusterAction(
                                disconnectMultiCluster,
                                [selectedRowKeys],
                                refetchClusters,
                                setErrorModalProps,
                              );
                            },
                          });
                        }}
                      >
                        <DisconnectOutlined />
                      </Button>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item>
                    <Tooltip placement="left" title="Refresh Scripts">
                      <Button
                        size="small"
                        onClick={() => {
                          Modal.confirm({
                            title: (
                              <>
                                Are you sure you want to refresh the cloud
                                scripts for this cluster(s)?
                              </>
                            ),
                            async onOk() {
                              await multiclusterAction(
                                refreshScriptsMultiCluster,
                                [selectedRowKeys],
                                refetchClusters,
                                setErrorModalProps,
                              );
                            },
                          });
                        }}
                      >
                        <RetweetOutlined />
                      </Button>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item>
                    <Tooltip placement="left" title="Collect Metrics">
                      <Button
                        size="small"
                        onClick={() => {
                          Modal.confirm({
                            title: (
                              <>
                                Are you sure you want to collect the metrics for
                                this cluster(s)?
                              </>
                            ),
                            async onOk() {
                              await multiclusterAction(
                                collectMetricsMultiCluster,
                                [selectedRowKeys],
                                refetchClusters,
                                setErrorModalProps,
                              );
                            },
                          });
                        }}
                      >
                        <LineChartOutlined />
                      </Button>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item>
                    <Tooltip placement="left" title="Lock Actions">
                      <Button
                        size="small"
                        onClick={() => {
                          setMassLockWindow({
                            clusterIds: selectedRowKeys,
                            user,
                          });
                        }}
                      >
                        <LockOutlined />
                      </Button>
                    </Tooltip>
                  </Menu.Item>
                </Menu>
              }
            >
              <MoreOutlined />
            </Dropdown>
          </Space>
        )}
      </div>
      <SearchableTable
        type="Clusters"
        searchPlaceholder={searchPlaceholder}
        pagination={{
          pageSize: limit,
          current,
          total,
          showSizeChanger: true,
          pageSizeOptions: seachableTablePageSizeOptions,
          onShowSizeChange: (_, size) => {
            setLimit(size);
          },
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={clusters}
        loading={isPending}
        columns={columns}
        advancedSearchColumns={searchColumns}
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        rowSelection={rowSelection}
      />

      {modalProps && (
        <ClusterModal
          {...modalProps}
          refetchClusters={refetchClusters}
          close={() => setModalProps(null)}
        />
      )}
      {clusterServiceModalProps && (
        <Modal
          title="Cluster Info"
          visible={true}
          onCancel={() => setclusterServiceModalProps(null)}
          footer={null}
          width={'50%'}
        >
          <ClusterServiceStatus {...clusterServiceModalProps} />
        </Modal>
      )}
      {upgradeModalProps && (
        <UpgradeModal
          {...upgradeModalProps}
          refetchClusters={refetchClusters}
          close={() => setUpgradeModalProps(null)}
          setErrorModalProps={setErrorModalProps}
        />
      )}
      {cloneModalProps && (
        <CloneModal
          {...cloneModalProps}
          refetchClusters={refetchClusters}
          close={() => setCloneModalProps(null)}
        />
      )}
      {migrateModalProps && (
        <MigrateModal
          {...migrateModalProps}
          refetchClusters={refetchClusters}
          close={() => setMigrateModalProps(null)}
        />
      )}
      {logsModalProps && (
        <LogsModal
          {...logsModalProps}
          refetchClusters={refetchClusters}
          close={() => setLogsModalProps(null)}
        />
      )}
      {ldapModalProps && (
        <LdapModal
          {...ldapModalProps}
          refetchClusters={refetchClusters}
          close={() => setLdapModalProps(null)}
        />
      )}
      {transferOwnershipModelProps && (
        <TransferOwnershipModel
          {...transferOwnershipModelProps}
          refetchClusters={refetchClusters}
          close={() => setTransferOwnershipModelProps(null)}
          setErrorModalProps={setErrorModalProps}
        />
      )}
      {auditModalProps && (
        <Modal
          title="Audit History"
          visible={true}
          onCancel={() => setAuditModalProps(null)}
          footer={null}
          width={'90%'}
        >
          <AuditHistory {...auditModalProps} />
        </Modal>
      )}
      {progressModalProps && (
        <Modal
          title="Progress Logs"
          visible={true}
          onCancel={() => setProgressModalProps(null)}
          footer={null}
          width={'50%'}
        >
          <ProgressLogs
            {...progressModalProps}
            close={() => setProgressModalProps(null)}
          />
        </Modal>
      )}
      {featureBitActionsProps && (
        <FeatureBitsActionsModal
          {...featureBitActionsProps}
          refetchClusters={refetchClusters}
          cancel={() => setFeatureBitActionsProps(null)}
          close={() => setFeatureBitActionsProps(null)}
          setErrorModalProps={setErrorModalProps}
        />
      )}
      {secretsModalProps && (
        <Modal
          title="Cluster Secrets"
          visible={true}
          onCancel={() => setSecretsModalProps(null)}
          footer={null}
          width={'50%'}
        >
          <Secrets {...secretsModalProps} />
        </Modal>
      )}
      {userRolesModalProps && (
        <Modal
          title="List of users"
          visible={true}
          onCancel={() => setUserRolesModalProps(null)}
          footer={null}
          width={'60%'}
        >
          <UserRoles {...userRolesModalProps} user={user} />
        </Modal>
      )}
      {customizeModalProps && (
        <CustomizeModal
          {...customizeModalProps}
          refetchClusters={refetchClusters}
          cancel={() => setCustomizeModalProps(null)}
          close={() => setCustomizeModalProps(null)}
        />
      )}
      {slaCostModalProps && (
        <SlaCostModal
          {...slaCostModalProps}
          refetchClusters={refetchClusters}
          close={() => setSlaCostModalProps(null)}
        />
      )}
      {errorModalProps && (
        <ErrorModal
          {...errorModalProps}
          close={() => {
            refetchClusters();
            setErrorModalProps(null);
          }}
        />
      )}
      {schedulerModalProps && (
        <SchedulerModal
          {...schedulerModalProps}
          refetchClusters={refetchClusters}
          close={() => setSchedulerModalProps(null)}
        />
      )}

      {lockWindow && (
        <LockInterfaceModal
          {...lockWindow}
          close={() => setLockWindow(null)}
          cancel={() => setLockWindow(null)}
          setErrorModalProps={setErrorModalProps}
          refetchClusters={refetchClusters}
        />
      )}
      {massLockWindow && (
        <MassLockInterfaceModal
          {...massLockWindow}
          close={() => setMassLockWindow(null)}
          cancel={() => setMassLockWindow(null)}
          setErrorModalProps={setErrorModalProps}
          refetchClusters={refetchClusters}
        />
      )}
      {sparkMasterModalProps && (
        <Modal
          className="chidori-modal__wrapper"
          title={
            <div className="chidori-modal__title">
              <img src={ChidoriIcon} alt="Chidori" /> Chidori
            </div>
          }
          open={true}
          onCancel={() => setSparkMasterModalProps(null)}
          footer={null}
          width={'50%'}
        >
          <SparkMasterModal
            {...sparkMasterModalProps}
            close={() => setSparkMasterModalProps(null)}
          />
        </Modal>
      )}
    </div>
  );
}

export default Clusters;
