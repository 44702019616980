import axios from 'axios';
import { API_URL } from 'utils/http';

export function getBlueprintFunctions({ page, search }: any = {}) {
  return axios.get(`${API_URL}/blueprintfunctions`, {
    params: { page, search },
  });
}

export function updateBlueprintFunction(id, blueprintFunction) {
  return axios.put(
    `${API_URL}/blueprintfunctions/${id}/update`,
    blueprintFunction,
  );
}

export function createBlueprintFunction(blueprintFunction) {
  return axios.post(`${API_URL}/blueprintfunctions/create`, blueprintFunction);
}

export function deleteBlueprintFunction(id) {
  return axios.delete(`${API_URL}/blueprintfunctions/${id}/delete`);
}
