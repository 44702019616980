import axios from 'axios';
import { API_URL } from 'utils/http';

export function fetchPlatformActions() {
  return axios.get(`${API_URL}/platform`);
}

export function updatePlatformAction(payload) {
  return axios.post(`${API_URL}/platform`, payload);
}
