import React, { useCallback } from 'react';
import { Modal, Form, Switch, Button, Space } from 'antd';
import useRequest from 'hooks/useRequest';
import { updateToggleFeature } from 'services/toggleFeature';

function ToggleFeatureModal({ toggleFeature, refetchToggleFeatures, close }) {
  let { toggle, published, id } = toggleFeature ?? {};

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async toggleFeature => {
        await updateToggleFeature(id, toggleFeature);
        await refetchToggleFeatures();
      },
      [id, refetchToggleFeatures],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(toggleFeature) {
    await makeRequest(toggleFeature);
    close();
  }

  return (
    <Modal visible title="Edit Toggle Feature" footer={null} onCancel={close}>
      <Form
        initialValues={{
          toggle,
          published,
        }}
        layout="vertical"
        name="Toggle Feature"
        onFinish={handleFinish}
      >
        <Form.Item
          name="toggle"
          label="Released for Selected Users"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        {toggleFeature.key != 'azureSupport' && (
          <Form.Item
            name="published"
            label="Released for Everyone"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        )}
        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            Update
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default ToggleFeatureModal;
