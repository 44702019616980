import { useQuery, useMutation } from 'react-query';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import { getAzurePlans, getToggleFeatures } from 'services/toggleFeature';
import { addUserToFeature, getUsers, me } from 'services/user';
import { displaySuccessMessage, displayErrorMessage } from 'utils';
import { getErrorMessage } from 'utils/errors';
import { toggleFeatures } from 'types/toggleFeatures';
import { getClusters } from 'services/cluster';
import {
  createConfiguration,
  getConfigurations,
  updateConfiguration,
} from 'services/configuration';
import { queryClient } from 'index';
import {
  fetchInstanceChidoriStatus,
  getChidoriSizes,
  modifiedChidoriSize,
  updateChidoriSizes,
} from 'services/sparkmaster';

export function useGetUserData({
  isAuthenticated,
}: {
  isAuthenticated: boolean;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.USER.GET_USER_DATA],
    () => me(),
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  );
  return queryOptions;
}

export function useGetAzurePlans({
  userId,
  userRole,
}: {
  userId?: string;
  userRole?: string;
}) {
  return useQuery(
    [QueryServerKeys.SYSTEM_MANAGEMENT.AZURE_PLANS, userId],
    async () => {
      const result = await getAzurePlans({ userId, userRole });
      return result?.data?.body;
    },
    {
      enabled: !!userId,
    },
  );
}

export function useAddUserToAzureSupportFeature() {
  const queryOptions = useMutation(
    async ({
      uid,
      tackle_plan,
      userRole,
    }: {
      uid: string;
      tackle_plan: string;
      userRole: string;
    }) => {
      const result = await addUserToFeature({
        feature: toggleFeatures.azureSupport,
        uid,
        tackle_plan,
        userRole,
      });
      return result;
    },
    {
      mutationKey: [QueryServerKeys.SYSTEM_MANAGEMENT.ADD_USER_TO_FEATURE],
      onSuccess(result) {
        queryClient.invalidateQueries(QueryServerKeys.USER.GET_USERS);
        displaySuccessMessage(result.data?.message);
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

function getPaginatedData({
  key,
  page,
  search,
  limit,
  service,
  options,
}: {
  key: string;
  page: number;
  search: string;
  service: Function;
  limit?: number;
  options?: Object;
}) {
  const queryKey = [key, page, search, ...[limit]];
  const queryFn = async () => {
    return await service({ page, search, limit });
  };
  const queryOptions = {
    staleTime: 1 * 1000, // 1 sec
    ...options,
  };
  return { queryKey, queryFn, queryOptions };
}

export function useGetUsers({
  page = 1,
  search = '',
  limit,
}: {
  page?: number;
  search?: string;
  limit?: number;
}) {
  const { queryKey, queryFn, queryOptions } = getPaginatedData({
    key: QueryServerKeys.USER.GET_USERS,
    page,
    search,
    limit,
    service: getUsers,
  });
  return useQuery(queryKey, queryFn, queryOptions);
}

export function useGetChidori({ instanceid = '' }: { instanceid: string }) {
  return useQuery(
    [QueryServerKeys.CHIDORI.CHIDORI, instanceid],
    () => fetchInstanceChidoriStatus(instanceid),
    {
      staleTime: 0,
      cacheTime: 0,
    },
  );
}

export function useGetChidoriSizes({
  page = 1,
  search = '',
}: {
  page?: number;
  search?: string;
}) {
  const { queryKey, queryFn, queryOptions } = getPaginatedData({
    key: QueryServerKeys.CHIDORI.GET_SIZES,
    page,
    search,
    service: getChidoriSizes,
  });
  return useQuery(queryKey, queryFn, queryOptions);
}

export function useUpdateChidoriSizes() {
  const queryOptions = useMutation(
    async ({ chidoriSizes }: { chidoriSizes: modifiedChidoriSize[] }) => {
      const result = await updateChidoriSizes({
        chidoriSizes,
      });
      return result;
    },
    {
      mutationKey: [QueryServerKeys.CHIDORI.UPDATE_SIZES],
      onSuccess(result) {
        queryClient.invalidateQueries(QueryServerKeys.CHIDORI.GET_SIZES);
        displaySuccessMessage(result.data?.message);
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

export function useGetClusters({
  page = 1,
  search = '',
  limit = 10,
}: {
  page?: number;
  search?: string;
  limit?: number;
}) {
  const { queryKey, queryFn, queryOptions } = getPaginatedData({
    key: QueryServerKeys.CLUSTER_MANAGEMENT.CLUSTERS,
    page,
    search,
    limit,
    service: getClusters,
  });
  return useQuery(queryKey, queryFn, queryOptions);
}

export function useGetConfigurations({
  page = 1,
  search = '',
}: {
  page?: number;
  search?: string;
}) {
  const { queryKey, queryFn, queryOptions } = getPaginatedData({
    key: QueryServerKeys.SYSTEM_MANAGEMENT.CONFIGURATIONS,
    page,
    search,
    service: getConfigurations,
  });
  return useQuery(queryKey, queryFn, queryOptions);
}

export function useGetToggleFeatures({
  page = 1,
  search = '',
}: {
  page?: number;
  search?: string;
}) {
  const { queryKey, queryFn, queryOptions } = getPaginatedData({
    key: QueryServerKeys.SYSTEM_MANAGEMENT.TOGGLE_FEATURES,
    page,
    search,
    service: getToggleFeatures,
  });
  return useQuery(queryKey, queryFn, queryOptions);
}

export function useCreateConfiguration() {
  const queryOptions = useMutation(
    async ({ configuration }: { configuration: any }) => {
      const result = await createConfiguration(configuration);
      return result;
    },
    {
      mutationKey: [QueryServerKeys.SYSTEM_MANAGEMENT.CREATE_CONFIGURATIONS],
      onSuccess(result) {
        queryClient.invalidateQueries(
          QueryServerKeys.SYSTEM_MANAGEMENT.CONFIGURATIONS,
        );
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
  return queryOptions;
}

export function useUpdateConfiguration() {
  const queryOptions = useMutation(
    async ({ id, configuration }: { id: any; configuration: any }) => {
      const result = await updateConfiguration(id, configuration);
      return result;
    },
    {
      mutationKey: [QueryServerKeys.SYSTEM_MANAGEMENT.UPDATE_CONFIGURATIONS],
      onSuccess(result) {
        queryClient.invalidateQueries(
          QueryServerKeys.SYSTEM_MANAGEMENT.CONFIGURATIONS,
        );
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
  return queryOptions;
}
