import './PriorityField.less';

import React from 'react';
import { Select, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { getOperators } from 'services/contextManager';

let { Option } = Select;

function PriorityField({ name, label }) {
  let { result, isPending } = useRequest(getOperators, {
    resolve: true,
    handleError: true,
    useCache: true,
  });

  let priorities = Array.from(
    { length: result?.data?.operators?.length ?? 1 },
    (_, i) => i + 1,
  );

  if (result?.data?.operators?.length > 1) {
    priorities.unshift(-1);
  }

  return (
    <Form.Item
      className="Priority"
      name={name}
      label={label}
      rules={[{ required: true }]}
    >
      <FieldSelect loading={isPending && !priorities} priorities={priorities} />
    </Form.Item>
  );
}

function FieldSelect({ value, onChange, loading, priorities }: any) {
  return (
    <Select
      value={priorities ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="Priority__select-dropdown"
    >
      {priorities?.map(priorities => (
        <Option key={priorities} value={priorities}>
          {priorities}
        </Option>
      ))}
    </Select>
  );
}

export default PriorityField;
