import './SupportedVersionField.less';

import React, { useCallback } from 'react';
import { Select, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { getAvailableVersions } from 'services/blueprint';

let { Option } = Select;

function SupportedVersionField({ name, label, blueprint }) {
  let { result, isPending } = useRequest(
    useCallback(() => getAvailableVersions({ blueprint }), [blueprint]),
    { resolve: true },
  );

  let incortaVersions = result?.data?.incortaVersions;

  return (
    <Form.Item
      className="IncortaVersion"
      name={name}
      label={label}
      rules={[{ required: true }]}
    >
      <FieldSelect
        loading={isPending && !incortaVersions}
        incortaVersions={incortaVersions}
      />
    </Form.Item>
  );
}

function FieldSelect({
  value,
  onChange,
  loading,
  incortaVersions,
}: {
  value?: string;
  onChange?: () => {};
  loading: boolean;
  incortaVersions: any[];
}) {
  return (
    <Select
      mode="multiple"
      value={incortaVersions ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="IncortaVersion__select-dropdown"
    >
      {incortaVersions?.map(incortaVersion => (
        <Option key={incortaVersion} value={incortaVersion}>
          {incortaVersion}
        </Option>
      ))}
    </Select>
  );
}

export default SupportedVersionField;
