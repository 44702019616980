import React, { useState, useCallback } from 'react';
import { Typography, Tag, Button, Tooltip, Space } from 'antd';
import { useHistory } from 'react-router-dom';

import { formatISO } from 'date-fns';
import { EditOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import ToggleFeatureModal from './ToggleFeatureModal/ToggleFeatureModal';
import useRequest from 'hooks/useRequest';
import { getToggleFeatures } from 'services/toggleFeature';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { useGetToggleFeatures } from 'hooks/queries';

let { Title } = Typography;

function ToggleFeatures() {
  let [page, setPage] = useState(1);
  let [search] = useState('');
  let [modalProps, setModalProps] = useState<any>(null);
  let history = useHistory();

  const {
    data: result,
    isLoading: isPending,
    refetch: refetchToggleFeatures,
  } = useGetToggleFeatures({});

  let toggleFeatures = result?.data?.toggleFeatures;
  let pageSize = result?.data?.limit;
  let current = result?.data?.page;
  let total = result?.data?.total;

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Released for Selected Users',
      render(_, toggleFeature) {
        return (
          <span>
            {toggleFeature.toggle ? (
              <Tag color="green">Enabled</Tag>
            ) : (
              <Tag color="red">Disabled</Tag>
            )}
          </span>
        );
      },
    },
    {
      title: 'Released for Everyone',
      render(_, toggleFeature) {
        return (
          <span>
            {toggleFeature.published ? (
              <Tag color="green">Enabled</Tag>
            ) : (
              <Tag color="red">Disabled</Tag>
            )}
          </span>
        );
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },

    {
      title: 'Action',
      render(_, toggleFeature) {
        return (
          <Space>
            <Tooltip title="Edit">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ toggleFeature });
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>

            <Tooltip
              title={
                toggleFeature?.published
                  ? `${toggleFeature.name} is already released for everyone`
                  : `Users`
              }
            >
              <Button
                disabled={toggleFeature?.published}
                size="small"
                onClick={() => {
                  history.push(
                    `/togglefeatures/${toggleFeature.key}?value=${toggleFeature.name}`,
                  );
                }}
              >
                <UsergroupAddOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="ToggleFeatures">
      <Title>Toggle Features</Title>
      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={toggleFeatures}
        loading={isPending}
        columns={columns}
      />
      {modalProps && (
        <ToggleFeatureModal
          {...modalProps}
          refetchToggleFeatures={refetchToggleFeatures}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default ToggleFeatures;
