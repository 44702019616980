import axios from 'axios';
import { API_URL } from 'utils/http';

export function getGlobalNotifications({ page, search }) {
  return axios.get(`${API_URL}/notification/getGlobalNotifications`, {
    params: { page, search },
  });
}

export function addGlobalNotification(event, message, usersIDs, method) {
  return axios.post(`${API_URL}/notification/addGlobalNotification`, {
    params: { event, message, usersIDs, method },
  });
}
