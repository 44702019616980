import axios from 'axios';
import cookie from 'js-cookie';
import { format } from 'date-fns';
import { API_URL } from 'utils/http';
import { toggleFeatures } from 'types/toggleFeatures';

let COOKIE_TIME = 1000 * 60 * 60 * 24 * 365;

export function me() {
  return axios.get(`${API_URL}/me`);
}

export async function login(email, password) {
  let res = await axios.post(`${API_URL}/signin`, { email, password });

  // auth
  let token = res.data.token;
  if (token) {
    cookie.set('token', token, { expires: COOKIE_TIME });
  }

  let meRes = await me();

  return meRes;
}

export async function loginWithAccessToken(access_token) {
  let res = await axios.post(`${API_URL}/signin`, { access_token });

  // auth
  let token = res.data.token;
  if (token) {
    cookie.set('token', token, { expires: COOKIE_TIME });
  }

  let meRes = await me();

  return meRes;
}

export function getUsersEmails(email) {
  return axios.get(`${API_URL}/users/filter?email=${email}`);
}

export function getUsers({ page = 1, search, limit = 10 }) {
  return axios.get(`${API_URL}/users`, { params: { page, search, limit } });
}

export function getUser({ param }) {
  return axios.get(`${API_URL}/users/${param}`);
}

export function getUserBlueprints({ param }) {
  return axios.get(`${API_URL}/users/${param}/blueprints`);
}

export function updateUser(uid, user) {
  return axios.put(`${API_URL}/users/${uid}/update`, user);
}

export function getRoles() {
  return axios.get(`${API_URL}/roles`);
}

export function getUserConfig() {
  return axios.get(`${API_URL}/configurations/permissions`);
}

export function blockUser(uid, block) {
  return axios.put(`${API_URL}/users/${uid}/block`, { block });
}

export function getStats({ userId }) {
  return axios.get(`${API_URL}/users/${userId}/statshistorytotal`);
}

export function getStatsForADate({ userId, date }) {
  date = format(new Date(date), 'MM/yyyy');
  return axios.get(`${API_URL}/users/${userId}/statshistory`, {
    params: { date },
  });
}

export function resetUserPassword(uid) {
  return axios.post(`${API_URL}/users/${uid}/reset`);
}

export async function addUserToFeature({
  feature,
  uid,
  tackle_plan,
  userRole,
}: {
  feature: string;
  uid: string;
  tackle_plan?: string;
  userRole?: string;
}) {
  if (feature !== toggleFeatures.azureSupport) {
    return axios.put(`${API_URL}/users/${uid}/assignuser`, { feature });
  } else {
    return axios.put(`${API_URL}/users/${uid}/assignuser`, {
      feature,
      source: 'toggle_feature',
      tackle_plan,
      userRole,
    });
  }
}

export function removeUserFromFeature(uid, feature) {
  return axios.put(`${API_URL}/users/${uid}/removeuser`, { feature });
}

export function deleteUser(uid) {
  return axios.delete(`${API_URL}/users/${uid}`);
}

export function createAccount(account) {
  return axios.post(`${API_URL}/users/createaccount`, account);
}

export function getProvisionedUsers({ page = 1, search }) {
  return axios.get(`${API_URL}/users/provisionedusers`, {
    params: { page, search },
  });
}
