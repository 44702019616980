import React, { useCallback } from 'react';
import { Modal, Form, Input, Button, Space } from 'antd';
import { cloneCluster } from 'services/cluster';
import useRequest from 'hooks/useRequest';

function CloneModal({ isNew, cluster, refetchClusters, close }) {
  let { name } = cluster ?? {};

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async cluster => {
        await cloneCluster(name, cluster);
        await refetchClusters();
      },
      [name, refetchClusters],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(cluster) {
    await makeRequest(cluster);
    close();
  }

  return (
    <Modal visible title={'Clone Cluster'} footer={null} onCancel={close}>
      <Form
        initialValues={{
          name,
        }}
        layout="vertical"
        name="cluster"
        onFinish={handleFinish}
      >
        <Form.Item name="name" label="Clone Name" rules={[{ required: true }]}>
          <Input placeholder="New Cluster Name" />
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {'Clone'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default CloneModal;
