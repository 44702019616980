import { useState } from 'react';
import { Modal, Tabs } from 'antd';
import LockActionsModal from './LockActionsModal';
const { TabPane } = Tabs;

function LockInterfaceModal({
  cluster,
  cancel,
  close,
  user,
  setErrorModalProps,
  refetchClusters,
}) {
  const [activeTab, setActiveTab] = useState('1');

  function onChange(activeKey) {
    setActiveTab(activeKey);
  }

  return (
    <Modal
      title="Locks"
      visible={true}
      footer={null}
      width={'40%'}
      onCancel={cancel}
    >
      <Tabs
        activeKey={activeTab}
        onChange={onChange}
        destroyInactiveTabPane={true}
      >
        <TabPane tab="Self Actions" key="1">
          {activeTab === '1' && (
            <LockActionsModal
              cluster={cluster}
              tab="selfActions"
              setErrorModalProps={setErrorModalProps}
              refetchClusters={refetchClusters}
            />
          )}
        </TabPane>

        <TabPane
          tab="Admin Portal Actions"
          key="2"
          forceRender={activeTab === '2'}
          disabled={!(user?.permission === 'super admin')}
        >
          {activeTab === '2' && (
            <LockActionsModal
              cluster={cluster}
              tab="controlPanelActions"
              setErrorModalProps={setErrorModalProps}
              refetchClusters={refetchClusters}
            />
          )}
        </TabPane>
      </Tabs>
    </Modal>
  );
}
export default LockInterfaceModal;
