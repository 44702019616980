import { useState } from 'react';
import { Typography, Tag, Button, Tooltip, Space } from 'antd';
import { formatISO } from 'date-fns';
import {
  MinusSquareOutlined,
  PlusSquareOutlined,
  BackwardOutlined,
} from '@ant-design/icons';

import { useParams, useHistory } from 'react-router-dom';
import UserToggleFeatureModal from './UserToggleFeatureModal/UserToggleFeatureModal';
import { toggleFeatures } from 'types/toggleFeatures';
import { addUserToFeature, removeUserFromFeature } from 'services/user';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { useGetUsers } from 'hooks/queries';

let { Title } = Typography;

function ToggleFeature() {
  let { feature }: any = useParams();
  let history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const featureName: any = queryParams.get('value');
  const [isUserToggleFeatureModalOpen, setIsUserToggleFeatureModalOpen] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  let [page, setPage] = useState(1);
  let [search, setSearch] = useState('');

  const {
    data: result,
    isLoading: isPending,
    refetch: refetchUsers,
  } = useGetUsers({ page, search });

  async function handleAddingUser(uid: string, feature: string) {
    if (feature === toggleFeatures.azureSupport) {
      setIsUserToggleFeatureModalOpen(true);
    } else {
      await addUserToFeature({ feature, uid });
    }
  }

  let users = result?.data?.users;
  let pageSize = result?.data?.limit;
  let current = result?.data?.page;
  let total = result?.data?.total;

  let columns = [
    {
      title: 'Full name',
      dataIndex: 'fullName',
      render(_, user) {
        return (
          <span>
            {user.fullName}
            &emsp;
            {user.role.name ? (
              <Tag color="blue">
                {user.role.name.charAt(0).toUpperCase() +
                  user.role.name.slice(1)}
              </Tag>
            ) : null}
            {user.permission === 'accountant' && (
              <Tag color="green">Accountant</Tag>
            )}
            {user.permission === 'support' && <Tag color="red">Support</Tag>}
            {user.permission === 'pre-sales' && (
              <Tag color="green">Pre-Sales</Tag>
            )}
            {user.permission === 'professional services' && (
              <Tag color="green">Professional Services</Tag>
            )}
            {user.permission === 'admin' && <Tag color="green">Admin</Tag>}
            {user.permission === 'super admin' && (
              <Tag color="cyan">Super admin</Tag>
            )}
            {user.confirmed ? <Tag color="purple">Confirmed</Tag> : null}
            {user.trialExpired ? <Tag color="red">Expired</Tag> : null}
            {user.blocked ? <Tag color="red">Blocked</Tag> : null}
          </span>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Company',
      dataIndex: 'company',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },
    {
      title: 'Action',
      render(_, user) {
        const userHasFeatureEnabled = user.toggleFeatures.find(
          ({ key }) => key === feature,
        );

        return (
          <Space>
            {
              <Tooltip title={userHasFeatureEnabled ? 'Remove' : 'Add'}>
                <Button
                  type={userHasFeatureEnabled ? 'default' : 'primary'}
                  size="small"
                  onClick={async () => {
                    setSelectedUser(user);
                    userHasFeatureEnabled
                      ? await removeUserFromFeature(user.uuid, feature)
                      : await handleAddingUser(user.uuid, feature);
                    await refetchUsers();
                  }}
                >
                  {userHasFeatureEnabled ? (
                    <MinusSquareOutlined />
                  ) : (
                    <PlusSquareOutlined />
                  )}
                </Button>
              </Tooltip>
            }
          </Space>
        );
      },
    },
  ];

  return (
    <div className="ToggleFeature">
      <div className="tableTitle">
        <Title>
          {featureName.charAt(0).toUpperCase() + featureName.slice(1)} Toggle
          Feature: Eligible Users
        </Title>
        <Tooltip title="Toggle Features">
          <Button
            type="primary"
            icon={<BackwardOutlined />}
            onClick={() => {
              history.push(`/togglefeatures`);
            }}
          >
            Toggle Features
          </Button>
        </Tooltip>
      </div>

      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={users}
        loading={isPending}
        columns={columns}
        searchPlaceholder={
          "Search By Email, Name, Comapny Or 'Field Name'=Some Value."
        }
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
      />

      {isUserToggleFeatureModalOpen && selectedUser && (
        <UserToggleFeatureModal
          selectedUser={selectedUser}
          feature={feature}
          setIsUserToggleFeatureModalOpen={setIsUserToggleFeatureModalOpen}
        />
      )}
    </div>
  );
}

export default ToggleFeature;
