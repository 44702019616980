import { useCallback, useState } from 'react';
import { Modal, Form, Button, Space, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CriteriaModal from '../CriteriaField/CriteriaModal';
import { getOperators } from 'services/contextManager';
import useRequest from 'hooks/useRequest';

function ContextManagerCriteriaModal({ operator, close, refetchOperators }) {
  let url = operator?.url;
  const [showAdvancedSearchModal, setShowAdvancedSearchModal] =
    useState<any>(null);
  let {
    result,
    isPending,
    makeRequest: refetchOperator,
  } = useRequest(
    useCallback(() => getOperators(url), [url]),
    { resolve: true },
  );

  operator = result?.data?.operators[0];
  const formatDataSource = filteringCriteriaList => {
    let dataSource = [];
    if (filteringCriteriaList) {
      for (let criteria of filteringCriteriaList) {
        let filter;
        if (criteria.includes('like')) filter = 'like';
        else filter = 'exact';
        let criteriaSplitted = criteria.split(filter);
        dataSource.push({
          criteria: criteriaSplitted[0].replace(/'/g, ''),
          filter: filter,
          value: criteriaSplitted[1],
        });
      }
      return dataSource;
    }
  };
  let filteringCriteriaList = operator?.filteringCriteria?.split('&&');
  let dataSource =
    operator?.filteringCriteria == ''
      ? []
      : formatDataSource(filteringCriteriaList);

  const columns = [
    {
      title: 'Filter By',
      dataIndex: 'criteria',
      key: 'criteria',
      width: '30%',
    },
    {
      title: 'Filter',
      dataIndex: 'filter',
      key: 'filter',
      width: '20%',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      width: '60%',
    },
  ];
  let filterColumns = [
    'Full Name',
    'Email',
    'Company',
    'Cost Center',
    'Organization',
  ];

  return (
    <Modal
      visible
      title={'Filter Operator Based On User Criteria'}
      footer={null}
      onCancel={close}
    >
      <Form layout="vertical" name="Operator">
        <Table dataSource={dataSource} loading={isPending} columns={columns} />
        <Form.Item>
          <Button
            type="primary"
            size="small"
            icon={<PlusOutlined />}
            onClick={() => {
              setShowAdvancedSearchModal(true);
            }}
          >
            Edit Criteria
          </Button>
        </Form.Item>

        <Space>
          <Button onClick={close}>Close</Button>
        </Space>
      </Form>
      {showAdvancedSearchModal && (
        <CriteriaModal
          {...showAdvancedSearchModal}
          close={() => setShowAdvancedSearchModal(null)}
          columnsList={filterColumns}
          dataSource={dataSource}
          refetchOperator={refetchOperator}
          refetchOperators={refetchOperators}
          operator={operator}
        />
      )}
    </Modal>
  );
}

export default ContextManagerCriteriaModal;
