import { useCallback, useContext } from 'react';
import './PlatformAction.less';
import { Typography, Tag, Modal } from 'antd';
import { SessionContext } from 'auth/SessionProvider';
import { fetchPlatformActions, updatePlatformAction } from 'services/platform';
import useRequest from 'hooks/useRequest';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';

let { Title } = Typography;

function PlatformAction() {
  const { user } = useContext(SessionContext);

  let {
    result,
    isPending,
    makeRequest: refetchActions,
  } = useRequest(useCallback(fetchPlatformActions, [user]), {
    resolve: true,
    handleError: true,
  });

  let { makeRequest: updateAction } = useRequest(
    // eslint-disable-next-line
    useCallback(payload => updatePlatformAction(payload), [user]),
    {
      handleError: true,
    },
  );

  const handleTagClick = async function (action, platform, status) {
    const payload = {
      action: action.name,
      platform: platform.name,
      status,
    };

    Modal.confirm({
      title: (
        <>
          Are you sure you want to {status ? 'Enable' : 'Disable'}{' '}
          <strong>{action.displayName}</strong> for{' '}
          <strong>{platform.displayName}</strong> clusters?
        </>
      ),
      async onOk() {
        try {
          await updateAction(payload);
        } finally {
          await refetchActions();
        }
      },
    });
  };

  const platforms = result?.data?.platforms;
  const actions = result?.data?.actions;

  let columns = [
    {
      title: 'Action',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '10%',
    },
  ];

  const platformCloumns = platforms
    ? platforms.map(platform => {
        return {
          title: platform.displayName,
          render(_, action) {
            return action.platforms
              .split(',')
              .find((value: any) => value === platform.name) ? (
              <div
                className="platform-action-tag__wrapper"
                onClick={() => handleTagClick(action, platform, false)}
              >
                <Tag color="green">Enabled</Tag>
              </div>
            ) : (
              <div
                className="platform-action-tag__wrapper"
                onClick={() => handleTagClick(action, platform, true)}
              >
                <Tag color="red">Disabled</Tag>
              </div>
            );
          },
        };
      })
    : [];

  columns = columns.concat(platformCloumns);

  return (
    <div className="PlatformAction">
      <div className="tableTitle">
        <Title>Platform Actions</Title>
      </div>

      <SearchableTable
        pagination={false}
        dataSource={actions}
        loading={isPending}
        columns={columns}
      />
    </div>
  );
}

export default PlatformAction;
