import React, { useState, useCallback } from 'react';
import { Typography, Button, Tooltip, Space, Modal } from 'antd';
import { formatISO } from 'date-fns';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import DataAgentModal from './DataAgentModal/DataAgentModal';
import useRequest from 'hooks/useRequest';
import { getDataAgents, deleteDataAgent } from 'services/dataagent';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';

let { Title } = Typography;

function DataAgents() {
  let [page, setPage] = useState(1);
  let [search] = useState('');
  let [modalProps, setModalProps] = useState<any>(null);

  let {
    result,
    isPending,
    makeRequest: refetchDataAgents,
  } = useRequest(
    useCallback(() => getDataAgents({ page, search }), [page, search]),
    { resolve: true },
  );

  let dataagents = result?.data?.dataagents;
  let pageSize = result?.data?.limit;
  let current = result?.data?.page;
  let total = result?.data?.total;

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Version',
      dataIndex: 'version',
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      render(_, dataagent) {
        return `${dataagent.user.fullName} (${dataagent.user.email})`;
      },
    },
    {
      title: 'Last Modifier',
      dataIndex: 'modifier',
      render(_, dataagent) {
        return `${dataagent.lastModified.fullName} (${dataagent.lastModified.email})`;
      },
    },
    {
      title: 'Incorta Versions',
      dataIndex: 'incortaVersion',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },
    {
      title: 'Action',
      render(_, dataagent) {
        return (
          <Space>
            <Tooltip title="Edit">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ isNew: false, dataagent });
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>

            <Tooltip title={'Delete'}>
              <Button
                size="small"
                onClick={() => {
                  Modal.confirm({
                    title: (
                      <>
                        Are you sure you want to {'delete'}{' '}
                        <strong>{dataagent.name}</strong>?
                      </>
                    ),
                    async onOk() {
                      await deleteDataAgent(dataagent.id);
                      await refetchDataAgents();
                    },
                  });
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="DataAgents">
      <div className="tableTitle">
        <Title>Data Agent</Title>
        <Tooltip title="Create">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setModalProps({ isNew: true });
            }}
          >
            Add Data Agent
          </Button>
        </Tooltip>
      </div>
      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={dataagents}
        loading={isPending}
        columns={columns}
      />

      {modalProps && (
        <DataAgentModal
          {...modalProps}
          refetchDataAgents={refetchDataAgents}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default DataAgents;
