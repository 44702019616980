import './SupportDashboard.less';

import { Button, Input, message, Typography } from 'antd';
import React, { useCallback, useContext } from 'react';
import { CopyOutlined, KeyOutlined } from '@ant-design/icons';

import copy from 'clipboard-copy';
import { getClusterKey } from 'services/support';
import useRequest from 'hooks/useRequest';
import { SessionContext } from 'auth/SessionProvider';
const { Paragraph } = Typography;

const { Search } = Input;

function SupportDashboard() {
  const { user } = useContext(SessionContext);

  let { makeRequest, isPending, result } = useRequest(
    useCallback(
      token => {
        return getClusterKey(user.uuid, token);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [user.id],
    ),
    { handleError: true },
  );

  function handleEnter(e) {
    let token = e.target.value;
    makeRequest(token);
  }
  function handleSearch(token) {
    makeRequest(token);
  }

  let clusterKey = result?.data?.key;

  return (
    <div className="SupportDashboard">
      <Search
        enterButton={<KeyOutlined />}
        onPressEnter={handleEnter}
        onSearch={handleSearch}
        placeholder="Enter user cluster token"
        loading={isPending}
      />

      {clusterKey && (
        <div className="SupportDashboard__key-wrapper">
          <span>
            <strong>KEY:</strong> {clusterKey}
          </span>
          <Button
            size="small"
            onClick={() => {
              copy(clusterKey);
              message.success('Copied');
            }}
          >
            <CopyOutlined />
          </Button>
        </div>
      )}

      <Paragraph>
        Don't have the CLI tool yet? Click{' '}
        <a
          href="https://incorta.atlassian.net/wiki/spaces/INC/pages/1740898463/IC+CLI+Versions"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
      </Paragraph>
    </div>
  );
}

export default SupportDashboard;
