import React, { useState, useCallback } from 'react';
import { Typography, Tag, Button, Tooltip, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import CliModal from './CliModal/CliModal';
import useRequest from 'hooks/useRequest';
import { getCliCommands } from 'services/clicommands';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';

let { Title } = Typography;

function Cli() {
  let [page, setPage] = useState(1);
  let [search, setSearch] = useState('');
  let [modalProps, setModalProps] = useState<any>(null);

  let {
    result,
    isPending,
    makeRequest: refetchCliCommands,
  } = useRequest(
    useCallback(() => getCliCommands({ page, search }), [page, search]),
    { resolve: true },
  );

  let cliCommands = result?.data?.cliCommands;
  let pageSize = result?.data?.limit;
  let current = result?.data?.page;
  let total = result?.data?.total;

  let columns = [
    {
      title: 'Command Category',
      dataIndex: 'category',
    },
    {
      title: 'Command',
      dataIndex: 'command',
    },
    {
      title: 'Least Allowed Permission',
      dataIndex: 'permission',
      render(_, cliCommand) {
        return (
          <span>
            &emsp;
            {cliCommand.permission === 'pre-sales' && (
              <Tag color="green">Pre-Sales</Tag>
            )}
            {cliCommand.permission === 'professional services' && (
              <Tag color="green">Professional Services</Tag>
            )}
            {cliCommand.permission === 'accountant' && (
              <Tag color="green">Accountant</Tag>
            )}
            {cliCommand.permission === 'support' && (
              <Tag color="red">Support</Tag>
            )}
            {cliCommand.permission === 'admin' && (
              <Tag color="green">Admin</Tag>
            )}
            {cliCommand.permission === 'super admin' && (
              <Tag color="cyan">Super admin</Tag>
            )}
          </span>
        );
      },
    },
    {
      title: 'Action',
      render(_, clicommand) {
        return (
          <Space>
            <Tooltip title="Edit">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ isNew: false, clicommand });
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="clicommands">
      <Title>CLI Commands</Title>

      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={cliCommands}
        loading={isPending}
        columns={columns}
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
      />

      {modalProps && (
        <CliModal
          {...modalProps}
          refetchCliCommands={refetchCliCommands}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default Cli;
