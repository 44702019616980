import React, { useState, useCallback, useContext } from 'react';
import { Typography, Tag, Button, Tooltip, Space } from 'antd';
import { format } from 'date-fns';
import { UserOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import UserModal from '../Users/UserModal/UserModal';
import useRequest from 'hooks/useRequest';
import { getProvisionedUsers } from 'services/user';
import { SessionContext } from 'auth/SessionProvider';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';

let { Title } = Typography;

function ProvisionUsers() {
  let history = useHistory();

  let [page, setPage] = useState(1);
  let [search, setSearch] = useState('');
  let [modalProps, setModalProps] = useState<any>(null);
  let {
    result,
    isPending,
    makeRequest: refetchUsers,
  } = useRequest(
    useCallback(() => getProvisionedUsers({ page, search }), [page, search]),
    { resolve: true },
  );

  let users = result?.data?.users;
  let pageSize = result?.data?.limit;
  let current = result?.data?.page;
  let total = result?.data?.total;
  const { user } = useContext(SessionContext);

  let columns = [
    {
      title: 'Full name',
      dataIndex: 'fullName',
      render(_, user) {
        return (
          <span>
            {user.fullName}
            &emsp;
            {user.role.displayName && (
              <Tag color="blue">{user.role.displayName}</Tag>
            )}
            {user.invitedAccount ? <Tag color="yellow">Invited</Tag> : null}
            {user.permission === 'accountant' && (
              <Tag color="green">Accountant</Tag>
            )}
            {user.permission === 'support' && <Tag color="red">Support</Tag>}
            {user.permission === 'pre-sales' && (
              <Tag color="green">Pre-Sales</Tag>
            )}
            {user.permission === 'professional services' && (
              <Tag color="green">Professional Services</Tag>
            )}
            {user.permission === 'admin' && <Tag color="green">Admin</Tag>}
            {user.permission === 'super admin' && (
              <Tag color="cyan">Super admin</Tag>
            )}
            {user.confirmed ? <Tag color="purple">Confirmed</Tag> : null}
            {user.isPartner ? <Tag color="volcano">Partner</Tag> : null}
            {user.trialExpired ? <Tag color="red">Expired</Tag> : null}
            {user.blocked ? <Tag color="red">Blocked</Tag> : null}
            {user.isProvision ? <Tag color="volcano">Provision</Tag> : null}
          </span>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return format(new Date(date), 'dd-MM-yyyy hh:mm a');
      },
    },
    {
      title: 'Last Login At',
      dataIndex: 'lastLoginAt',
      render(date) {
        return format(new Date(date), 'dd-MM-yyyy hh:mm a');
      },
    },
    {
      title: 'User Info',
      render(_, user) {
        return (
          <Space>
            <Tooltip title="User Info">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  history.push(`/accounting/provisionuserdetails/${user.id}`);
                }}
              >
                <UserOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  let searchColumns = [
    'Full Name',
    'Email',
    'Company',
    'Cost Center',
    'Organization',
    'Created At',
    'Role',
    'Permission',
  ];

  return (
    <div className="Users">
      {(user.permission === 'admin' ||
        user.permission === 'super admin' ||
        user.permission === 'support') && (
        <div className="ProvisionCustomer">
          <div className="tableTitle">
            <Title></Title>

            <Tooltip title="New">
              <Link to={`/provision-customer/new`}>
                <Button type="primary" icon={<PlusOutlined />}>
                  Provision New User
                </Button>
              </Link>
            </Tooltip>
          </div>
        </div>
      )}

      <Title>Provision Users</Title>

      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        dataSource={users}
        loading={isPending}
        columns={columns}
        advancedSearchColumns={searchColumns}
        type="Clusters"
        searchPlaceholder={
          "Search By Email, Name, Comapny Or 'Field Name'=Some Value."
        }
      />
      {modalProps && (
        <UserModal
          {...modalProps}
          refetchUsers={refetchUsers}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default ProvisionUsers;
