import React, { useState, useCallback } from 'react';
import { Typography, Button, Tooltip, Space, Modal, Tag } from 'antd';
import { formatISO } from 'date-fns';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import SearchableTable from '../ui/SearchableTable/SearchableTable';
import BlueprintModal from './BlueprintModal/BlueprintModal';
import useRequest from 'hooks/useRequest';
import { getBlueprints, deleteBlueprint } from 'services/blueprint';

let { Title } = Typography;

function Blueprints() {
  let [page, setPage] = useState(1);
  let [search, setSearch] = useState('');
  let [modalProps, setModalProps] = useState<any>(null);

  let {
    result,
    isPending,
    makeRequest: refetchBlueprints,
  } = useRequest(
    useCallback(() => getBlueprints({ page, search }), [page, search]),
    { resolve: true },
  );

  let blueprints = result?.data?.blueprints;
  let pageSize = result?.data?.limit;
  let current = result?.data?.page;
  let total = result?.data?.total;

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Category',
      dataIndex: 'blueprintCategory',
      render(blueprintCategory) {
        return (
          <>
            <img src={blueprintCategory.image} width={50} alt="logo" />{' '}
            {blueprintCategory.name}
          </>
        );
      },
    },
    {
      title: 'Business Function',
      dataIndex: 'businessFunction',
    },
    {
      title: 'Incorta Versions',
      dataIndex: 'incortaVersion',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },
    {
      title: 'More Information',
      render(_, blueprint) {
        return (
          <span>
            {
              <Tag color={blueprint.enable ? 'green' : 'red'}>
                {blueprint.enable ? 'Enabled' : 'Disabled'}
              </Tag>
            }

            {
              <Tag color={blueprint.visibleForTrial ? 'green' : 'red'}>
                {blueprint.visibleForTrial
                  ? 'Visible For Trial'
                  : 'Not Visible For Trial'}
              </Tag>
            }
          </span>
        );
      },
    },
    {
      title: 'Action',
      render(_, blueprint) {
        return (
          <Space>
            <Tooltip title="Edit">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ isNew: false, blueprint });
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>

            <Tooltip title={'Delete'}>
              <Button
                size="small"
                onClick={() => {
                  Modal.confirm({
                    title: (
                      <>
                        Are you sure you want to {'delete'}{' '}
                        <strong>{blueprint.name}</strong>?
                      </>
                    ),
                    async onOk() {
                      await deleteBlueprint(blueprint.id);
                      await refetchBlueprints();
                    },
                  });
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="Blueprints">
      <div className="tableTitle">
        <Title>Blueprints</Title>

        <Tooltip title="Create">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setModalProps({ isNew: true });
            }}
          >
            Add Blueprint
          </Button>
        </Tooltip>
      </div>

      <SearchableTable
        searchPlaceholder="Search Blueprint or 'Field Name'=Some Value."
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={blueprints}
        loading={isPending}
        columns={columns}
      />

      {modalProps && (
        <BlueprintModal
          {...modalProps}
          refetchBlueprints={refetchBlueprints}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default Blueprints;
