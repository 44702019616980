import { useCallback, useState } from 'react';
import { Modal, Form, Input, Button, Space, Checkbox } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import useRequest from 'hooks/useRequest';
import { addGlobalNotification } from 'services/notifications';

function NotificationModal({
  refetchGlobalNotifications,
  close,
  selectedUsers,
}) {
  const { TextArea } = Input;
  const [email, setEmail] = useState(false);
  const [alert, setAlert] = useState(false);

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async (notification, method) => {
        await addGlobalNotification(
          notification.event,
          notification.message,
          selectedUsers,
          method,
        );
        await refetchGlobalNotifications();
      },
      [refetchGlobalNotifications, selectedUsers],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(notification) {
    let method = { email: email, alert: alert };
    await makeRequest(notification, method);
    close();
  }

  return (
    <Modal
      visible
      title={'Send New Global Notification'}
      footer={null}
      onCancel={close}
    >
      <div>
        {selectedUsers.length === 0 && (
          <div className="notificationInfo">
            <p>
              <InfoCircleOutlined />
              Looks like you haven't selected any users, this will send a
              notification to all active users!
            </p>
          </div>
        )}
        <Form layout="vertical" name="Notification" onFinish={handleFinish}>
          <Form.Item name="event" label="Event" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="method"
            label="Send via"
            rules={[
              {
                message: 'At least one method is required!',
                validator: (_, value) => {
                  if (!email && !alert) {
                    return Promise.reject('At least one method is required!');
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Checkbox
              onChange={e => {
                setEmail(e.target.checked);
              }}
            >
              Email
            </Checkbox>
            <Checkbox
              onChange={e => {
                setAlert(e.target.checked);
              }}
            >
              Alert
            </Checkbox>
          </Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={isPending}>
              {'Send'}
            </Button>
            <Button onClick={close}>Cancel</Button>
          </Space>
        </Form>
      </div>
    </Modal>
  );
}

export default NotificationModal;
