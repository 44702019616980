import React, { useState } from 'react';
import { Table, Input, Button, SpinProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  TablePaginationConfig,
  TableRowSelection,
} from 'antd/lib/table/interface';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import AdvancedSearchModal from '../AdvancedSearch/AdvancedSearch';

import './SearchableTable.less';
import usePersistSearchValue from './hooks/usePersistSearchValue';

const { Search } = Input;

interface ISearchableTable {
  size?: SizeType;
  pagination: false | TablePaginationConfig;
  dataSource: readonly any[];
  loading?: boolean | SpinProps;
  columns: ColumnsType<any>;
  scroll?: { x: true; y: string };
  rowSelection?: TableRowSelection<any>;
  onSearch?: (value) => void;
  searchPlaceholder?: string;
  advancedSearchColumns?: string[];
  type?: string;
  title?: Function | string;
  footer?: Function | string;
  showHeader?: boolean;
  shouldPersistSearch?: boolean;
}

function SearchableTable(props: ISearchableTable) {
  const [showAdvancedSearchModal, setShowAdvancedSearchModal] =
    useState<any>(null);
  const [searchValue, setSearchValue] = usePersistSearchValue(
    props.onSearch || (() => ({})),
    props.shouldPersistSearch,
  );

  return (
    <div className="searchable-table-container">
      <div className="search-field">
        {props.onSearch && (
          <Search
            placeholder={props.searchPlaceholder}
            onSearch={props.onSearch || (() => {})}
            enterButton
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
        )}

        {(props?.advancedSearchColumns?.length ?? 0) > 0 && (
          <>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                setShowAdvancedSearchModal({
                  isNew: false,
                });
              }}
            >
              Advanced Search
            </Button>
            <Button
              size="small"
              type="default"
              onClick={() => {
                props.onSearch?.('');
                setSearchValue('');
              }}
            >
              Clear Search
            </Button>
          </>
        )}
      </div>

      {showAdvancedSearchModal &&
        (props?.advancedSearchColumns?.length ?? 0) > 0 && (
          <AdvancedSearchModal
            {...showAdvancedSearchModal}
            close={() => setShowAdvancedSearchModal(null)}
            columnsList={props.advancedSearchColumns}
            type={props.type}
            setSearchValue={value => {
              props.onSearch?.(value);
              setSearchValue(value);
            }}
          />
        )}

      <Table
        rowKey="id"
        className="antd-table-customized-scroll"
        bordered
        pagination={props.pagination}
        dataSource={props.dataSource}
        loading={props.loading}
        columns={props.columns}
        showHeader={props.showHeader}
        rowSelection={props.rowSelection}
        size={props.size}
      />
    </div>
  );
}

export default SearchableTable;
