import React, { useEffect, useState } from 'react';
import './ChidoriSizes.less';
import { Button, Slider, Typography } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useGetChidoriSizes, useUpdateChidoriSizes } from 'hooks/queries';
import { chidoriSizeItem } from 'services/sparkmaster';
import { Spinner } from 'components/Spinner/Spinner';

function ChidoriSizes() {
  let { Title } = Typography;

  const [dataSource, setDataSource] = useState<chidoriSizeItem[]>([]);
  const { data, isLoading, isError } = useGetChidoriSizes({});
  useEffect(() => {
    setDataSource(data);
  }, [data]);

  const { mutateAsync: mutateUpdateChidoriSizes } = useUpdateChidoriSizes();

  const marks = data?.reduce((acc, item) => {
    acc[item.sizeValue] = (
      <div className="mark__wrapper">
        <label className="mark__mark-name">{item.size}</label>
        <label className="mark__mark-original">Original</label>
      </div>
    );
    return acc;
  }, {});

  function handleSliderChange(value, index) {
    if (index === 0) {
      if (value < dataSource[1].sizeValue) {
        let newDataSource = structuredClone(dataSource);
        newDataSource.shift();
        newDataSource = [
          { ...dataSource[0], sizeValue: value },
          ...newDataSource,
        ];
        setDataSource(newDataSource);
      }
    } else if (index === dataSource.length - 1) {
      if (value > dataSource[index - 1].sizeValue) {
        let newDataSource = structuredClone(dataSource);
        newDataSource.pop();
        newDataSource = [
          ...newDataSource,
          { ...dataSource[index], sizeValue: value },
        ];
        setDataSource(newDataSource);
      }
    } else {
      if (
        value > dataSource[index - 1].sizeValue &&
        value < dataSource[index + 1].sizeValue
      ) {
        const modifiedItem = { ...dataSource[index], sizeValue: value };
        const firstPart = dataSource.slice(0, index);
        const secondPart = dataSource.slice(index + 1);
        const newDataSource = [...firstPart, modifiedItem, ...secondPart];
        setDataSource(newDataSource);
      }
    }
  }

  async function handleModifyChidoriSizes() {
    const modifiedChidoriSizes = [];

    data.forEach((originalItem: chidoriSizeItem, i: number) => {
      if (originalItem.sizeValue !== dataSource[i].sizeValue) {
        modifiedChidoriSizes.push({
          name: originalItem.size,
          memory: dataSource[i].sizeValue,
        });
      }
    });
    if (modifiedChidoriSizes.length) {
      try {
        const res = await mutateUpdateChidoriSizes({
          chidoriSizes: modifiedChidoriSizes,
        });
        console.log('first');
      } catch (error) {
        //
      }
    }
  }

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="chidori-sizes__wrapper">
      <article className="tableTitle">
        <Title>Chidori Sizes</Title>
      </article>
      {dataSource?.map((d, index) => {
        return (
          <section key={d.size} className="chidori-sizes__item">
            <section className="chidori-sizes__item-info">
              <h3>{d.size}</h3>
              <label>{d.sizeValue} GB</label>
            </section>
            <Slider
              value={d.sizeValue}
              min={2}
              marks={marks}
              max={100}
              onChange={value => handleSliderChange(value, index)}
            />
            <br />
          </section>
        );
      })}
      <Button
        type="primary"
        icon={<SaveOutlined />}
        onClick={handleModifyChidoriSizes}
      >
        {'Save Sizes'}
      </Button>
    </div>
  );
}

export default ChidoriSizes;
