import React from 'react';
import { Typography, Button, Tooltip, message } from 'antd';
import { routeToCloudFunction } from 'services/costAwareness';

let { Title } = Typography;

function CostAwareness() {
  async function mapToCloudFunction(cloudFunction) {
    routeToCloudFunction(cloudFunction).then(result => {
      message.info(result.data.message);
    });
  }
  return (
    <div className="UserInfo">
      <div className="tableTitle">
        <Title>Cost Awareness</Title>
      </div>

      <div className="UserDetails">
        <div>
          <Tooltip title="Update Users table">
            <Button
              type="primary"
              onClick={() => {
                mapToCloudFunction(true);
              }}
            >
              {'Update Users table'}{' '}
            </Button>{' '}
          </Tooltip>

          <Tooltip title="Notify Directors">
            <Button
              type="default"
              onClick={() => {
                mapToCloudFunction(false);
              }}
            >
              {'Notify Directors'}{' '}
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default CostAwareness;
