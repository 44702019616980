import './CategoryField.less';

import React from 'react';
import { Select, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { getBlueprintCategories } from 'services/blueprintCategory';

let { Option } = Select;

function CategoryField({ name, label }) {
  let { result, isPending } = useRequest(getBlueprintCategories, {
    resolve: true,
    handleError: true,
  });

  let blueprintCategories = result?.data?.blueprintCategories;

  return (
    <Form.Item
      className="CategoryField"
      name={name}
      label={label}
      rules={[{ required: true }]}
    >
      <BlueprintCategoriesSelect
        loading={isPending && !blueprintCategories}
        blueprintCategories={blueprintCategories}
      />
    </Form.Item>
  );
}

function BlueprintCategoriesSelect({
  value,
  onChange,
  loading,
  blueprintCategories,
}: any) {
  return (
    <Select
      value={blueprintCategories ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="SizeField__select-dropdown"
    >
      {blueprintCategories?.map(blueprintCategory => (
        <Option key={blueprintCategory.id} value={blueprintCategory.image}>
          <img src={blueprintCategory.image} width="30" alt="logo" />{' '}
        </Option>
      ))}
    </Select>
  );
}

export default CategoryField;
