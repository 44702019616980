import { useCallback, useState } from 'react';
import {
  Button,
  Space,
  Modal,
  Form,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
let { Title } = Typography;
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import AddActionModal from './AddActionModal';
import useRequest from 'hooks/useRequest';
import {
  removeInstanceLock,
  getCluster,
  removeInstanceSelfLock,
} from 'services/cluster';

function LockActionsModal({
  cluster,
  tab,
  refetchClusters,
  setErrorModalProps,
}) {
  const formatDataSource = lockedActionsList => {
    let dataSource = [];
    if (lockedActionsList) {
      if (tab == 'selfActions')
        lockedActionsList = lockedActionsList.filter(lockedAction =>
          lockedAction.startsWith('Self'),
        );
      else
        lockedActionsList = lockedActionsList.filter(
          lockedAction => !lockedAction.startsWith('Self'),
        );

      for (let action of lockedActionsList) {
        dataSource.push({ action: action });
      }
      return dataSource;
    }
  };

  let name = cluster.name;
  let { result, makeRequest: refetchCluster } = useRequest(
    useCallback(() => getCluster(name), [name]),

    {
      handleError: true,
      resolve: true,
    },
  );

  cluster = result?.data?.instance;
  let lockedActionsList = cluster?.lockedActions?.split(',');
  lockedActionsList = lockedActionsList?.map(item => item.trim());

  let [showModal, setShowModal] = useState(false);
  let dataSource =
    cluster?.lockedActions == '' ? [] : formatDataSource(lockedActionsList);

  const columns = [
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 99,
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 1,
      render(_, record) {
        return (
          <Space>
            <Tooltip title="Unlock">
              <Button
                type="text"
                size="small"
                onClick={() => {
                  Modal.confirm({
                    title: (
                      <>
                        Are you sure you want to{' '}
                        <strong>Unlock {record.action} </strong>
                        for {cluster.name} ?
                      </>
                    ),
                    async onOk() {
                      handleRemove(record.action);
                    },
                  });
                }}
              >
                <CloseOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async actionToRemove => {
        if (tab == 'selfActions') {
          const response = await removeInstanceSelfLock(actionToRemove, [
            cluster.id,
          ]);
          message.success(response.data.message);
        } else {
          let response = await removeInstanceLock(actionToRemove, [cluster.id]);
          message.success(response.data.message);
        }
        await refetchCluster();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [cluster?.name],
    ),
    {
      handleError: true,
    },
  );

  const handleRemove = async actionToRemove => {
    await makeRequest(actionToRemove);
  };

  return (
    <div title="Locks">
      <Form className="ActionLocks" layout="vertical" name="Lock Actions">
        <div className="tableTitle">
          <Title></Title>

          <Tooltip title="New">
            <Button
              type="primary"
              size="small"
              icon={<PlusOutlined />}
              onClick={() => {
                setShowModal(true);
              }}
            >
              Add Action
            </Button>
          </Tooltip>
        </div>
        <Table dataSource={dataSource} loading={isPending} columns={columns} />
      </Form>
      {showModal && (
        <AddActionModal
          close={() => setShowModal(null)}
          instance={cluster}
          tab={tab}
          refetchCluster={refetchCluster}
          refetchClusters={refetchClusters}
          setErrorModalProps={setErrorModalProps}
        />
      )}
    </div>
  );
}
export default LockActionsModal;
