import React, { useState, useCallback } from 'react';
import { Typography, Tag, Button, Tooltip, Space, Modal } from 'antd';
import { formatISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { MinusSquareOutlined, PlusOutlined } from '@ant-design/icons';
import useRequest from 'hooks/useRequest';

import {
  getAccountManagers,
  removeAccountManager,
} from 'services/accountManager';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';

let { Title } = Typography;

function AccountManager() {
  let [page, setPage] = useState(1);
  let [search, setSearch] = useState('');
  let history = useHistory();

  let {
    result,
    isPending,
    makeRequest: refetchUsers,
  } = useRequest(
    useCallback(() => getAccountManagers({ page, search }), [page, search]),
    { resolve: true },
  );

  let users = result?.data?.users;
  let pageSize = result?.data?.limit;
  let current = result?.data?.page;
  let total = result?.data?.total;

  let columns = [
    {
      title: 'Full name',
      dataIndex: 'fullName',
      render(_, user) {
        return (
          <span>
            {user.fullName}
            &emsp;
            <Tag color="blue">
              {user.role.name.charAt(0).toUpperCase() + user.role.name.slice(1)}
            </Tag>
            {user.permission === 'accountant' && (
              <Tag color="green">Accountant</Tag>
            )}
            {user.permission === 'support' && <Tag color="red">Support</Tag>}
            {user.permission === 'pre-sales' && (
              <Tag color="green">Pre-Sales</Tag>
            )}
            {user.permission === 'professional services' && (
              <Tag color="green">Professional Services</Tag>
            )}
            {user.permission === 'admin' && <Tag color="green">Admin</Tag>}
            {user.permission === 'super admin' && (
              <Tag color="cyan">Super admin</Tag>
            )}
            {user.confirmed && <Tag color="purple">Confirmed</Tag>}
            {user.trialExpired && <Tag color="red">Expired</Tag>}
            {user.blocked && <Tag color="red">Blocked</Tag>}
          </span>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Company',
      dataIndex: 'company',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },
    {
      title: 'last Login At',
      dataIndex: 'lastLoginAt',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },
    {
      title: 'Action',
      render(_, user) {
        return (
          <Space>
            {
              <Tooltip title="Remove">
                <Button
                  type="default"
                  size="small"
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <>
                          Are you sure you want to remove
                          <strong> {user.fullName} </strong>
                          from the Account Managers list?
                        </>
                      ),
                      async onOk() {
                        await removeAccountManager(user.id, user);
                        await refetchUsers();
                      },
                    });
                  }}
                >
                  <MinusSquareOutlined />
                </Button>
              </Tooltip>
            }
          </Space>
        );
      },
    },
  ];

  return (
    <div className="AccountManager">
      <div className="tableTitle">
        <Title>Account Managers</Title>
        <Tooltip title="Create">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              history.push(`/accountmanagers/users`);
            }}
          >
            Add Account Managers
          </Button>
        </Tooltip>
      </div>

      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={users}
        loading={isPending}
        columns={columns}
        searchPlaceholder={
          "Search By Email, Name, Comapny Or 'Field Name'=Some Value."
        }
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
      />
    </div>
  );
}

export default AccountManager;
