import React, { useCallback } from 'react';
import { Modal, Form, Input, InputNumber, Button, Space } from 'antd';
import useRequest from 'hooks/useRequest';
import {
  createBlueprintCategory,
  updateBlueprintCategory,
} from 'services/blueprintCategory';
import Upload from 'components/ui/Upload/Upload';

import './BlueprintCategoryModal.less';

function BlueprintCategoryModal({
  isNew,
  blueprintCategory,
  refetchBlueprintCategories,
  close,
}) {
  let { id, name, image, position } = blueprintCategory ?? {};

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async blueprintCategory => {
        isNew
          ? await createBlueprintCategory(blueprintCategory)
          : await updateBlueprintCategory(id, blueprintCategory);
        await refetchBlueprintCategories();
      },
      [id, isNew, refetchBlueprintCategories],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(blueprintCategory) {
    await makeRequest(blueprintCategory);
    close();
  }

  return (
    <Modal
      visible
      title={isNew ? 'New Blueprint Category' : 'Edit Blueprint Category'}
      footer={null}
      onCancel={close}
      wrapClassName="BlueprintCategoryModal"
    >
      <Form
        initialValues={{
          name,
          image,
          position,
        }}
        layout="vertical"
        name="BlueprintCategory"
        onFinish={handleFinish}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
          name="position"
          label="Order/Position"
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item name="image" label="Image" rules={[{ required: true }]}>
          <Upload draggable className="BlueprintCategoryModal__upload" />
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {isNew ? 'Create' : 'Update'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default BlueprintCategoryModal;
