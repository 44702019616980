import React, { useState } from 'react';
import { Typography, Tag, Button, Tooltip, Space } from 'antd';
import { format } from 'date-fns';
import { EditOutlined, UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import UserModal from './UserModal/UserModal';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { useGetUsers } from 'hooks/queries';
import { seachableTablePageSizeOptions } from 'utils/constants';

const { Title } = Typography;

function Users() {
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [modalProps, setModalProps] = useState<any>(null);

  const [limit, setLimit] = useState(seachableTablePageSizeOptions[0]);
  const {
    data: result,
    isLoading: isPending,
    refetch: refetchUsers,
  } = useGetUsers({ page, search, limit });

  const users = result?.data?.users;
  const current = result?.data?.page;
  const total = result?.data?.total;

  const columns = [
    {
      title: 'Full name',
      dataIndex: 'fullName',
      render(_, user) {
        return (
          <span>
            {user.fullName}
            &emsp;
            {user.role.displayName && (
              <Tag color="blue">{user.role.displayName}</Tag>
            )}
            {user.invitedAccount ? <Tag color="yellow">Invited</Tag> : null}
            {user.permission === 'accountant' && (
              <Tag color="green">Accountant</Tag>
            )}
            {user.permission === 'support' && <Tag color="red">Support</Tag>}
            {user.permission === 'pre-sales' && (
              <Tag color="green">Pre-Sales</Tag>
            )}
            {user.permission === 'professional services' && (
              <Tag color="green">Professional Services</Tag>
            )}
            {user.permission === 'release manager' && (
              <Tag color="yellow">Release Manager</Tag>
            )}
            {user.permission === 'admin' && <Tag color="green">Admin</Tag>}
            {user.permission === 'super admin' && (
              <Tag color="cyan">Super admin</Tag>
            )}
            {user.confirmed ? <Tag color="purple">Confirmed</Tag> : null}
            {user.isPartner ? <Tag color="volcano">Partner</Tag> : null}
            {user.trialExpired ? <Tag color="red">Expired</Tag> : null}
            {user.blocked ? <Tag color="red">Blocked</Tag> : null}
            {user.isProvision ? <Tag color="volcano">Provision</Tag> : null}
          </span>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render(_, user) {
        return user.email || user.fullName;
      },
    },
    {
      title: 'Company',
      dataIndex: 'company',
      width: '10%',
    },
    {
      title: 'Cost Center',
      dataIndex: 'cost_center',
      width: '10%',
    },
    {
      title: 'Org',
      dataIndex: 'organization',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return format(new Date(date), 'dd-MM-yyyy hh:mm a');
      },
    },
    {
      title: 'last Login At',
      dataIndex: 'lastLoginAt',
      render(date) {
        return date ? format(new Date(date), 'dd-MM-yyyy hh:mm a') : 'Never';
      },
    },
    {
      title: 'Login Provider',
      dataIndex: 'loginProvider',
      render(_, user) {
        return user.loginProvider;
      },
    },
    {
      title: 'Action',
      render(_, user) {
        return (
          <Space>
            <Tooltip title="Edit">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ isNew: false, user });
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="User Info">
              <Button
                type="primary"
                size="small"
                icon={<UserOutlined />}
                onClick={() => {
                  history.push(`/accounting/userdetails/${user.uuid}`);
                }}
              ></Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const searchColumns = [
    'Full Name',
    'Email',
    'Company',
    'Cost Center',
    'Organization',
    'Created At',
    'Role',
    'Permission',
  ];

  return (
    <div className="Users">
      <Title>Users</Title>

      <SearchableTable
        pagination={{
          pageSize: limit,
          current,
          total,
          showSizeChanger: true,
          pageSizeOptions: seachableTablePageSizeOptions,
          onShowSizeChange: (_, size) => {
            setLimit(size);
          },
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        dataSource={users}
        loading={isPending}
        columns={columns}
        advancedSearchColumns={searchColumns}
      />
      {modalProps && (
        <UserModal
          {...modalProps}
          refetchUsers={refetchUsers}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default Users;
